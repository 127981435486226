import React, { useEffect } from 'react';
import { NotificationModalDesign } from '../../styles/MyDairy/Design';

const NotificationModal = ({ notification, onClose }) => {
  const { notify_type, previous_data, current_data } = notification;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.modal-content')) {
        onClose();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [onClose]);

  return (
    <NotificationModalDesign>
      <div className="modal-container">
        <div className="modal-header">
          <h2>Notification Details</h2>
        </div>
        <div className="modal-content">
          <div className="data-section">
            <h3>Date: {notification.current_data.date}</h3>
            <table className="parameter-table">
              <thead>
                <tr>
                  <th>Parameter</th>
                  {notify_type !== 'delete_sold_milk' && <th>Previous</th>}
                  <th>Current</th>
                </tr>
              </thead>
              <tbody>
                {['Fat', 'SNF', 'CLR', 'Quantity', 'Shift', 'Amount'].map((param) => (
                  <tr key={param}>
                    <td>{param}</td>
                    {notify_type !== 'delete_sold_milk' && <td>{previous_data[param === 'Quantity' ? 'quntity' : param.toLowerCase()]}</td>}
                    <td>{current_data[param === 'Quantity' ? 'quntity' : param.toLowerCase()]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-header">
          <button className="modal-close" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </NotificationModalDesign>
  );
};

export default NotificationModal;
