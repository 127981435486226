import React, { useState, useEffect, useRef } from 'react';
import { TransactionsDesign } from './Design';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { BuyMilkListingAction, CustomerListingAction, DepositeHistoryAdminAction, DestroyDepositAction } from '../../store/actions/MyDairyActions';
import CustomSelect from '../Elements/CustomSelect';
import moment from 'moment';
import { sort } from 'semver';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { BASE_URL } from '../../utils/fetchServices';
import Swal from 'sweetalert2';

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const ProductTransactions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useParams();
  const tableRef = useRef(null);
  const { t } = useTranslation();
  const customers = useSelector((state) => state.MyDairy.customers || []);
  const buyMilks = useSelector((state) => state.MyDairy.depositehistoryAdmin || {});
  const pagy = useSelector((state) => state.MyDairy.depositehistoryAdmin?.pagination || {});
  const spinnerLoader = useSelector((state) => state.Authentication.spinnerLoader);
  const [data, setData] = useState([]);
  const [preData, setPreData] = useState([]);
  const [meta, setMeta] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [sortConfig, setSortConfig] = useState({key: 'created_at', direction: 'asc'});
  const [perform, setPerform] = useState('');
  const initialDate = moment().tz('Asia/Kolkata').format('YYYY-MM-DD');
  const [filters, setFilters] = useState({
    customer_id: '',
    from_date: initialDate,
    to_date: initialDate,
    sort: sortConfig,
  });

  useEffect(() => {
    dispatch(CustomerListingAction());
  }, []);

  useEffect(() => {
    if(buyMilks.data){
      setData([...preData, ...buyMilks.data])
      setMeta(buyMilks.meta)
    } else {
      setData([])
      setMeta({})
    }
  }, [buyMilks]);

  useEffect(() => {
    setPreData(data);
    dispatch(DepositeHistoryAdminAction({...filters, page: currentPage, meta: true, deposit_type: "product"}, data));
  }, [filters, currentPage, perform]);
  
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setCurrentPage(1);
    setData([]);
    setSortConfig({ key, direction });
    setFilters({...filters, sort: { key, direction }})
  };

  const getSortIconClass = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down';
    }
    return 'fa fa-sort';
  };

  const customerOptions = customers.map((customer) => ({
    value: customer.id,
    label: `${customer.name} - #${customer.sid}`,
  }));

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);
    setCurrentPage(1);
    setData([]);
    setFilters({ ...filters, customer_id: selectedOption.value });
  };

  const handleScroll = () => {
    const scrolablePage = document.querySelector(".table-container");
    const scrollTop = scrolablePage.scrollTop;
    const scrollHeight = scrolablePage.scrollHeight;
    const clientHeight = scrolablePage.clientHeight;
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

    if (!spinnerLoader && scrollPercentage >= 99 && pagy?.last > currentPage && pagy?.next) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const scrolablePage = document.querySelector(".table-container");
    scrolablePage?.addEventListener("scroll", handleScroll);

    return () => {
      scrolablePage?.removeEventListener("scroll", handleScroll);
    };
  }, [pagy?.last > currentPage && pagy?.next, currentPage, spinnerLoader]);

  const performNavigation =(url, data)=> {
    navigate(url, { state: {...data, redirect: 'transactions-milk-product'} })
  }

  const DeleteDeposite=(event, id)=>{
    event.stopPropagation(); // Stop event propagation
    Swal.fire({
      title: t("Are you sure you want to delete this Deposit?"),
      text: t("This action is irreversible and the Deposit data will be permanently deleted!"),
      icon: "warning",
      color: "white",
      showCancelButton: true,
      confirmButtonColor: "#28728f",
      cancelButtonColor: "#28728f",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t('Cancel'),
      background: "#a4c4d1",
      customClass: {
        confirmButton: "swal-button swal-button--danger",
        cancelButton: "swal-button",
        popup: "swal-popup--grey",
        content: "swal-content--grey",
        actions: "swal-actions--center",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DestroyDepositAction(id, true, setPerform, setData, t)).then((response) => {
          setCurrentPage(1);
          setData([]);
        });
      } else {
        return;
      }
    });
  };

  return (
  <TransactionsDesign>
    <div className='filters-section'>
      <div className='input-container'>
        <div className='full-input'>
          <label>{t("From Date")}</label>
          <input
            type="date"
            placeholder="From Date"
            value={filters.from_date}
            onChange={(e) => {
              setCurrentPage(1);
              setData([]);
              setFilters({ ...filters, from_date: e.target.value })
            }}
          />
        </div>

        <div className='full-input'>
          <label>{t("To Date")}</label>
          <input
            type="date"
            placeholder="To Date"
            value={filters.to_date}
            onChange={(e) => {
              setCurrentPage(1);
              setData([]);
              setFilters({ ...filters, to_date: e.target.value })
            }}
          />
        </div>
      </div>

      <div className='input-container'>
        <div className='full-input'>
          <label>{t("Customers")}</label>
          <CustomSelect
            options={[{value: '', label: 'All'}, ...customerOptions]}
            value={selectedCustomer}
            onChange={handleCustomerChange}
            placeholder={t("Search for a customer...")}
            isSearchable
          />
        </div>
      </div>
    </div>

    <div className='table-container'>
      <table ref={tableRef}>
        <thead>
          <tr>
            <th onClick={() => handleSort('sid')}>
              {t("SN.")} <i className={getSortIconClass('sid')}></i>
            </th>
            <th>
              {t("Customer")}
            </th>
            <th onClick={() => handleSort('date')}>
              {t("Date")} <i className={getSortIconClass('date')}></i>
            </th>
            <th>
              {t("Product")}
            </th>
            <th onClick={() => handleSort('quntity')}>
              {t("Quantity")} <i className={getSortIconClass('quntity')}></i>
            </th>
            <th onClick={() => handleSort('amount')}>
              {t("Amount")} <i className={getSortIconClass('amount')}></i>
            </th>
            <th>
              {t("Note")}
            </th>
            <th>
              {t("Action")}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length ? data.map((item) => (
            <tr key={item.id}>
              <td>{item.customer.sid}</td>
              <td>{item.customer.name}</td>
              <td>{formatDate(item.date)}</td>
              <td>{item.product?.name}</td>
              <td>{item.quntity}</td>
              <td>{item.amount}</td>
              <td>{item.note}</td>
              <td><div className='product-actions'><p onClick={() => performNavigation(`/sell/edit/${item.id}`, item)}>Edit</p><p onClick={(e) => DeleteDeposite(e, item.id)}>Delete</p></div></td>
            </tr>
          )) : ''}
        </tbody>
      </table>
      {data.length ? spinnerLoader && <div className="table-spinner-container">
        <div className="table-spinner"></div>
      </div> : ''}
    </div>

    <div className='summury-div'>
      <p>{t("Total Amount")}: {meta.total_amount}</p>
    </div>
  </TransactionsDesign>
  );
};

export default ProductTransactions;
