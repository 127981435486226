import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaCopy } from 'react-icons/fa';

const generateShareMessage = (referralCode, t) => {
  const webAppLink = 'https://milkdairy.in/signup';
  const playStoreLink = 'https://play.google.com/store/apps/details?id=com.milkdairymerchant&pli=1';

  return `🌟 *${t('join_milkdairy')}* 🌟\n\n` +
         `🎉 ${t('use_referral_code')}: *${referralCode}* ${t('to_sign_up')}\n\n` +
         `${t('with_milkdairy')}\n` +
         `- ${t('quick_calculations')}\n` +
         `- ${t('efficient_billing')}\n` +
         `- ${t('more_features')}\n\n` +
         `📲 ${t('get_started')}:\n` +
         `- *${t('web_link')}*: ${webAppLink}\n` +
         `- *${t('play_store')}*: ${playStoreLink}\n\n` +
         `${t('let_make_dairy_management_easy')} 🥛💚`;
};

const ReferralScreen = () => {
  const profile = useSelector((state) => state.Authentication.profile || {});
  const referralCode = profile.referral_code || 'N/A';
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const handleShare = () => {
    const shareMessage = generateShareMessage(referralCode, t);

    if (navigator.share) {
      navigator.share({
        title: t('share_title'),
        text: shareMessage,
      }).catch((error) => console.log('Error sharing', error));
    } else {
      alert(t('sharing_not_supported'));
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.referralContainer}>
        <h2 style={styles.heading}>{t('earn_free_days')}</h2>
        <p style={styles.infoText}>{t('invite_friends_description')}</p>
        <p style={styles.highlightText}>{t('share_three_times')}</p>

        <div style={styles.codeContainer}>
          <span style={styles.referralCode}>{referralCode}</span>
          <FaCopy style={styles.copyIcon} onClick={copyToClipboard} />
        </div>

        <button style={styles.shareButton} onClick={handleShare}>
          {t('share_referral_code')}
        </button>
      </div>

      {copied && <div style={styles.copiedMessage}>{t('copied')}</div>}
    </div>
  );
};

const styles = {
  highlightText: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  container: {
    padding: '40px',
    marginTop: '30px',
    textAlign: 'center',
    height: '77vh',
  },
  referralContainer: {
    backgroundColor: '#28728f',
    color: '#ffffff',
    padding: '30px',
    borderRadius: '10px',
    maxWidth: '400px',
    margin: 'auto',
    position: 'relative',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#ffffff',
  },
  infoText: {
    fontSize: '16px',
    marginBottom: '15px',
    color: '#ffffff',
  },
  codeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    color: '#231f20',
    padding: '10px',
    borderRadius: '8px',
    marginBottom: '20px',
    position: 'relative',
  },
  referralCode: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginRight: '10px',
  },
  copyIcon: {
    fontSize: '20px',
    cursor: 'pointer',
    color: '#28728f',
  },
  shareButton: {
    backgroundColor: '#4fa8de',
    color: '#ffffff',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  copiedMessage: {
    position: 'fixed',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#4fa8de',
    color: '#ffffff',
    padding: '10px 20px',
    borderRadius: '8px',
    fontSize: '14px',
    zIndex: 1000,
  },
};

export default ReferralScreen;
