import React, { useEffect, useState } from 'react';
import { MyPlanDesign } from '../../styles/MyDairy/Design';
import { useDispatch, useSelector } from 'react-redux';
import { GetPlansAction } from '../../store/actions/MyDairyActions';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';


const MyPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const profile = useSelector((state) => state.Authentication.profile || {});
  const plans = useSelector((state) => state.MyDairy.plans || []);
  const [showPopup, setShowPopup] = useState(false);
  const [activatedPlan, setActivatedPlan] = useState({
    title: profile.plan_details.plan_name,
    activated: profile.plan_details.activated,
    expire_date: profile.plan_details.expire_date,
  });

  useEffect(()=>{
    dispatch(GetPlansAction())
  }, [])

  const handleSelectPlan = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const formatNumber = (number) => {
    if (number > 999) {
      const numString = number.toString();
      const formattedNumber = numString.slice(0, -3) + ',' + numString.slice(-3);
      return formattedNumber;
    }
    return number.toString();
  };

  const formatDate = (originalDate) => {
    const parsedDate = moment(originalDate, 'YYYY-MM-DD');  
    const formattedDate = parsedDate.format('D MMM YYYY');
    return formattedDate;
  }
  
  
  console.log(profile);
  

  console.log(profile);
  return (
    <MyPlanDesign>
      <div className="my-plan-container">
        <h1>{t("Your Plans")}</h1>
        {!activatedPlan.activated && 
        <div className="activated-plan" style={{backgroundColor: '#581616'}}>
          <h4>{t("Dear user, your current plan has expired kindly recharge immediately to continue using our platform.")}</h4>
          <p>
            {t("Call us at")}: <a href="tel:+916267016717">+91 6267016717</a>
            <br />
            {t("Email us at")}: <a href="mailto:contact@ftpls.com">contact@ftpls.com</a>
          </p>
          <p>{t("Thanks for choosing us!")}</p>
        </div>}

        <div className="activated-plan">
          <h2>{t("Activated Plan")}</h2>
          <p>{t(`${activatedPlan.title}`)}</p>
          <p>{t("Expires on")}: {formatDate(activatedPlan.expire_date)}</p>
        </div>

        <div className="plans-container">
          {plans.map((plan, index) => (
            <div key={index} className={`plan${plan.recommended ? ' recommended' : ''}`} >
              {plan.recommended && <div className="recommended-badge">{t("Recommended")}</div>}
              <div className="plan-header">
                <h3>{`${t(plan.name)}`}</h3>
                <p className="duration">{plan.validity} {t("Days")}</p>
               </div>
              <div className="price">₹{formatNumber(plan.amount)}</div>
              <button className="cta-button" onClick={handleSelectPlan}>{t("Select")}</button>
            </div>
          ))}
        </div>
      </div>

      {showPopup && (
        <div className='popup'>
          <div className="popup-content">
            <h2>{t("Contact Us for Recharge")}</h2>
            <p>
              {t("Call us at")}: <a href="tel:+916267016717">+91 6267016717</a>
              <br />
              {t("Email us at")}: <a href="mailto:contact@ftpls.com">contact@ftpls.com</a>
            </p>
            <p>{t("Thanks for choosing us!")}</p>
            <span className="close" onClick={handleClosePopup}>
              {t("OK")}
            </span>
          </div>
        </div>
      )}
      { !activatedPlan.activated &&
        <div className='logout-button'>
        <span className="close" onClick={()=> navigate('/logout')}>
          {t("Logout")}
        </span>
      </div>}
    </MyPlanDesign>
  );
};

export default MyPlan;
