import React, { useState } from 'react';
import { AgChartsReact } from 'ag-charts-react';

function Graph({type, data}) {
  const [chartOptions] = useState({
    data: data,
    series: [
      { type: type,
        xKey: 'key',
        yKey: 'value',
        fill: '#28728f',
        stroke: '#28728f',
        marker: {
          fill: '#28728f',
        },
      }
    ],
  });

  return (
    <AgChartsReact options={chartOptions} />
  );
}

export default Graph