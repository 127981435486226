import React from 'react'
import { FeaturesSectionDesign } from './Design'
import { FiDatabase, FiEye, FiFileText, FiClock, FiSettings, FiAlertCircle, FiUsers, FiLock } from 'react-icons/fi';


function FeaturesSection() {
  const features = [
    {
      icon: <FiDatabase />,
      title: 'Maintains Daily Inventory',
      description:
        'Enables daily inventory tracking, ensuring that records of members and milk supplies are always up to date.',
    },
    {
      icon: <FiUsers />,
      title: 'Create and Manage Customers and Their Accounts',
      description: 'You can create and manage customer accounts efficiently.',
    },
    {
      icon: <FiEye />,
      title: 'Customer Account Access',
      description:
        'Customers have read-only access and receive notifications of their account activity, including total milk credited and cash given.',
    },
    {
      icon: <FiFileText />,
      title: 'Automated Customer Receipts',
      description:
        'As soon as you make an entry of milk bought by the customer, the customer receives an automated receipt through our application, eliminating the need for physical receipts.',
    },
    {
      icon: <FiClock />,
      title: 'Quick Access',
      description: 'Provides quick access to all records, allowing for efficient decision-making.',
    },
    {
      icon: <FiSettings />,
      title: 'Reduces Manual Work',
      description: 'Significantly reduces the need for manual calculation, saving time and resources.',
    },
    {
      icon: <FiAlertCircle />,
      title: 'Automation Reduces Errors',
      description: 'Automation reduces the risk of human errors in managing dairy operations.',
    },
    {
      icon: <FiUsers />,
      title: 'Multi-User Access',
      description: 'Multiple users can simultaneously use the software, facilitating collaboration and data sharing.',
    },
    {
      icon: <FiLock />,
      title: 'Secure and Accessible Data',
      description: 'Your data is always secure and accessible anytime you need it.',
    },
  ];

  return (
    <FeaturesSectionDesign>
      <section id="features" className="features-section">
        <h2>Key Features</h2>
        <div className="features-container">
          {features.map((feature, index) => (
            <div key={index} className="feature-card">
              <div className="feature-icon">{feature.icon}</div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>
    </FeaturesSectionDesign>
  )
}

export default FeaturesSection;