import { useSelector } from "react-redux";
import { DashboardDesign, ModalOverlay } from "../../styles/MyDairy/Design";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";

function Dashboard() {
  const profile = useSelector((state) => state.Authentication.profile || {});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleModalClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const functinalities = [
    {
      id: 1,
      name: 'Customers',
      navigate: '/customers'
    },
    {
      id: 2,
      name: 'Buy Milk',
      navigate: '/set-parameters'
    },
    {
      id: 3,
      name: "Transactions",
      action: handleModalOpen,
    },
    {
      id: 4,
      name: 'Products',
      navigate: '/products'
    },
    {
      id: 5,
      name: 'Admin Actions',
      navigate: '/admin-actions'
    },
    {
      id: 6,
      name: 'Customer Bill',
      navigate: '/pdf-format'
    },
  ]

  const transactionOptions = [
    { id: 1, name: "Sell Product", navigate: "/sell/new" },
    { id: 2, name: "Add Cash", navigate: "/cash/new" },
  ];

  return (
    <DashboardDesign>
      <h1> {profile.dairy_name}</h1>
      <div>
        {functinalities.map((el) => (
          <div key={el.id} onClick={el.action || (() => navigate(el.navigate))}>
          <h3>{t(el.name)}</h3>
        </div>
        ))}
      </div>
      
      {isModalOpen && (
        <ModalOverlay>
          <div ref={modalRef} className="modal-content">
            {transactionOptions.map((option) => (
              <div
                key={option.id}
                onClick={() => {
                  navigate(option.navigate);
                  handleModalClose();
                }}
                className="modal-option"
              >
                <h3>{t(option.name)}</h3>
              </div>
            ))}
          </div>
        </ModalOverlay>
      )}
    </DashboardDesign>
  );
}
export default Dashboard;
