import React from 'react'
import { HeroSectionDesign } from './Design'
import { useNavigate } from 'react-router-dom'

function HeroSection() {
  const navigate = useNavigate();

  return (
    <HeroSectionDesign>
      <section className="hero">
        <div className="hero-content">
          <h1 style={{ color: '#28728f' }}>Your Dairy, Our Solution!</h1>
          <p style={{ color: '#231f20' }}>
            Experience the convenience of managing your dairy operations with MILK DAIRY.
          </p>
          <button className="cta-button" style={{ backgroundColor: '#4fa8de', color: '#ffffff' }} onClick={()=> navigate('/signup')}>
            Get Started - 1 Month Free Trial
          </button>
        </div>
        {/* You can add an image or video here */}
      </section>
    </HeroSectionDesign>
  )
}

export default HeroSection