import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import VideoPlayer from './VideoPlayer';

const Container = styled.div`
  max-width: 800px;
  margin: 20px auto;
`;

const SectionContainer = styled.div`
  margin-bottom: 40px;
  padding: 20px;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: #28728f;
  margin-bottom: 20px;
`;

const TextContent = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
`;

const FaqItem = styled.div`
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 15px;

  &:last-child {
    border-bottom: none;
  }
`;

const Question = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #28728f;
`;

const Answer = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
`;

const InfoLabel = styled.p`
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #28728f;
`;

const InfoDetail = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
`;

const Support = () => {
  const { t } = useTranslation();
  const faqData = [
    {
      question: t('How do I recover my password?'),
      answer: t("To reset your password, go to the login page and click on the 'Forgot Password' link."),
    },
    {
      question: t('Is my account information secure?'),
      answer: t('Yes, we prioritize the security of your account information. We use industry-standard encryption techniques to keep your data safe.'),
    },
    // Add more FAQs as needed
  ];

  const guidesData = [
    {
      title: t('Common Login Issues'),
      description: t('Clear your browser data or site data, and try again. If you are still facing issues, please contact us to receive clear and proper guidance.'),
    }
  ];

  return (
    <Container>
      <SectionContainer>
        <SectionTitle>{t("Support - Milk Dairy")}</SectionTitle>
        <TextContent>{t("Welcome to our support center. How can we assist you today?")}</TextContent>
        <VideoPlayer/>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>{t("Frequently Asked Questions")}</SectionTitle>
        {faqData.map((faq, index) => (
          <FaqItem key={index}>
            <Question>{faq.question}</Question>
            <Answer>{faq.answer}</Answer>
          </FaqItem>
        ))}
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>{t("Contact Information")}</SectionTitle>
        <InfoLabel>{t("Email")}:</InfoLabel>
        <InfoDetail>contact@ftpls.com</InfoDetail>
        <InfoLabel>{t("Phone")}:</InfoLabel>
        <InfoDetail>+91 73547 04537</InfoDetail>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>{t("Support Hours")}</SectionTitle>
        <InfoLabel>Monday - Sunday:</InfoLabel>
        <InfoDetail>{t("9:00 AM - 8:00 PM (Local Time)")}</InfoDetail>
        <InfoLabel>{t("Weekends")}:</InfoLabel>
        <InfoDetail>{t("Available")}</InfoDetail>
        <InfoLabel>{t("Holidays")}:</InfoLabel>
        <InfoDetail>{t("Available")}</InfoDetail>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>{t("Troubleshooting Guides")}</SectionTitle>
        {guidesData.map((guide, index) => (
          <FaqItem key={index}>
            <Question>{guide.title}</Question>
            <Answer>{guide.description}</Answer>
          </FaqItem>
        ))}
      </SectionContainer>
    </Container>
  );
};

export default Support;
