import { useDispatch, useSelector } from "react-redux";
import { SignupDesign } from "../styles/Design";
import { useState } from "react";
import { SignIn } from "../store/actions/AuthenticationActions";
import { BASE_URL, ERRORS } from "../utils/fetchServices";
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'
import { useNavigate } from "react-router-dom";


function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.Authentication.loader);
  const errors = useSelector((state) => state.Authentication.errors || {});
  const [icon, setIcon] = useState(eyeOff);
  const [type, setType] = useState('password');
  const [iconCon, setIconCon] = useState(eyeOff);
  const [typeCon, setTypeCon] = useState('password');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [formData, setFormData] = useState({
    dairy_name: "",
    owner_name: "",
    email: "",
    phone_number: "",
    password: "",
    confirm_password: "",
    village: "",
    district: "",
    state: "",
    pin: "",
    referred_by_code: "",
    I_agree_terms_and_conditions_and_privacy_policies: agreeTerms,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      I_agree_terms_and_conditions_and_privacy_policies: !agreeTerms,
    }));
    setAgreeTerms(!agreeTerms);
  }

  const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
  } 

 const handleConfirmToggle  = () => {
    if (typeCon==='password'){
      setIconCon(eye);
      setTypeCon('text')
    } else {
      setIconCon(eyeOff)
      setTypeCon('password')
    }
  } 

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      dairy_name: formData.dairy_name,
      owner_name: formData.owner_name,
      email: formData.email,
      phone_number: formData.phone_number,
      password: formData.password,
      confirm_password: formData.confirm_password,
      referred_by_code: formData.referred_by_code,
      I_agree_terms_and_conditions_and_privacy_policies: formData.I_agree_terms_and_conditions_and_privacy_policies,
      address_attributes: {
        city: formData.village,
        district: formData.district,
        state: formData.state,
        pin: formData.pin,
      }
    }
    dispatch({
      type: ERRORS,
      payload: {},
    });
    localStorage.setItem("errors", JSON.stringify({}));
    !loader && dispatch(SignIn({data: data}, navigate))
  };

  return (
    <SignupDesign>
      <div className="back-img">
        <form onSubmit={handleSubmit}>
          <div className="full-input-container">
            <div className="lable-input">
              <input
                type="text"
                placeholder="Enter your dairy name"
                name="dairy_name"
                value={formData.dairy_name}
                onChange={handleChange}
                required
              />
              <span className="error">{errors.dairy_name}</span>
            </div>
            <div className="lable-input">
              <input
                type="text"
                placeholder="Enter your name"
                name="owner_name"
                value={formData.owner_name}
                onChange={handleChange}
                required
              />
              <span className="error">{errors.owner_name}</span>
            </div>
          </div>

          <div className="full-input-container">
            <div className="lable-input">
              <input
                type="email"
                placeholder="email@gmail.com"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <span className="error">{errors.email}</span>
            </div>
            <div className="lable-input">
              <input
                type="number"
                placeholder="phone number"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
              />
              <span className="error">{errors.phone_number}</span>
            </div>
          </div>

          <div className="full-input-container">
            <div className="lable-input">
              <div className="password-input">
                <input
                  type={type}
                  placeholder="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <span className="eye-buttton" onClick={handleToggle}>
                  <Icon className="absolute mr-10" icon={icon} size={25}/>
                </span>
              </div>
              <span className="error">{errors.password}</span>
            </div>
            
            <div className="lable-input">
              <div className="password-input">
                <input
                  type={typeCon}
                  placeholder="confirm password"
                  name="confirm_password"
                  value={formData.confirm_password}
                  onChange={handleChange}
                />
                <span className="eye-buttton" onClick={handleConfirmToggle}>
                  <Icon className="absolute mr-10" icon={iconCon} size={25}/>
                </span>
              </div>  
              <span className="error">{typeof(errors) === 'object' ? '' : errors}</span>
            </div>
          </div>

          <div className="full-input-container">
            <div className="full">
              <input
                type="text"
                placeholder="Referral Code (Optional)"
                name="referred_by_code"
                value={formData.referred_by_code}
                onChange={handleChange}
              />
            </div> 
          </div>

          <div>
            <label>
              <b>Address</b>
            </label>
          </div>

          <div className="full-input-container">
            <div className="lable-input">
              <input
                type="text"
                placeholder="village / city Name"
                name="village"
                value={formData.village}
                required
                onChange={handleChange}
              />
            </div>
            <div className="lable-input">
              <input
                type="text"
                placeholder="District"
                name="district"
                value={formData.district}
                required
                onChange={handleChange}
              /> 
            </div> 
          </div>

          <div className="full-input-container">
            <div className="lable-input">
              <input
                type="text"
                placeholder="State"
                name="state"
                value={formData.state}
                required
                onChange={handleChange}
              />
            </div>
            <div className="lable-input">
              <input
                type="text"
                placeholder="Pin"
                name="pin"
                value={formData.pin}
                required
                onChange={handleChange}
                pattern="[0-9]+"
              />
            </div>
          </div>

          <div className="checkbox-row">
            <label>
              <div class="checkbox-input">
                <input
                  id="tems_conditions"
                  type="checkbox"
                  name="I_agree_terms_and_conditions_and_privacy_policies"
                  checked={formData.I_agree_terms_and_conditions_and_privacy_policies}
                  onChange={handleCheckboxChange}
                />
              </div>
              <span>
                {' '}
                I agree to accept the{' '}
                <a
                  href={`${BASE_URL}/terms_and_coditions`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: '4px' }}
                >
                  terms and conditions
                </a>{' '}
                and{' '}
                <a
                  href={`${BASE_URL}/privacy_policies`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: '4px' }}
                >
                  privacy policy
                </a>
                .
              </span>
            </label>
            <label>
              <span className="error">{errors.I_agree_terms_and_conditions_and_privacy_policies}</span>
            </label>
          </div>


          <div>
            <button type="submit">
              <b>Sign Up</b>
            </button>
          </div>
        </form>
      </div>
    </SignupDesign>
  );
}

export default Signup;
