import React, { useEffect, useState } from 'react'
import profile from '../../assets/images/login.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { GetCustomerAction } from '../../store/actions/MyDairyActions';
import CreditHistory from '../MyDairy/CreditHistory';
import DepositeHistory from '../MyDairy/DepositeHistory';
import { ViewCustomerDesign } from '../../styles/MyDairy/Design';
import { useTranslation } from 'react-i18next';


function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useParams();
  const { t } = useTranslation();
  const customer = useSelector((state) => state.MyDairy.customer || {});
  const [permissions, setPermissions] = useState(customer.my_dairy?.customer_permissions || {})
  
  useEffect(()=> {
    if(customer.my_dairy?.customer_permissions) {
      setPermissions(customer.my_dairy?.customer_permissions);
    }
  }, [customer, setPermissions])

  useEffect(()=> {
    dispatch(GetCustomerAction(location.id));
  }, [dispatch, location.id])

  const convertStringToBoolean = (str) => str === "true";
  const permissionsConver = Object.keys(permissions).reduce((acc, key) => {
    const obj = {};
    Object.keys(permissions[key]).forEach(innerKey => {
        obj[innerKey] = convertStringToBoolean(permissions[key][innerKey]);
    });
    acc[key] = obj;
    return acc;
  }, {});

  console.log(permissionsConver)

  return (
    <ViewCustomerDesign>
      <div className="info">
        <img src={customer.image?.url || profile} alt="Profile" />
        
        <div className="name-number">
          <h2>{customer.name}</h2>
          <div className="number">+91{customer.phone_number}</div>
        </div>
        
        <div className="edit">
          <button onClick={()=> navigate('/logout')}>{t("Logout")}</button>
        </div>
      </div>
      <hr/>
      
      {permissionsConver.accountDetails?.parent &&
      <div className="contents">
        {permissionsConver.accountDetails?.creditedAmount &&
        <div className="box">
          <h2>{t('Credited')}</h2>
          <p>{customer.customer_account?.credit}</p>
        </div>}

        {permissionsConver.accountDetails?.debitedAmount &&
        <div className="box">
          <h2>{t('Deposited')}</h2>
          <p>{customer.customer_account?.deposit}</p>
        </div>}

        {permissionsConver.accountDetails?.parent &&
        <div className="box">
          <h2>{t("Balance")}</h2>
          <p>{customer.customer_account?.balance}</p>
        </div>}
      </div>}

      {permissionsConver.creditHistory?.parent && <CreditHistory customer_id={location.id} />}
      {permissionsConver.depositHistory?.parent && <DepositeHistory customer_id={location.id} />}
    </ViewCustomerDesign>
  )
}

export default Dashboard