import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage } from "firebase/messaging";
import 'firebase/messaging'
import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: "AIzaSyCqRba3VtKp_0Ry5SIabgvELfSr-F3cyqg",
  authDomain: "milk-dairy-62824.firebaseapp.com",
  projectId: "milk-dairy-62824",
  storageBucket: "milk-dairy-62824.appspot.com",
  messagingSenderId: "109696054826",
  appId: "1:109696054826:web:9df226026fd0e2a8f6b553"
};

let fapp, analytics, messaging;

try {
  fapp = initializeApp(firebaseConfig);
  analytics = getAnalytics(fapp);
  messaging = getMessaging(fapp);
} catch (error) {
  console.log("Error initializing Firebase:", error);
}

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    try {
      onMessage(messaging, (payload) => {
        console.log("p--------ayload", payload);
        if(localStorage.getItem("lng") === 'hi'){
          toast.info(`${payload.data.title_hindi}: ${payload.data.body_hindi}`);
        } else {
          toast.info(`${payload.notification.title}: ${payload.notification.body}`);
        }
        resolve(payload);
      });
    } catch (error) {
      console.log("Error in onMessageListener:", error);
    }
});

export default messaging;
