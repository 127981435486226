import { toast } from "react-toastify";
import { ERRORS, deleteInfo, getInfo, postCustomer, postInfo, putInfo } from "../../utils/fetchServices";
import { DATA, LOADER, SPINER_LOADER } from "./AuthenticationActions";
import axios from "axios";
export const PRODUCTS = 'PRODUCTS'
export const PRODUCT = 'PRODUCT'
export const CREDITHISTORY = 'CREDITHISTORY'
export const DEPOSITEHISTORY = 'DEPOSITEHISTORY'
export const CUSTOMERS = 'CUSTOMERS';
export const CUSTOMER = 'CUSTOMER';
export const CASH = 'CASH';
export const PRODUCTCUSTOMER = 'PRODUCTCUSTOMER';
export const CHART = 'CHART';
export const CUSTOMERINFORMATION = 'CUSTOMERINFORMATION'
export const CHART_RATES = 'CHART_RATES';
export const PRICE_PER_LITTER = 'PRICE_PER_LITTER';
export const PRICE_PER_LITTER_MESSAGE = 'PRICE_PER_LITTER_MESSAGE';
export const BUYMILK = 'BUYMILK';
export const SELLMILK = 'SELLMILK';
export const GRAPHDATA = 'GRAPHDATA';
export const BUYMILKOBJ = 'BUYMILKOBJ';
export const PLANS = 'PLANS';
export const GETNOTIFICATION = 'GETNOTIFICATION';
export const GRADE = 'GRADE';
export const DEPOSITEHISTORYADMIN = 'DEPOSITEHISTORYADMIN'
export const PRINTER = 'PRINTER'

export const GetInfoMyDairy = (body) => async (dispatch) => {
  try {
      const response = await getInfo('/my_dairies/:id', body, dispatch);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: DATA,
          payload: data.data,
        });
        localStorage.setItem("data", JSON.stringify(data.data));

        dispatch({
          type: DATA,
          payload: data.data,
        });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const UpdateMyDairy = (body, navigate, notify, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await putInfo('/my_dairies/:id', body, dispatch);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: DATA,
          payload: data.data,
        });
        localStorage.setItem("data", JSON.stringify(data.data));

        dispatch({
          type: DATA,
          payload: data.data,
        });
        LoaderAction(false, dispatch);
        notify && toast.success(t("Congratulations! Your profile has been successfully updated!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate('/')
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const ResetPasswordAction = (body, setFormData, notify, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await postInfo('/reset_password', body, dispatch);
      if (response.status === 200) {
        const data = response.data;
        setFormData({
          old_password: '',
          new_password: '',
          confirm_new_password: '',
        });
        notify && toast.success(t("Congratulations! Your password has been successfully updated!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const ProductListingAction = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await getInfo('/products', body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: PRODUCTS,
          payload: data.data,
        });
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const DestroyProductAction = (id, notify, setPerform, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await deleteInfo(`/products/${id}`);
      if (response.status === 200) {
        const data = response.data;
        notify && toast.success(t("Congratulations! Your Product has been successfully Deleted!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setPerform(`delete${id}`);
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const GetProductAction = (id) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await getInfo(`/products/${id}`);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: PRODUCT,
          payload: data.data,
        });
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const UpdateProduct = (body, navigate, notify, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await putInfo(`/products/${body.id}`, body, dispatch);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: PRODUCT,
          payload: data.data,
        });
        
        notify && toast.success(t("Congratulations! Your product has been successfully updated!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate('/products')
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const CreateProduct = (body, navigate, notify, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await postInfo('/products', body, dispatch);
      if (response.status === 201) {
        const data = response.data;
        LoaderAction(false, dispatch);
        notify && toast.success(t("Congratulations! Product has been successfully Created!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate('/products')
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const CustomerListingAction = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  LoaderAction(true, dispatch);

  localStorage.setItem("errors", JSON.stringify({}));
  try {
      const response = await getInfo('/customers', body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: CUSTOMERS,
          payload: data.data,
        });
        LoaderAction(false, dispatch);
        
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const AddCustomers = (body, navigate, notify, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  try {
      const response = await postInfo('/customers', body, dispatch);
      if (response.status === 201) {
        const data = response.data;
        
        notify && toast.success(t("Congratulations! Customer has been successfully added!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });

        navigate('/customers')
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const GetCustomerAction = (id) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await getInfo(`/customers/${id}`, {});
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: CUSTOMER,
          payload: data.data,
        });
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const UpdateCustomer = (body, navigate, notify, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await putInfo(`/customers/${body.id}`, body, dispatch);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: CUSTOMER,
          payload: data.data,
        });
        
        notify && toast.success(t("Congratulations! Customer has been successfully updated!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate(`/customer/${data.data.id}`)
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const CreditHistoryAction = (body, obj) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  SpinerLoaderAction(true, dispatch);
  try {
      const response = await getInfo(`/buy_milks/credit_history`, body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: CREDITHISTORY,
          payload: data,
        });
        SpinerLoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const DepositeHistoryAction = (body, obj) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  SpinerLoaderAction(true, dispatch);
  try {
      const response = await getInfo('/deposit_histories/deposit_history', body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: DEPOSITEHISTORY,
          payload: data,
        });
        SpinerLoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const DepositeHistoryAdminAction = (body, obj) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  SpinerLoaderAction(true, dispatch);
  try {
      const response = await getInfo('/deposit_histories', body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: DEPOSITEHISTORYADMIN,
          payload: data,
        });
        SpinerLoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const BuyCustomerMilk = (body, setFormData, setSelectedCustomer, notify, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await postInfo('/buy_milks', body, dispatch);
      if (response.status === 201) {
        const data = response.data;
        LoaderAction(false, dispatch);
        notify && toast.success(t("Congratulations! Record has been successfully Created!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setFormData({
          customer_id: '',
          fat: "",
          clr: "",
          snf: "",
          temprature: "",
          quntity: "",
          amount: "",
          date: body.data.date,
          shift: body.data.shift,
          rate_type: body.data.rate_type,
          chart_id: body.data.chart_id,
        });

        dispatch({
          type: PRICE_PER_LITTER,
          payload: {},
        });

        setSelectedCustomer(null);
        return response
      } else if(response.status === 200) {
        LoaderAction(false, dispatch);
        return response
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const UpdateBuyCustomerMilk = (body, navigate, notify, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await putInfo(`/buy_milks/${body.data.id}`, body, dispatch);
      if (response.status === 200) {
        LoaderAction(false, dispatch);
        const data = response.data;
        
        notify && toast.success(t("Congratulations! Record has been successfully updated!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });

        navigate(`/customer/${data.data.customer.id}`)
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const GetBuyCustomerMilk = (id) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await getInfo(`/buy_milks/${id}`);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: BUYMILKOBJ,
          payload: data.data,
        });
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const DepositeToCustomer = (body, setFormData, setSelectedCustomer, notify, type, setSelectedProduct, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await postInfo('/deposit_histories', body, dispatch);
      if (response.status === 201) {
        const data = response.data;
        LoaderAction(false, dispatch);
        notify && toast.success(t("Congratulations! Record has been successfully Created!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });

        setFormData({
          customer_id: '',
          deposit_type: type,
          product_id: '',
          quntity: '',
          note: '',
          amount: "",
        });
        setSelectedCustomer(null);
        setSelectedProduct && setSelectedProduct(null);
        return response
      } else {
          // dispatch({ type: FAIL });
          return response
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const UpdateCustomerProduct = (body, navigate, notify, t, redirect) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await putInfo(`/deposit_histories/${body.id}`, body, dispatch);
      if (response.status === 200) {
        const data = response.data;
        
        notify && toast.success(t("Congratulations! Deposit History has been successfully updated!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        if(redirect){
          console.log(redirect)
          navigate(`/${redirect}`)
        } else {
          navigate(`/customer/${data.data.customer.id}`)
        }
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const DestroyDepositAction = (id, notify, setPerform, setDepositehistory, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await deleteInfo(`/deposit_histories/${id}`);
      if (response.status === 200) {
        const data = response.data;
        notify && toast.success(t("Congratulations! Deposit has been successfully Deleted!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setDepositehistory([]);
        setPerform(`delete${id}`);
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const DestroyCreditAction = (id, notify, setPerform, setCredithistory, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await deleteInfo(`/buy_milks/${id}`);
      if (response.status === 200) {
        const data = response.data;
        LoaderAction(false, dispatch);
        notify && toast.success(t("Congratulations! Record has been successfully Deleted!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setCredithistory([]);
        setPerform(`delete${id}`);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const DestroyDairyBillAction = (id, setPerform, setData, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await deleteInfo(`/sell_milks/${id}`);
      if (response.status === 200) {
        const data = response.data;
        LoaderAction(false, dispatch);
        toast.success(t("Congratulations! Record has been successfully Deleted!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setData([]);
        setPerform(`delete${id}`);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const ChartListingAction = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });

  dispatch({
    type: LOADER,
    payload: true,
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await getInfo('/charts', body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: CHART,
          payload: data.data,
        });
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const LoaderAction =(active, dispatch)=> {
  dispatch({
    type: LOADER,
    payload: active,
  });
}

export const SpinerLoaderAction =(active, dispatch)=> {
  dispatch({
    type: SPINER_LOADER,
    payload: active,
  });
}

export const AddChartAction = (body, setIsModalOpen, notify, setPerform, setNewChartName, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  try {
      const response = await postInfo('/charts', body, dispatch);
      if (response.status === 201) {
        const data = response.data;
        
        notify && toast.success(t("Congratulations! Chart has been successfully added!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setPerform(`chart-${data.data.id}`)
        setIsModalOpen(false);
        setNewChartName('');
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const DestroyChartAction = (id, notify, setPerform, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await deleteInfo(`/charts/${id}`);
      if (response.status === 200) {
        const data = response.data;
        LoaderAction(false, dispatch);
        notify && toast.success(t("Congratulations! Chart has been successfully Deleted!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setPerform(`delete${id}`);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const GetCustomerInformation = (params) => async (dispatch) => {
  try {
    const response = await getInfo(`/buy_milks`, params);
    if (response.status === 200) {
      const data = response.data;

      dispatch({
        type: CUSTOMERINFORMATION,
        payload: data,
      });
    } else {
      //dispatch({ type: FAIL });
    }
  } catch (error) {
    // dispatch({ type: FAIL });;
  }
};

export const ChartRateListingAction = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });

  dispatch({
    type: LOADER,
    payload: true,
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await getInfo('/chart_rates', body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: CHART_RATES,
          payload: data.data,
        });
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const BuildChartRateAction = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });

  dispatch({
    type: LOADER,
    payload: true,
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await postInfo('/chart_rates/build_chart_rate', body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: CHART_RATES,
          payload: data.data,
        });
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const ClearChartAction = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });

  dispatch({
    type: LOADER,
    payload: true,
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await deleteInfo(`/chart_rates/clear_chart_rate?chart_id=${body.data}`, body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: CHART_RATES,
          payload: data.data,
        });
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const GradeListingAction = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });

  dispatch({
    type: LOADER,
    payload: true,
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await getInfo('/grades', body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: GRADE,
          payload: data.data,
        });
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const CreateChartRate = (body, chart_rates, index) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  try {
      const response = await postInfo('/chart_rates', body, dispatch);
      if (response.status === 201) {
        const data = response.data;
        LoaderAction(false, dispatch);
        chart_rates[index]['id'] = data.data.id 
        dispatch({
          type: CHART_RATES,
          payload: chart_rates,
        });
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const CreateGrateRate = (body, chart_rates, index) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  try {
      const response = await postInfo('/grades', body, dispatch);
      if (response.status === 201) {
        const data = response.data;
        LoaderAction(false, dispatch);
        chart_rates[index]['id'] = data.data.id 
        dispatch({
          type: GRADE,
          payload: chart_rates,
        });
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const UpdateChartRate = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  try {
      const response = await putInfo(`/chart_rates/${body.data.id}`, body, dispatch);
      if (response.status === 200) {
        const data = response.data;
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const UpdateGradeRate = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  try {
      const response = await putInfo(`/grades/${body.data.id}`, body, dispatch);
      if (response.status === 200) {
        const data = response.data;
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const DestroyChartRate = (id) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  try {
      const response = await deleteInfo(`/chart_rates/${id}`);
  } catch {
      // dispatch({ type: FAIL });
  }
};


export const GetPerLitterPriceAction = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  // LoaderAction(true, dispatch);
  try {
      const response = await getInfo(`/chart_rates/per_litter_price`, body);
      if (response.status === 200) {
        const data = response.data;

        if(data.data) {
          dispatch({
            type: PRICE_PER_LITTER_MESSAGE,
            payload: '',
          });

          dispatch({
            type: PRICE_PER_LITTER,
            payload: data.data,
          });
        } else {
          dispatch({
            type: PRICE_PER_LITTER,
            payload: {},
          });

          dispatch({
            type: PRICE_PER_LITTER_MESSAGE,
            payload: data.message,
          });
        }

        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const BuyMilkListingAction = (body, obj) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  SpinerLoaderAction(true, dispatch);
  try {
      const response = await getInfo('/buy_milks', body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: BUYMILK,
          payload: data,
        });
        SpinerLoaderAction(false, dispatch);
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const SellMilkListingAction = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  SpinerLoaderAction(true, dispatch);
  try {
      const response = await getInfo('/sell_milks', body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: SELLMILK,
          payload: data,
        });
        SpinerLoaderAction(false, dispatch);
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const GraphDataAction = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });

  dispatch({
    type: LOADER,
    payload: true,
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await getInfo('/sell_milks/graph_data', body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: GRAPHDATA,
          payload: data,
        });
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const CreateSellMilk = (body, notify, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await postInfo('/sell_milks', body, dispatch);
      if (response.status === 201) {
        const data = response.data;
        LoaderAction(false, dispatch);
        notify && toast.success(t("Congratulations! Record has been successfully Created!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const UpdateSellMilk = (body, notify, t) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  })
  dispatch({
    type: LOADER,
    payload: true,
  });

  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await putInfo(`/sell_milks/${body.id}`, body, dispatch);
      if (response.status === 200) {
        const data = response.data; 
        notify && toast.success(t("Congratulations! Record has been successfully updated!"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
    }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const GetPlansAction = (body) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });

  dispatch({
    type: LOADER,
    payload: true,
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await getInfo('/plans', body);
      if (response.status === 200) {
        const data = response.data;
        dispatch({
          type: PLANS,
          payload: data.data,
        });
        LoaderAction(false, dispatch);
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
}

export const GetNotification = (body) => async (dispatch) => {
  SpinerLoaderAction(true, dispatch);
  try {
    const response = await getInfo(`/notifications`, body);
    if (response.status === 200) {
      const data = response.data;
      console.log(data)
      dispatch({
        type: GETNOTIFICATION,
        payload: data,
      });
      SpinerLoaderAction(false, dispatch);
    } else {
      //dispatch({ type: FAIL });
    }
  } catch (error) {
    // dispatch({ type: FAIL });;
  }
};

export const MarkReadNotification = (id) => async (dispatch) => {
  try {
    const response = await putInfo(`/notifications/${id}`);
  } catch (error) {
    // dispatch({ type: FAIL });;
  }
};

export const DownloadPdf = (url, params, selectedCustomer) => async(dispatch) => {
  const lng = localStorage.getItem("lng") || 'en';

  try {
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        token: localStorage.getItem("token"),
      },
      params: {...params, locale: lng, meta: true},
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    if(selectedCustomer?.value) {
      a.download = `${selectedCustomer.label}.pdf`;
    } else {
      a.download = `${params.from_date} to ${params.to_date}.pdf`;
    }
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
}

export const scanBluetoothDevices = async (dispatch) => {
  try {
    const device = await navigator.bluetooth.requestDevice({
      filters: [{ services: ['000018f0-0000-1000-8000-00805f9b34fb'] }],
    });
    const server = await device.gatt.connect();
    const service = await server.getPrimaryService('000018f0-0000-1000-8000-00805f9b34fb');
    const characteristic = await service.getCharacteristic('00002af1-0000-1000-8000-00805f9b34fb');
    
    dispatch({
      type: PRINTER,
      payload: characteristic,
    });

    localStorage.setItem("selectedBluetoothDevice", characteristic?.service?.device);
    return characteristic?.service?.device;
  } catch (error) {
    console.error("Error connecting to Bluetooth printer:", error);
  }
};
