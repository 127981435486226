import React from 'react'
import { CustomCreditHistoryTableDesign, CustomTableDesign } from '../../styles/MyDairy/Design';
import { useSelector } from 'react-redux';

function CustomCreditHistoryTable({data, tableName, buttons}) {
  const spinnerLoader = useSelector((state) => state.Authentication.spinnerLoader);

  const renderTableHeader = () => {
    const header = Object.keys(data[0]);
    return header.map((key, index) => <th key={index}>{key}</th>);
  };

  const renderTableRows = () => {
    return data.map((item, index) => {
      const rowColor = index % 2 === 0 ? 'white' : 'grey';
      return (
        <tr key={index} className={rowColor}>
          {Object.values(item).map((value, index) => (
            <td key={index}>{value}</td>
          ))}
        </tr>
      );
    });
  };

  return (
    <CustomCreditHistoryTableDesign>
      <table>
        <thead>
            <tr className='table-heading'>
              <th colSpan={Object.keys(data[0]).length} className='table-heading'>
                <div className='table-heading-items'>
                  <h3>{tableName}</h3>
                  {/* <button>New Product</button> */}
                  {buttons}
                </div>
              </th>
            </tr> 
            <div className='scroll-tr'>
              <tr>{renderTableHeader()}</tr> 
              {renderTableRows()}
              {spinnerLoader && <div className="table-spinner-container">
                <div className="table-spinner"></div>
              </div>}
            </div>                        
        </thead>
        
        <tbody>
          {/* {renderTableRows()} */}
        </tbody>
      </table>
    </CustomCreditHistoryTableDesign>
  )
}

export default CustomCreditHistoryTable