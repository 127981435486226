import React, { useState } from 'react'
import { ContactSectionDesign } from './Design'
import { useDispatch } from 'react-redux';
import { ConstactUs } from "../../store/actions/AuthenticationActions";
import { toast } from 'react-toastify';

function ContactSection() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(ConstactUs({data: formData})).then((response)=> {
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
      })
      toast.success('Thank you for reaching out! Your message has been received, and our team will get back to you shortly.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    })  
  }

  return (
    <ContactSectionDesign>
      <section id="contact" className="contact-section">
        <h2>Contact Us</h2>
        <p>Have questions or need assistance? Reach out to us! You can also book a demo by filling out the form below.</p>
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Your Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Your Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Your Phone Number:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Your Message:</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                placeholder="Type your message here..."
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>

            <button type="submit">Send Message</button>
          </form>
        </div>
      </section>
    </ContactSectionDesign>
  )
}

export default ContactSection