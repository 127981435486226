import React from 'react';
import { HowItWorksSectionDesign } from './Design';
import { FiBarChart2 } from 'react-icons/fi';

const HowItWorksSection = () => {
  const steps = [
    {
      title: 'Step 1',
      description: 'Sign up and create your dairy owner account.',
    },
    {
      title: 'Step 2',
      description: 'Add and manage your customers and their accounts.',
    },
    {
      title: 'Step 3',
      description: 'Record daily milk transactions, both purchases, and sales.',
    },
    {
      title: 'Step 4',
      description: 'Customers receive automated receipts for their milk transactions.',
    },
    {
      title: 'Step 5',
      description: 'Access quick and detailed records for efficient decision-making.',
    },
    {
      title: 'Step 6',
      description: 'Celebrate your achievements! Analyze daily, weekly, monthly, and custom date reports with excitement.',
      icon: <FiBarChart2 />,
    },
  ];

  return (
    <HowItWorksSectionDesign>
      <section id="how-it-works" className="how-it-works-section">
        <h2>How It Works</h2>
        <div className="steps-container">
          {steps.map((step, index) => (
            <div key={index} className="step">
              <div className="step-number">{index + 1}</div>
              <div className="step-text">
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
              {step.icon && <div className="step-icon">{step.icon}</div>}
            </div>
          ))}
        </div>
      </section>
    </HowItWorksSectionDesign>
  );
};

export default HowItWorksSection;
