import styled from "styled-components";
import { ForgotPasswordDesign } from "../styles/Design";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SendOtp } from "../store/actions/AuthenticationActions";

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errors = useSelector((state) => state.Authentication.errors || {});
  const [email, setEmail] = useState('')
  const [error, SetError] = useState('')

  const VerificationClick = (e) => {
    e.preventDefault();
    if(email === ''){
      SetError("Please Enter your Email or Phone");
    } else {
      SetError('');
      dispatch(SendOtp({data: {email: email} }, navigate));
    }
  };

  return (
    <ForgotPasswordDesign>
      <div className="box">
        <form>
          <h1>Forgot Password</h1>
          <input 
            type="text"
            placeholder="Phone Or Email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} />
            <span className="error">{error || errors.email}</span>
            <button className="button" onClick={(e)=> VerificationClick(e)}>
              <b>Forgot Password</b>
            </button>
        </form>
      </div>
    </ForgotPasswordDesign>
  );
}

export default ForgotPassword;
