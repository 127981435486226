import React from 'react';
import YouTube from 'react-youtube';
import { VideoPlayerDesign } from '../AdminAction/Design';

class VideoPlayer extends React.Component {
  render() {
    const opts = {
      playerVars: {
        autoplay: 0,
      },
    };

    return (
      <VideoPlayerDesign>
        <div className="video-container">
          <YouTube videoId="0txPeO_SXic" opts={opts} />
        </div>
      </VideoPlayerDesign>
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
  }
}

export default VideoPlayer;
