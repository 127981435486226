import React, { useEffect } from 'react'
import { CustomCenterBillingTableDesign } from './Design';
import { useDispatch, useSelector } from 'react-redux';
import { GetCustomerInformation } from '../../store/actions/MyDairyActions';
import { useTranslation } from "react-i18next";

function CustomCenterBillingTable({params}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const customerinfo = useSelector((state) => state.MyDairy.customerinfo?.data || []);
  
  useEffect(()=> {
    dispatch(GetCustomerInformation({...params, meta: true, sort: {key: 'created_at', direction: 'asc'}}));
  }, [params, dispatch])

  const data = customerinfo.length  && customerinfo.map(item => {
    const { customer,fat, clr, snf, quntity, amount } = item;
    return {
      [t('Name')]:  customer.name,
      [t('FAT')]: fat,
      [t('CLR')]: clr,
      [t('SNF')]: snf,
      [t('QUANTITY')]: quntity,
      [t('AMOUNT')]: amount,
    };
  });

  const renderTableHeader = () => {
    const header = Object.keys(data[0]);
    return header.map((key, index) => <th key={index}>{key}</th>);
  };

  const renderTableRows = () => {
    return data.map((item, index) => {
      const rowColor = index % 2 === 0 ? 'white' : 'grey';
      return (
        <tr key={index} className={rowColor}>
          {Object.values(item).map((value, index) => (
            <td key={index}>{value}</td>
          ))}
        </tr>
      );
    });
  };
  
  return (
    <CustomCenterBillingTableDesign>
      <div className="table-container">
        {data.length ? 
        <table>
          <thead>
            <tr colSpan={Object.keys(data[0]).length}></tr>
            <tr>{renderTableHeader()}</tr>
          </thead>  
          <tbody>
            {renderTableRows()}
          </tbody>
        </table> : 

        <p>{t("No Data Found")}</p>
        }
      </div>
    </CustomCenterBillingTableDesign>
  )
}

export default CustomCenterBillingTable