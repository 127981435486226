import { useDispatch, useSelector } from 'react-redux';
import profile from '../../assets/images/login.jpg';
import { ViewCustomerDesign } from "../../styles/MyDairy/Design";
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { GetCustomerAction } from '../../store/actions/MyDairyActions';
import DepositeHistory from './DepositeHistory';
import CreditHistory from './CreditHistory';
import { useTranslation } from "react-i18next";

function ViewCustomers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useParams();
  const { t } = useTranslation();
  const [perform, setPerform] = useState("");
  const customer = useSelector((state) => state.MyDairy.customer || {});

  useEffect(()=> {
    dispatch(GetCustomerAction(location.id));
  }, [perform])

	return (
    <ViewCustomerDesign>
      <div className="info">
        <img src={customer.image?.url || profile} alt="Milk Dairy" />
        
        <div className="name-number">
          <h2>{customer.name}</h2>
          <div className="number">+91{customer.phone_number}</div>
        </div>
        
        <div className="edit">
          <button onClick={()=> navigate(`/customer/edit/${customer.id}`)}>{t("Edit")}</button>
        </div>
      </div>
      <hr/>
      <div className="contents">
        <div className="box">
          <h2>{t("Credited")}</h2>
          <p>{customer.customer_account?.credit}</p>
        </div>

        <div className="box">
          <h2>{t("Deposited")}</h2>
          <p>{customer.customer_account?.deposit}</p>
        </div>

        <div className="box">
          <h2>{t("Balance")}</h2>
          <p>{customer.customer_account?.balance}</p>
        </div>
      </div>

      <CreditHistory customer_id={location.id} perform={perform} setPerform={setPerform} customer={customer} />
      <DepositeHistory customer_id={location.id} perform={perform} setPerform={setPerform} customer={customer} />
    </ViewCustomerDesign>
  );
}
export default ViewCustomers;
