import React, { useState } from 'react'
import { ResetPasswordDesign } from '../../styles/MyDairy/Design'
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import Icon from 'react-icons-kit';
import { useDispatch, useSelector } from 'react-redux';
import { ResetPasswordAction } from '../../store/actions/MyDairyActions';
import { useTranslation } from 'react-i18next';

function ResetPassword() {
  const errors = useSelector((state) => state.Authentication.errors || {});
  const [icon, setIcon] = useState(eyeOff);
  const [type, setType] = useState('password');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [passwordIcon, setPasswordIcon] = useState(eyeOff);
  const [passwordType, setPasswordType] = useState('password');
  const [passwordcIcon, setPasswordcIcon] = useState(eyeOff);
  const [passwordcType, setPasswordcType] = useState('password');
  
  const [formData, setFormData] = useState({
    old_password: '',
    new_password: '',
    confirm_new_password: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
  }

  const handlePasswordToggle = () => {
    if (passwordType==='password'){
      setPasswordIcon(eye);
      setPasswordType('text')
    } else {
      setPasswordIcon(eyeOff)
      setPasswordType('password')
    }
  }

  const handlePasswordcToggle = () => {
    if (passwordcType==='password'){
      setPasswordcIcon(eye);
      setPasswordcType('text')
    } else {
      setPasswordcIcon(eyeOff)
      setPasswordcType('password')
    }
  }

  const SubmitResetPassword = (e) => {
    e.preventDefault();
    dispatch(ResetPasswordAction({data: formData}, setFormData, true, t))
  }

  return (
    <ResetPasswordDesign>
      <h1>{t("Change Password")}</h1>
      <div className='contener'>
        <div className='box'>
          <form onSubmit={(e) => SubmitResetPassword(e)}>
            <div className="lable-input">
              <div className="password-input">
                <input
                  type={type}
                  placeholder={t("Old Password")}
                  name="old_password"
                  value={formData.old_password}
                  required
                  onChange={handleChange}
                />
                <span className="eye-buttton" onClick={handleToggle}>
                  <Icon className="absolute mr-10" icon={icon} size={25}/>
                </span>
              </div>
              <span className="error">{errors.old_password}</span>
            </div>

            <div className="lable-input">
              <div className="password-input">
                <input
                  type={passwordType}
                  placeholder={t("New Password")}
                  name="new_password"
                  value={formData.new_password}
                  required
                  onChange={handleChange}
                />
                <span className="eye-buttton" onClick={handlePasswordToggle}>
                  <Icon className="absolute mr-10" icon={passwordIcon} size={25}/>
                </span>
              </div>
              <span className="error">{errors.new_password}</span>
            </div>

            <div className="lable-input">
              <div className="password-input">
                <input
                  type={passwordcType}
                  placeholder={t("Confirm New Password")}
                  name="confirm_new_password"
                  value={formData.confirm_new_password}
                  required
                  onChange={handleChange}
                />
                <span className="eye-buttton" onClick={handlePasswordcToggle}>
                  <Icon className="absolute mr-10" icon={passwordcIcon} size={25}/>
                </span>
              </div>
              <span className="error">{errors.password}</span>
            </div>
            <button className="button" type='submit'><b>{t("Change Password")}</b></button>
          </form>
        </div>
      </div>
    </ResetPasswordDesign>
  )
}

export default ResetPassword