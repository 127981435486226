import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ForgotPassword from "../pages/forgot_password";
import Login from "../pages/login";
import ResetPassword from "../pages/reset_password";
import ResetPasswordSuccessfully from "../pages/reset_password_successfully";
import Signup from "../pages/signup";
import Verification from "../pages/verification";
import Header from '../components/Headers/Header';
import LandingPage from '../pages/LandingPage/LandingPage';

function Guest() {
  return(
    <>
      <Header/>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/verification" element={<Verification />} />    
        <Route path="/reset_password" element={<ResetPassword />} />   
        <Route path="/reset_password_successfully" element={<ResetPasswordSuccessfully />} />
      </Routes>
    </>
  );
}

export default Guest;
