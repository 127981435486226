import React, { useEffect, useState } from 'react'
import Graph from './Graph'
import moment from 'moment';
import { EarningsDesign } from './Design';
import { GraphDataAction } from '../../store/actions/MyDairyActions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

function Earnings() {
  const dispatch = useDispatch();
  const graphData = useSelector((state) => state.MyDairy.graphData || {});
  const [isUpdated, setIsUpdated] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const { t } = useTranslation();
  const startDate = moment().tz('Asia/Kolkata').startOf('week').format('YYYY-MM-DD');
  const endDate = moment().tz('Asia/Kolkata').endOf('week').format('YYYY-MM-DD');
  const [filters, setFilters] = useState({
    from_date: startDate,
    to_date: endDate,
  });

  useEffect(()=> {
    setIsUpdated(false);
    dispatch(GraphDataAction(filters))
  }, [filters, dispatch])

  useEffect(() => {
    if(graphData.data){
      setData(graphData.data)
      setMeta(graphData.meta)
      setIsUpdated(true);
    }
  }, [graphData]);

  return (
    <EarningsDesign>
      <div className='filters-section'>
        <h2>{t("Earnings Till Date")} : {meta.total_earning}</h2>
      </div>
      <div className='filters-section'>
        <div className='input-container'>
          <div className='full-input'>
            <label>{t("From Date")}</label>
            <input
              type="date"
              placeholder="From Date"
              value={filters.from_date}
              onChange={(e) => setFilters({ ...filters, from_date: e.target.value })}
            />
          </div>

          <div className='full-input'>
            <label>{t("To Date")}</label>
            <input
              type="date"
              placeholder="To Date"
              value={filters.to_date}
              onChange={(e) => setFilters({ ...filters, to_date: e.target.value })}
            />
          </div>
        </div>
      </div>
      
      {isUpdated && <div className='graph-charts-container'>
        <div className='graph-charts'>
          {data.length && <Graph type={'bar'} data={data} />}
        </div>

        <div className='graph-charts'>
          {data.length && <Graph type={'line'} data={data} />}
        </div>
      </div>}
    </EarningsDesign>
  )
}

export default Earnings