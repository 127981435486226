// MembershipWarningPopup.js
import React from 'react';
import { MembershipWarningPopupDesign } from '../../styles/MyDairy/Design';
import { useTranslation } from 'react-i18next';

function MembershipWarningPopup({ daysLeft, onClose }) {
  const { t } = useTranslation();
  const daysLeftPair = {
    0: 'today',
    1: 'in one days',
    2: 'in two days',
    3: 'in three days',
    4: 'in four days',
    5: 'in five days',
  }
  return (
    <MembershipWarningPopupDesign>
      <div className="membership-warning-popup">
        <span onClick={onClose} className="close-button">
          &times;
        </span>
        <p>{t("Your membership plan is set to expire")} {t(daysLeftPair[daysLeft])}.</p>
      </div>
    </MembershipWarningPopupDesign>
  );
};

export default MembershipWarningPopup;
