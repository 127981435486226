import { useState } from "react";
import logoImage from '../../assets/images/MilkDairyLogo/Blue Fresh Milk Logo Template (1).png'
import profile from "../../assets/images/login.jpg"
import { useNavigate } from "react-router-dom";
import { HeadersDesign } from "../../styles/Design";


function Header() {
  const [logo] =  useState(logoImage)
  const [isHeaderVisible] = useState(true);
  const [dynamicStyle] = useState({});
  const navigate = useNavigate();

  return (
    <HeadersDesign>
      <header style={isHeaderVisible ? dynamicStyle : {}} className={`header ${isHeaderVisible ? 'visible' : ''}`}>
        <div className="log-menu">
          <div className="logo" onClick={()=> navigate('/')}>
            <img src={logo} alt="Milk Dairy" />
          </div>

          {window.location.pathname !== '/login' && <button className="button" onClick={()=> navigate('/login') }>
            <div className="login">
              <div className="login-part1">
                LOGIN
              </div>
              <div className="login-part2">
                <img src={ profile } alt="Milk Dairy" />
              </div>
            </div>
          </button>}
        </div>
      </header>
    </HeadersDesign>
  );
}

export default Header;