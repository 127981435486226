import React from 'react'
import styled from 'styled-components';
import { Modal, ModalContent } from './Design';

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledModalContent = styled(ModalContent)`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 10px;
    color: #28728f;
  }

  input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #28728f;
    border-radius: 4px;
    box-sizing: border-box; 
  }

  button {
    padding: 10px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #28728f;
    color: #fff;
  }

  span {
    margin-top: -10px;
    margin-bottom: 10px;
  }
`;

function NewChart({newChartName, setNewChartName, handleCreateChart, handleModalClose, errors}) {
  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      handleCreateChart();
    }
  };

  return (
    <StyledModal>
      <StyledModalContent>
        <h2>Create New Chart</h2>
        <input
          type='text'
          placeholder='Enter chart name'
          value={newChartName}
          autoFocus
          onKeyDown={handleEnterKey}
          onChange={(e) => setNewChartName(e.target.value)}
        />
        <span className="error">{errors.name}</span>

        <button onClick={handleCreateChart}>Create</button>
        <button onClick={handleModalClose}>Cancel</button>
      </StyledModalContent >
    </StyledModal>
  )
}

export default NewChart