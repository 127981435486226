import { ResetPasswordSuccessfullyDesign } from "../styles/Design";
import { useNavigate } from "react-router-dom";
import verified from '../assets/images/MilkDairyLogo/verified-96.png'
import { useSelector } from "react-redux";
import { useEffect } from "react";

function ResetPasswordSuccessfully() {
  const navigate = useNavigate();
  const passwordReseted = useSelector((state) => state.Authentication.passwordReseted);
  useEffect(() => {
    if(passwordReseted === false){
      navigate('/')
    }
  }, [navigate, passwordReseted]);

  return (
    <ResetPasswordSuccessfullyDesign>
			<div>
        <img className="verifcation-tick" src={verified} alt="Milk Dairy"/>
        <h1>password reset successfully</h1>
        <p><a href="/login"><b><i>login here</i></b></a></p>
      </div>
    </ResetPasswordSuccessfullyDesign>
  );
}

export default ResetPasswordSuccessfully;
