import React from 'react';
import { BuyOptionsDesign } from '../../styles/MyDairy/Design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

function BuyOptions() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBuyMilk = () => {
    navigate('/buy-milk');
  };

  const handleBuyMilkShortcut = () => {
    navigate('/buy-milk-sort');
  };

  return (
    <BuyOptionsDesign>
      <div className="buy-options-container">
        <h1 className="buy-options-title">{t('Milk Buy Options')}</h1>
        <p>{t('Both options')}</p>
        <div className="buy-options-buttons">
          <button className="buy-options-button" onClick={handleBuyMilk}>{t('Buy Milk')}</button>
          <button className="buy-options-button" onClick={handleBuyMilkShortcut}>{t('Buy Milk (Shortcut)')}</button>
        </div>
      </div>
    </BuyOptionsDesign>
  );
}

export default BuyOptions;
