import React, { useEffect, useState } from 'react';
import { ChartsDesign } from './Design';
import { ChartListingAction, AddChartAction, DestroyChartAction } from '../../store/actions/MyDairyActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import NewChart from './NewChart';
import DeleteIcon from '../Elements/DeleteIcon';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";

function Charts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newChartName, setNewChartName] = useState('');
  const [perform, setPerform] = useState('');
  const { t } = useTranslation();
  const charts = useSelector((state) => state.MyDairy.charts || []);
  const errors = useSelector((state) => state.Authentication.errors || {});

  useEffect(() => {
    dispatch(ChartListingAction());
  }, [perform, dispatch]);

  const handleChartClick = (el) => {
    const state = {
      label: el.name,
    }
    navigate(`/chart/${el.id}`, { state: state });
  };

  const handleAddChartClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewChartName('');
  };

  const handleCreateChart = () => {
    dispatch(AddChartAction({ data: {name: newChartName} }, setIsModalOpen, true, setPerform, setNewChartName, t));
  };

  const DeleteChart=(event, id)=>{
    event.stopPropagation();
    
    Swal.fire({
      title: t("Are you sure you want to delete this Chart?"),
      text: t("This action is irreversible and the Chart data will be permanently deleted!"),
      icon: "warning",
      color: "white",
      showCancelButton: true,
      confirmButtonColor: "#28728f",
      cancelButtonColor: "#28728f",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t('Cancel'),
      background: "#a4c4d1",
      customClass: {
        confirmButton: "swal-button swal-button--danger",
        cancelButton: "swal-button",
        popup: "swal-popup--grey",
        content: "swal-content--grey",
        actions: "swal-actions--center",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DestroyChartAction(id, true, setPerform, t));
      } else {
        return;
      }
    });
  };

  return (
    <ChartsDesign>
      <h1>{t("Charts")}</h1>
      <div className='contener'>
        {charts.map((el) => (
          <div
            className='contener-box'
            key={el.id}
            onClick={() => handleChartClick(el)}
          >
            <div className='delete-icon' onClick={(event) => DeleteChart(event, el.id)}>
              <DeleteIcon/>
            </div>
            <h3>{el.name}</h3>
          </div>
        ))}

        <div onClick={handleAddChartClick} className='contener-box'>
          <h1>+</h1>
        </div>
      </div>

      {isModalOpen && (
        <NewChart newChartName={newChartName} setNewChartName={setNewChartName} handleCreateChart={handleCreateChart} handleModalClose={handleModalClose} errors={errors} />
      )}
    </ChartsDesign>
  );
}

export default Charts;
