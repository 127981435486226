import axios from "axios";
import process from "process";
import { LoaderAction } from "../store/actions/MyDairyActions";
export const ERRORS = 'ERRORS';
const boolean = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const BASE_URL = boolean
  ? "http://localhost:3000"
  : "https://api.milkdairy.in";

const logoutOnUnauthorized =()=>{
  localStorage.clear();
  window.location.href = "/";
}

export const getInfo = (url, params = {}) => {
  const lng = localStorage.getItem("lng") || 'en';
  const token = localStorage.getItem("token");
  const Headers = {
    "Content-Type": "application/json",
  };
  params['locale'] = lng;
  if (token) {
    Headers.token = token;
  }
  const config = {
    headers: Headers,
  };
  if (params) {
    config.params = params;
  }
  return axios
    .get(`${BASE_URL}${url}`, config)
    .then(async (response) => response)
    .catch((err) => {
      console.log("errrorss", err.response.data.errors);
      if(err.response.statusText === 'Unauthorized'){
        logoutOnUnauthorized();
      }
    });
};

export const postInfo = (url, body, dispatch) => {
  const lng = localStorage.getItem("lng") || 'en';
  const token = localStorage.getItem("token");
  const Headers = {
    'Content-Type': 'multipart/form-data',
  };
  if (token) {
    Headers.token = token;
  }
  return axios
    .post(`${BASE_URL}${url}?locale=${lng}`, body, {
      headers: Headers,
    })
    .then(async (response) => response)
    .catch((errors) => {
      if(errors.response?.data?.errors){
        LoaderAction(false, dispatch);
        dispatch({
          type: ERRORS,
          payload: errors.response?.data?.errors,
        });
        localStorage.setItem("errors", JSON.stringify(errors.response?.data?.errors));
      }
      if(errors.response.statusText === 'Unauthorized' && url !== '/auth/login'){
        logoutOnUnauthorized();
      }
    });
};

export const putInfo = (url, body, dispatch) => {
  const lng = localStorage.getItem("lng") || 'en';
  const token = localStorage.getItem("token");
  const Headers = {
    'Content-Type': 'multipart/form-data',
  };
  if (token) {
    Headers.token = token;
  }
  return axios
    .put(`${BASE_URL}${url}?locale=${lng}`, body, {
      headers: Headers,
    })
    .then(async (response) => response)
    .catch((errors) => {
      if(errors.response?.data?.errors){
        LoaderAction(false, dispatch);
        dispatch({
          type: ERRORS,
          payload: errors.response?.data?.errors,
        });
        localStorage.setItem("errors", JSON.stringify(errors.response?.data?.errors));
      }
      if(errors.response.statusText === 'Unauthorized'){
        logoutOnUnauthorized();
      }
    });
};

export const deleteInfo = (url, dispatch) => {
  const token = localStorage.getItem("token");
  const Headers = {
    'Content-Type': 'multipart/form-data',
  };
  if (token) {
    Headers.token = token;
  }
  return axios
    .delete(`${BASE_URL}${url}`, {
      headers: Headers,
    })
    .then(async (response) => response)
    .catch((errors) => {
      if(errors.response?.data?.errors){
        LoaderAction(false, dispatch);
        dispatch({
          type: ERRORS,
          payload: errors.response?.data?.errors,
        });
        localStorage.setItem("errors", JSON.stringify(errors.response?.data?.errors));
      }
      if(errors.response.statusText === 'Unauthorized'){
        logoutOnUnauthorized();
      }
    });
};