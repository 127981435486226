import styled from "styled-components";

export const CenterBillingDesign = styled.div`
h1, h2 {
  color: #28728f;
  text-align: center;
  padding-top: 10px;
} 

.farm {
  display: flex;
  justify-content: center;
  margin-top: -30px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #fff;
}

.form-contener {
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box; /* Ensure padding and border are included in width */
  padding: 20px; /* Add padding for better spacing */
}

.contents {
  padding: 0px 10px; /* Adjust padding for smaller screens */
  display: flex;
  flex-wrap: wrap; /* Allow contents to wrap on smaller screens */
  align-items: center;
  justify-content: space-around;
  gap: 15px; /* Adjust the gap between contents */
}

select, input {
  border: 2px solid #28728f;
  width: 90%; /* Make inputs full width */
  padding: 10px 15px;
  border-radius: 30px;
  margin: 10px 0;
  box-sizing: border-box;
}

.tab-group {
  display: flex;
  flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.tab {
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
  margin-bottom: 10px;
}

.tab.active {
  background-color: #28728f;
  color: #fff;
}

.content1,
.content2 {
  width: 100%; /* Make both content divs full width on smaller screens */

  @media (min-width: 768px) {
    width: 47%; /* Adjust width for larger screens */
  }

  @media (min-width: 1024px) {
    width: 47%; /* Adjust width for even larger screens */
  }
}

.save-button button {
  display: flex;
  justify-content: center;
  width: 100%; /* Make the button full width */
  max-width: 300px;
  margin: 1em auto;
  border: none;
  color: white;
  padding: 1em 2em;
  text-align: center;
  border-radius: 50px;
  font-size: 15px;
  background-color: #28728f;
}

.save-button button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.averages {
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap; /* Allow averages to wrap on smaller screens */
  justify-content: space-around;
  padding: 0px 20px; /* Adjust padding */
  margin-bottom: 10px;
}

.both {
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.avg input {
  border: 2px solid #28728f;
  width: 100%;
  padding: 10px 15px;
  border-radius: 30px;
  margin: 10px 0;
  box-sizing: border-box;
  background-color: #a4c4d1;
}

p {
  text-align: center;
  color: #28728f;
}

`;

export const ActualCenterBillingDesign = styled.div`
.farm {
  display: flex;
  justify-content: center;
}

h1, h2 {
  color: #28728f;
  text-align: center;
  padding-top: 10px;
}

label {
  margin-top: 5px;
  font-weight: bold;
  display: block;
}

.form-contener {
  border-radius: 8px;
  border: 2px solid #28728f;
  width: 80%;
  margin: 0 auto;
}

.form-conteners{
  margin-top: 20px;
  border-radius: 8px;
  border: 2px solid #28728f;
  width: 45%;
  margin: 0 auto;
}

.contents {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}

select,
input {
  border: 2px solid #28728f;
  padding: 10px 15px;
  border-radius: 30px;
  margin: 10px 0;
  box-sizing: border-box;
  width: 100%;
}

.save-button button {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  margin: 1em auto;
  border: none;
  color: white;
  padding: 1em 2em;
  text-align: center;
  border-radius: 50px;
  font-size: 15px;
  background-color: #28728f;
}

form {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
}

#data {
  background-color: #a4c4d1;
}

.averages {
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0px 20px;
  margin-bottom: 10px;
}

.both {
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.avg input {
  border: 2px solid #28728f;
  width: 100%;
  padding: 10px 15px;
  border-radius: 30px;
  margin: 10px 0;
  box-sizing: border-box;
  background-color: #a4c4d1;
}
@media screen and (max-width: 360px) {
  .heading {
    font-size: 20px;
  }

  .label,
  .input,
  .select,
  .text {
    font-size: 10px;
    padding: 2px;
  }

  .submit-button {
    font-size: 10px;
    padding: 2px 14px;
  }
}
`;

export const CustomCenterBillingTableDesign = styled.div`
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
th {
  background-color: #28728F;
  color: white;
}
th, td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
@media (min-width: 360px) and (max-width: 460px) {
  table {
    width: 100%;
  }
  th, td {
    font-size: 10px;
  }
}
@media (min-width: 279px) and (max-width: 360px) {
  table {
    width: 100%;
  }
  th, td {
    font-size: 8px;
  }
}
`;

export const ChartsDesign = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 10px;

h1 {
  color: #28728f;
  font-size: 40px;
}

.contener {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 30px;
}

.contener h1 {
  color: #fff;
}

.contener .contener-box {
  width: 14vw;
  height: 14vh;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #28728f;
  cursor: pointer;
  text-align: center;
  border-radius: 10px;
  border: 2px solid #28728f;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: column;
}

.contener .contener-box > p {
  margin: 0px;
  font-size: larger;
}

.contener .contener-box:hover {
  background-color: #fff;
  color: #28728f;
  border: 2px solid #28728f;
  h1 {
    color: #28728f;
  }

  .delete-icon {
    display: block;
    &:hover {
      border: 2px solid #28728f;
      border-radius: 100px;
    }
  }
}

.delete-icon {
  display: none;
  margin: -16px 0px 0px -127px;
}

@media (max-width: 1050px) {
  .delete-icon {
    margin: -16px 0px 0px -100px;
  }
}

@media (max-width: 850px) {
  .contener .contener-box {
    width: 20vw;
    height: 14vh;
  }
}

@media (max-width: 700px) {
  .contener .contener-box {
    width: 30vw;
    height: 14vh;
  }
}

@media (max-width: 500px) {
  .delete-icon {
    margin: -16px 0px 0px -40px;
  }
}

@media (max-width: 500px) {
  .delete-icon {
    margin: -16px 0px 0px -100px;
  }

  .contener .contener-box {
    width: 40vw;
    height: 14vh;
  }
}
}
}
`;

export const Modal = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background: #000;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    padding: 10px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #3d5af1;
    color: #fff;
  }

thead th {
  background-color: #28728f;
  color: #fff;
}

@media (min-width: 385px) and (max-width: 675px) {
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
}
@media (min-width: 200px) and (max-width: 384px) {

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}  
`;

export const ViewChartDesign = styled.div`
  .table-container{ 
    overflow-x: auto;
    max-height: 66vh;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    border: none;
  }

  th {
    background-color: #28728f;
    color: #fff;
    position: sticky;
    top: 0;
  }

  th {
    background-color: #28728f;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  td[contenteditable='true']:focus {
    outline: 2px solid #a4c4d1;
  }

  button {
    display: flex;
    justify-content: center;
    width: 150px;
    max-width: 300px;
    margin: 1em auto;
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    border-radius: 4px;
    font-size: 15px;
    background-color: #28728f;
    align-items: center;
    cursor: pointer;
  }

  p {
    cursor: pointer;
    color: #28728f;
    font-style: italic;
    text-decoration: underline;
    text-align: center;
  }

  .actions {
    width: 0%;
  }

  .tab-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
  }

  .popup-content h2 {
    text-align: center;
    color: #28728f;
  }
  
  .input-container {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    align-items: stretch;
    flex-direction: column;
  }

  .full-input {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
  }
  
  .input-container label {
  }
  
  .input-container input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100px;
  }

  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 6px;
  }
  
  .popup-content button {
    margin: 0 10px;
    padding: 8px 16px;
    background-color: #28728f;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-content button:hover {
    background-color: #5fb1d2;
  }
`;

export const TransactionsDesign = styled.div`
  .filters-section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-content: center;
    padding-left: 40px;
    padding-top: 10px;
  }

  .actions-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .bill-download  {
    display: flex;
    justify-content: center;
    width: 150px;
    max-width: 300px;
    margin: 1em auto;
    border: none;
    color: white;
    padding: 1em 2em;
    text-align: center;
    border-radius: 50px;
    font-size: 15px;
    background-color: #28728f;
  }

  .no-data {
    text-align: center;
    color: #28728f;
  }

  .input-container {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .full-input {
    width: 100%;
  }

  label {
    margin: 10px;
  }

  select, input {
    border: 2px solid #28728f;
    width: 100%; 
    padding: 10px 15px;
    border-radius: 30px;
    margin: 10px 0;
    box-sizing: border-box;
  }

  .table-container {
    overflow-x: auto;
    max-height: 64vh;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    border: none;
  }

  th {
    background-color: #28728f;
    color: #fff;
    position: sticky;
    top: 0;
  }

  th {
    background-color: #28728f;
    cursor: pointer;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  td[contenteditable='true']:focus {
    outline: 2px solid #a4c4d1;
  }

  .summury-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
  }

  .product-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    color: #28728f;
    font-style: italic;
    text-decoration: underline;
  }

  p {
    text-align: center;
  }

  .circular-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #28728f;
    color: #fff;
    font-size: 18px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 30px;
    right: 40px;
    font-size: xx-large;
  }
  
  .circular-button:hover {
    background-color: #a4c4d1;
  }
`;

export const EarningsDesign = styled.div`
  h2 {
    color: #28728f;
    text-align: center;
  }

  .filters-section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-content: center;
    padding-left: 40px;
  }

  .input-container {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .full-input {
    width: 100%;
  }

  label {
    margin: 10px;
  }

  select, input {
    border: 2px solid #28728f;
    width: 100%; 
    padding: 10px 15px;
    border-radius: 30px;
    margin: 10px 0;
    box-sizing: border-box;
  }

  .graph-charts {
    padding: 40px;
  }
`;

export const PdfFormatSelectorDesign = styled.div`
p, h1 {
  color: #28728f;
  text-align: center;
  padding: 10px;
}

.pdf-format-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly ;
  padding: 40px;
}

.pdf-format-item {
  margin-bottom: 20px;
  width: 200px;
}

.pdf-format-item button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #28728f;
  color: #fff;
  border: none;
  cursor: pointer;
}

.pdf-format-item button:hover {
  background-color: #5fb1d2;
}

.pdf-format-item img {
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.PopupContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.PopupContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  h2 {
    margin-top: 0;
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .datepicker, select,
  input {
    width: -webkit-fill-available;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 3px;
    border: 2px solid #28728f;
  }

  .css-17z0cbu-control {
    width: -webkit-fill-available;
    padding: 0px;
    border-radius: 3px;
  }
  
  .css-1mo8iv9-control {
    width: -webkit-fill-available;
    padding: 0px;
    border-radius: 3px;
  }
  
  .button-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: center;
  }

  button {
    padding: 8px 16px;
    background-color: #28728f;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 10px;
  }

  button:hover {
    background-color: #5fb1d2;
  }
}
`;

export const VideoPlayerDesign = styled.div`
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
`;