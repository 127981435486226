import React from 'react';
import { PricingSectionDesign } from './Design';
import { useNavigate } from 'react-router-dom';

const PricingSection = () => {
  const navigate = useNavigate();

  const plans = [
    {
      title: '1 Month',
      price: '99',
      duration: '30 Days',
    },
    {
      title: '3 Months',
      price: '249',
      duration: '90 Days',
    },
    {
      title: '6 Months',
      price: '499',
      duration: '180 Days',
      recommended: true,
    },
    {
      title: '1 Year',
      price: '999',
      duration: '365 Days',
    },
    {
      title: '5 Years',
      price: '3,999',
      duration: '1825 Days',
    },
  ];

  return (
    <PricingSectionDesign>
      <section id="pricing" className="pricing-section">
        <h2>Choose Your Plan</h2>
        <div className="plans-container">
          {plans.map((plan, index) => (
            <div key={index} className={`plan${plan.recommended ? ' recommended' : ''}`} >
              {plan.recommended && <div className="recommended-badge">Recommended</div>}
              <div className="plan-header">
                <h3>{plan.title}</h3>
                <p className="duration">{plan.duration}</p>
              </div>
              <div className="price">₹{plan.price}</div>
              <button className="cta-button" onClick={()=> navigate('/signup')}>Get Started</button>
            </div>
          ))}
        </div>
      </section>
    </PricingSectionDesign>
  );
};

export default PricingSection;
