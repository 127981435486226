import { ERRORS } from "../../utils/fetchServices";
import { DATA, LNG, LOADER, SPINER_LOADER, LOGIN, OTP, OTPVERIFIED, RESETED, ROLE } from "../actions/AuthenticationActions";

const initialState = {
  errors: {},
  loggedIn: false,
  role: null,
  profile: {},
  otp: false,
  passwordReseted: false,
  otpVerified: false,
  loader: false,
  spinnerLoader: false,
  lng: 'en'
}

const Authentication = (state = initialState, action) => {
  switch (action.type) {
    case ERRORS:
        return {
            ...state,
            errors: action.payload,
        };

    case DATA:
        return {
            ...state,
            profile: action.payload,
        };

    case ROLE:
        return {
            ...state,
            role: action.payload,
        };

    case LOGIN:
        return {
            ...state,
            loggedIn: action.payload,
        };
    case OTP:
        return {
            ...state,
            otp: action.payload,
        };
    case RESETED:
        return {
            ...state,
            passwordReseted: action.payload,
        };
    case OTPVERIFIED:
        return {
            ...state,
            otpVerified: action.payload,
        };
    case LOADER:
        return {
            ...state,
            loader: action.payload,
        };
    case SPINER_LOADER:
        return {
            ...state,
            spinnerLoader: action.payload,
        };
    case LNG:
        return {
            ...state,
            lng: action.payload,
        };
    default: {
        return state;
    }
    }
};
    
export default Authentication;