import React, { useEffect, useState } from "react";
import { BuyMilkDesign } from "../../styles/MyDairy/Design";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../Elements/CustomSelect";
import { useLocation, useNavigate, useParams } from "react-router";
import { CustomerListingAction, GetBuyCustomerMilk, GetPerLitterPriceAction, UpdateBuyCustomerMilk } from "../../store/actions/MyDairyActions";
import moment from "moment";
import { useTranslation } from "react-i18next";

function EditBuyMilk({action}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locationUrl = useParams();
  const { t } = useTranslation();
  const price_per_litter = useSelector((state) => state.MyDairy.price_per_litter || {});
  const price_per_litter_msg = useSelector((state) => state.MyDairy.price_per_litter_msg);
  const [params, setParams] = useState(location.state);
  const errors = useSelector((state) => state.Authentication.errors || {});
  const profile = useSelector((state) => state.Authentication.profile || {});
  const customers = useSelector((state) => state.MyDairy.customers || []);
  const buyMilkObj = useSelector((state) => state.MyDairy.buyMilkObj || {});
  const FatEnum = {
    "Fat": 'fat',
    "Fat/CLR": "fat_clr",
    "Fat/SNF": "fat_snf",
  }

  const ShiftEnum = {
    "morning": 'Morning',
    "evening": "Evening",
  }

  const FatEnumRevert = {
    "fat": "Fat",
    "fat_clr": "Fat/CLR",
    "fat_snf": "Fat/SNF",
  }
  const [formData, setFormData] = useState({
    customer_id: '',
    fat: "",
    clr: "",
    snf: "",
    quntity: "",
    amount: "",
    date: params?.selectedDate,
    shift: params?.selectedShift?.toLowerCase(),
    rate_type: FatEnum[params?.selectedRateType],
    chart_id: params?.selectedMilkType?.value,
  });
  const [perltr, Setperltr] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [snfClr, setSnfClr] = useState('')

	useEffect(()=> {
    dispatch(CustomerListingAction());
		dispatch(GetBuyCustomerMilk(locationUrl.id));
    if(action === 'edit'){
    }
	}, [])

  useEffect(()=> {
    setSelectedCustomer({value: buyMilkObj.customer?.id, label: `${buyMilkObj.customer?.name} - #${buyMilkObj.customer?.sid}`})
    setFormData({
      ...formData,
      customer_id: buyMilkObj.customer?.id,
      fat: buyMilkObj.fat,
      clr: buyMilkObj.clr,
      snf: buyMilkObj.snf,
      quntity: buyMilkObj.quntity,
      amount: buyMilkObj.amount,
      date: buyMilkObj.data,
      shift: buyMilkObj.shift,
      rate_type: buyMilkObj.rate_type,
      chart_id: buyMilkObj.chart?.id,
    })

    if(!params?.selectedDate) {
      setParams({
        selectedDate: buyMilkObj.date,
        selectedShift: ShiftEnum[buyMilkObj.shift],
        selectedRateType: FatEnumRevert[buyMilkObj.rate_type],
        selectedMilkType: {value: buyMilkObj.chart?.id, label: buyMilkObj.chart?.name},
        id: locationUrl.id,
      })
    } else {
      setFormData({
        ...formData,
        customer_id: buyMilkObj.customer?.id,
        fat: buyMilkObj.fat,
        clr: buyMilkObj.clr,
        snf: buyMilkObj.snf,
        quntity: buyMilkObj.quntity,
        amount: buyMilkObj.amount,
        date: params?.selectedDate,
        shift: params?.selectedShift?.toLowerCase(),
        rate_type: FatEnum[params?.selectedRateType],
        chart_id: params?.selectedMilkType?.value,
      });
    }
  }, [buyMilkObj])

  useEffect(()=> {
    if(formData.rate_type === 'fat_clr') {
      setSnfClr(formData.clr);
    } else if(formData.rate_type === 'fat_snf'){
      setSnfClr(formData.snf);
    }
	}, [formData.clr, formData.snf])

  useEffect(()=> {
    if(formData.rate_type === 'fat_clr' && Number(formData.clr) > 0 && Number(formData.fat) > 0) {
      dispatch(GetPerLitterPriceAction({fat: formData.fat, clr: formData.clr, chart_id: formData.chart_id}))
    } else if(formData.rate_type === 'fat_snf' && Number(formData.snf) > 0 && Number(formData.fat) > 0){
      dispatch(GetPerLitterPriceAction({fat: formData.fat, snf: formData.snf, chart_id: formData.chart_id}))
    }
	}, [snfClr, formData.fat])

  useEffect(()=> {
    if(formData.rate_type === 'fat') {
      setFormData({ ...formData, amount: calculateAmount()});
      Setperltr((Number(formData.fat)*Number(profile.fate_rate)).toFixed(2))
    } else if(formData.rate_type === 'fat_clr' || formData.rate_type === 'fat_snf') {
      setFormData({ ...formData, amount: calculateAmount()});
      Setperltr(price_per_litter.rate);
    }
	}, [formData.fat, formData.quntity, price_per_litter])

  const customerOptions = customers.map((customer) => ({
    value: customer.id,
    label: `${customer.name} - #${customer.sid}`,
  }));  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);
    setFormData({ ...formData, customer_id: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    !price_per_litter_msg && dispatch(UpdateBuyCustomerMilk({data: {...formData, id: locationUrl.id}}, navigate, true, t));
  };

  const calculateAmount =()=> {
    let calculatedAmount = 0;
    if(formData.rate_type === 'fat_clr' || formData.rate_type === 'fat_snf'){
      calculatedAmount = Number(formData.quntity) * Number(price_per_litter.rate);
    } else {
      const { quntity, fat } = formData;
      const fateRate = profile.fate_rate;
      calculatedAmount = Number(quntity) * Number(fat) * Number(fateRate);
    }
    return calculatedAmount.toFixed(2); 
  }

  return (
    <BuyMilkDesign>
      <div className="edit-button">
        <button onClick={()=> navigate('/set-parameters/edit', { state: params })}>{t('Edit Parameters')}</button>
        <div>
          <p>{moment(params?.selectedDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} {t(params?.selectedDay)} {t(params?.selectedShift)}</p>
        </div>
        <button onClick={()=> formData.rate_type === 'fat' ? navigate('/account') : navigate(`/chart/${formData.chart_id}`)}>{formData.rate_type === 'fat' ? [t("Set Fat Rate")] : [t("Set Chart Rate")]}</button>
      </div>
      <h1>{t("Buy Milk")}</h1>
      <h5>{price_per_litter_msg}</h5>
      <h3>{t(params?.selectedRateType)} {params?.selectedRateType !== 'Fat' && ` - ${params?.selectedMilkType?.label}`}</h3>
      <div className="form-contener">
        <form onSubmit={handleSubmit}>
          <div className="contents">
            <div className="content1">
              <label>{t("Select Customer")}</label>
              <CustomSelect
                options={customerOptions}
                value={selectedCustomer}
                onChange={handleCustomerChange}
                placeholder={t("Search for a customer...")}
                isSearchable
              />
            </div>
            <div className="content2">
              <label>{t("Fat")}</label>
              <input
                type="number"
                placeholder={t("Fat")}
                autoComplete="off"
                name="fat"
                value={formData.fat}
                required
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="contents">
            <div className="content1">
              <label>{t("CLR")}</label>
              <input
                type="number"
                placeholder={t("CLR")}
                name="clr"
                value={formData.clr}
                required={formData.rate_type === 'fat_clr'}
                onChange={handleInputChange}
              />
            </div>
            <div className="content2">
              <label>{t("SNF")}</label>
              <input
                type="number"
                placeholder={t("SNF")}
                name="snf"
                required={formData.rate_type === 'fat_snf'}
                value={formData.snf}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="contents">
            <div className="content1">
              <label>{t("Quantity in liter")} {perltr ? <b>({perltr} {t("pr/ltr")})</b> : ''}</label>
              <input
                type="number"
                placeholder={t("Quantity")}
                name="quntity"
                value={formData.quntity}
                required
                onChange={handleInputChange}
              />
            </div>
            <div className="content2">
              <label>{t("Amount")}</label>
              <input
                id="milk-amount"
                type="number"
                placeholder={t("Amount")}
                name="amount"
                value={calculateAmount()}
                onChange={handleInputChange}
                required
                readOnly
              />
            </div>
            <span className="error">{errors.fate_rate}</span>
          </div>
          <div className="save-button">
            <button type="submit" className={price_per_litter_msg && 'disabled'}>{t("Save")}</button>
          </div>
        </form>
      </div>
    </BuyMilkDesign>
  );
}

export default EditBuyMilk;
