import React from 'react'
import poweredByLogo from '../../assets/images/FittoniaLogo1x.png'
import { PoweredBySectionDesign } from './Design'

function PoweredBySection() {
  return (
    <PoweredBySectionDesign>
      <a href='https://ftpls.com' target="_blank" rel="noopener noreferrer">
        <div className="powered-by-section">
          <p>Powered by</p>
          <img src={poweredByLogo} alt="Fittonia Technologies Pvt Ltd Milk Dairy" />
        </div>
      </a>
    </PoweredBySectionDesign>
  )
}

export default PoweredBySection