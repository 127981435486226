import React, { useState } from 'react';
import { ProfileSetupDesign } from '../../styles/MyDairy/Design';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UpdateMyDairy } from '../../store/actions/MyDairyActions';
import { useTranslation } from 'react-i18next';

function ProfileSetup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [fatRate, setFatRate] = useState('');
  const [error, setError] = useState('');

  const handleSetRate =(e)=> {
    e.preventDefault();
    const data = {
      fate_rate: Number(fatRate),
    };
    if(data.fate_rate <= 0) {
      setError(t("Fat rate must be greater then 0."))
    } else {
      dispatch(UpdateMyDairy({data: data}, navigate))
    }
  };

  return (
    <ProfileSetupDesign>
      <div className="container">
        <form className="box" onSubmit={handleSetRate}>
          <h2>{t("Set Fat Rate for Per Liter Milk")}</h2>
          <p>
            {t("Please set your fat rate for per liter of milk. This is the rate you want to apply to the customer's milk fat content.")}
          </p>
          <label>
            {t("Your Fat Rate")}:
            <input
              type="number"
              step="0.1"
              required
              value={fatRate}
              onChange={(e) => setFatRate(e.target.value)}
            />
            <span className="error">{error}</span>
          </label>
          <br />
          <button>{t("Set Rate")}</button>
        </form>
      </div>
    </ProfileSetupDesign>
  );
}

export default ProfileSetup;
