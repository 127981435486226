import { CREDITHISTORY, DEPOSITEHISTORY, CUSTOMERS, PRODUCT, PRODUCTS, CUSTOMER, CHART, CHART_RATES, PRICE_PER_LITTER, PRICE_PER_LITTER_MESSAGE, BUYMILK, SELLMILK, GRAPHDATA, BUYMILKOBJ, CUSTOMERINFORMATION, PLANS, GETNOTIFICATION, GRADE, DEPOSITEHISTORYADMIN, PRINTER } from "../actions/MyDairyActions";

const initialState = {
  products: [],
  product: {},
  credithistory: {},
  depositehistory: {},
  depositehistoryAdmin: [],
  customers: [],
  customer: {},
  charts: [],
  grades: [],
  customerinfo: {},
  chart_rates: [],
  price_per_litter: {},
  price_per_litter_msg: '',
  buyMilks: {},
  sellMilks: {},
  graphData: {},
  buyMilkObj: {},
  plans: [],
  getnotification: {},
  printerConnected: null,
}

const MyDairy = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS:
        return {
            ...state,
            products: action.payload,
        };
    case PRODUCT:
      return {
          ...state,
          product: action.payload,
      };
    case CREDITHISTORY:
      return {
        ...state,
        credithistory: action.payload,
      };

    case DEPOSITEHISTORY:
      return {
        ...state,
        depositehistory: action.payload,
      };
    case DEPOSITEHISTORYADMIN:
      return {
        ...state,
        depositehistoryAdmin: action.payload,
      };
    case CUSTOMERS:
      return {
          ...state,
          customers: action.payload,
      };
    case CUSTOMER:
      return {
          ...state,
          customer: action.payload,
      };
    case CHART:
      return {
          ...state,
          charts: action.payload,
      };
    case GRADE:
      return {
          ...state,
          grades: action.payload,
      };
    case CUSTOMERINFORMATION:
      return {
          ...state,
          customerinfo: action.payload,
      };
    case CHART_RATES:
        return {
            ...state,
            chart_rates: action.payload,
        };
    case PRICE_PER_LITTER:
      return {
          ...state,
          price_per_litter: action.payload,
      };
    case PRICE_PER_LITTER_MESSAGE:
      return {
          ...state,
          price_per_litter_msg: action.payload,
      };
    case BUYMILK:
      return {
          ...state,
          buyMilks: action.payload,
      };
    case SELLMILK:
      return {
          ...state,
          sellMilks: action.payload,
      };
    case GRAPHDATA:
      return {
          ...state,
          graphData: action.payload,
      };
    case BUYMILKOBJ:
      return {
          ...state,
          buyMilkObj: action.payload,
      };
    case PLANS:
      return {
          ...state,
          plans: action.payload,
      };

    case GETNOTIFICATION:
      return {
          ...state,
          getnotification: action.payload,
      };
    case PRINTER:
      return {
          ...state,
          printerConnected: action.payload,
      };

    default: {
        return state;
    }
    }
};

export default MyDairy;