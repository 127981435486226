import React from "react";
import styled from "styled-components";

const DeleteIconWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
`;

const DeleteIconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

const DeleteIcon = ({ onClick }) => {
  return (
    <DeleteIconWrapper onClick={onClick}>
      <DeleteIconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M3 6h18M5 6l1 14A2 2 0 0 0 8 22h8a2 2 0 0 0 2-2L19 6h-2M9 6V4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2M9 11v9M15 11v9" />
        </svg>
      </DeleteIconContainer>
    </DeleteIconWrapper>
  );
};

export default DeleteIcon;