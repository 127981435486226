import React, { useEffect, useState } from "react";
import { BuyMilkDesign } from "../../styles/MyDairy/Design";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../Elements/CustomSelect";
import { useLocation, useNavigate } from "react-router";
import { BuyCustomerMilk, CustomerListingAction, GetPerLitterPriceAction, GradeListingAction, PRICE_PER_LITTER_MESSAGE } from "../../store/actions/MyDairyActions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import DatePicker from 'react-datepicker';
import { formatDate } from "../AdminAction/Transactions";
import { usePrintReceipt } from "./PrintReceipt";

const FatEnum = {
  "Fat": 'fat',
  "Fat/CLR": "fat_clr",
  "Fat/SNF": "fat_snf",
}

const FatEnumRever = {
  'fat':  "Fat",
  "fat_clr": "Fat/CLR",
  "fat_snf": "Fat/SNF",
}

function BuyMilk() {
  const dispatch = useDispatch();
  const { printReceipt } = usePrintReceipt();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const price_per_litter = useSelector((state) => state.MyDairy.price_per_litter || {});
  const price_per_litter_msg = useSelector((state) => state.MyDairy.price_per_litter_msg);
  const { t } = useTranslation();
  const errors = useSelector((state) => state.Authentication.errors || {});
  const customers = useSelector((state) => state.MyDairy.customers || []);
  const grades = useSelector((state) => state.MyDairy.grades || {});
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);
  const [duplicateEntries, setDuplicateEntries] = useState([]);
  const [formData, setFormData] = useState({
    customer_id: '',
    fat: "",
    clr: "",
    snf: "",
    quntity: "",
    amount: "",
    date: new Date(),
    shift: new Date().getHours()>= 12 ? 'evening' : 'morning',
    rate_type: "",
    chart_id: "",
    grade_id: "", 
    little_rate: "",
  });
  const [perltr, Setperltr] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [snfClr, setSnfClr] = useState('')
  const printer = useSelector((state) => JSON.parse(localStorage.getItem("printerEnabled")) && state.MyDairy.printerConnected);

	useEffect(()=> {
		dispatch(CustomerListingAction());
    dispatch(GradeListingAction());
	}, [])

  useEffect(()=> {
    if(formData.rate_type === 'fat_clr') {
      setSnfClr(formData.clr);
    } else if(formData.rate_type === 'fat_snf'){
      setSnfClr(formData.snf);
    }
	}, [formData.clr, formData.snf, selectedCustomer])

  useEffect(()=> {
    if(formData.rate_type === 'fat_clr' && Number(formData.clr) > 0 && Number(formData.fat) > 0) {
      dispatch(GetPerLitterPriceAction({fat: formData.fat, clr: formData.clr, chart_id: formData.chart_id}))
    } else if(formData.rate_type === 'fat_snf' && Number(formData.snf) > 0 && Number(formData.fat) > 0){
      dispatch(GetPerLitterPriceAction({fat: formData.fat, snf: formData.snf, chart_id: formData.chart_id}))
    }
	}, [snfClr, formData.fat, selectedCustomer])

  useEffect(()=> {
    if(formData.rate_type === 'fat') {
      const rate = grades.find((grade) => grade.id === parseInt(formData.grade_id));
      const little_rate = (Number(formData.fat)*Number(rate?.rate)).toFixed(2);
      setFormData({ ...formData, amount: calculateAmount(), little_rate: little_rate});
      Setperltr(little_rate);
    } else if(formData.rate_type === 'fat_clr' || formData.rate_type === 'fat_snf') {
      setFormData({ ...formData, amount: calculateAmount(), little_rate: price_per_litter.rate});
      Setperltr(price_per_litter?.rate);
    }
	}, [formData.fat, formData.quntity, price_per_litter])

  const customerOptions = customers.map((customer) => ({
    value: customer.id,
    label: `${customer.name} - #${customer.sid}`,
  }));  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCustomerChange = (selectedOption) => {
    dispatch({
      type: PRICE_PER_LITTER_MESSAGE,
      payload: '',
    });
    setSelectedCustomer(selectedOption);
    const customer = customers.find((c) => c.id === selectedOption.value);
    setFormData({ ...formData,
      customer_id: selectedOption.value,
      rate_type: customer.rate_type,
      chart_id: customer.chart_id,
      grade_id: customer.grade_id,
      customer: customer,
    });
  };

  const handleProceed = (e) => {
    setShowDuplicatePopup(false);
    handleSubmit(e, true);
  };
  
  const handleClose = () => {
    setShowDuplicatePopup(false);
  };

  const printReceipts = (item) => {
    const { fat, clr, snf, quntity, date, amount, shift, customer } = item;

    printReceipt(
      customer?.sid,
      moment(selectedDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      shift.toUpperCase(),
      fat || '',
      quntity,
      clr || '-',
      snf || '-',
      amount
    );
  };

  const handleSubmit = async (e, duplicate=null) => {
    e.preventDefault();
    !price_per_litter_msg && dispatch(BuyCustomerMilk({data: {...formData, confirmed: duplicate, date: moment(selectedDate).format('YYYY-MM-DD'),}}, setFormData, setSelectedCustomer, true, t)).then((response)=> {
      if(response.status === 201) {
        printer && printReceipts(formData)
        setFormData({
        ...formData,
        customer_id: '',
        fat: "",
        clr: "",
        snf: "",
        temprature: "",
        quntity: "",
        amount: "",})
        setSelectedCustomer(null);
      }  else if(response.status === 200) {
        if (response.data && response.data.duplicates && response.data.duplicates.length > 0) {
          const duplicateEntries = response.data.duplicates;
          setShowDuplicatePopup(true);
          setDuplicateEntries(duplicateEntries);
        }
      }
    })
  };

  const calculateAmount =()=> {
    let calculatedAmount = 0;
    if(formData.rate_type === 'fat_clr' || formData.rate_type === 'fat_snf'){
      calculatedAmount = Number(formData.quntity) * Number(price_per_litter.rate);
    } else {
      const rate = grades.find((grade) => grade.id === parseInt(formData.grade_id));
      const { quntity, fat } = formData;
      calculatedAmount = Number(quntity) * Number(fat) * Number(rate?.rate);
    }
    return calculatedAmount.toFixed(2); 
  }

  return (
    <BuyMilkDesign>
      <div className="h1-container">
        <h1>{t("Buy Milk")}</h1>
      </div>
      <div className="top-leval elements">
        <div>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="dd-MM-yyyy"
            className="datepicker"
          />
        </div>
        <div>
          <h3>{t(FatEnumRever[formData.rate_type])}</h3>
        </div>
        <select
          name="shift"
          value={formData.shift}
          onChange={handleInputChange}
          className="select"
          style={{ width: 'auto' }}
        >
          <option value="morning">{t('Morning')}</option>
          <option value="evening">{t('Evening')}</option>
        </select>
      </div>
      <h5>{price_per_litter_msg}</h5>
      <div className="form-contener">
        <form onSubmit={handleSubmit}>
          <div className="contents">
            <div className="content1">
              <label>{t("Select Customer")}</label>
              <CustomSelect
                options={customerOptions}
                value={selectedCustomer}
                onChange={handleCustomerChange}
                placeholder={t("Search for a customer...")}
                isSearchable
              />
            </div>
            <div className="content2">
              <label>{t("Quantity in liter")} {perltr ? <b>({perltr} {t("pr/ltr")})</b> : ''}</label>
              <input
                type="number"
                placeholder={t("Quantity")}
                name="quntity"
                value={formData.quntity}
                required
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="contents">
            <div className="content1">
              <label>{t("Fat")}</label>
              <input
                type="number"
                placeholder={t("Fat")}
                autoComplete="off"
                name="fat"
                value={formData.fat}
                required
                onChange={handleInputChange}
              />
            </div>
            <div className="content2">
              <label>{t("CLR")}</label>
              <input
                type="number"
                placeholder={t("CLR")}
                name="clr"
                value={formData.clr}
                required={formData.rate_type === 'fat_clr'}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="contents">
            <div className="content1">
              <label>{t("SNF")}</label>
              <input
                type="number"
                placeholder={t("SNF")}
                name="snf"
                required={formData.rate_type === 'fat_snf'}
                value={formData.snf}
                onChange={handleInputChange}
              />
            </div>
            <div className="content2">
              <label>{t("Amount") }</label>
              <input
                id="milk-amount"
                type="number"
                placeholder={t("Amount")}
                name="amount"
                value={calculateAmount()}
                onChange={handleInputChange}
                required
                readOnly
              />
            </div>
            <span className="error">{errors.fate_rate}</span>
          </div>
          <div className="save-button">
            <button type="submit" className={price_per_litter_msg && 'disabled'}>{printer ? t("Print") : t("Save")}</button>
          </div>
        </form>
      </div>

      {showDuplicatePopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>{t('Duplicate Entries Found')}</h2>
            <p>{t('duplicate Message', { date: formatDate(duplicateEntries[0]?.date), shift: t(duplicateEntries[0]?.shift) })}</p>
            <div className="duplicate-list">
              <table>
                <thead>
                  <tr>
                    <th>{t('Fat')}</th>
                    <th>{t('Quantity')}</th>
                    <th>{t('Amount')}</th>
                  </tr>
                </thead>
                <tbody>
                  {duplicateEntries.map((entry, index) => (
                    <tr key={index}>
                      <td>{entry.fat}</td>
                      <td>{entry.quntity}</td>
                      <td>{entry.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p>{t('Do you want to proceed with the duplicate entry?')}</p>
            <div className="button-container">
              <button onClick={handleProceed}>{t('Yes')}</button>
              <button onClick={handleClose}>{t('Close')}</button>
            </div>
          </div>
        </div>
      )}
    </BuyMilkDesign>
  );
}

export default BuyMilk;
