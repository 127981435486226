import React, { useEffect, useState } from 'react';
import { CustomerPermissionsDesign } from '../../styles/MyDairy/Design';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateMyDairy } from '../../store/actions/MyDairyActions';

const CustomerPermissions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.Authentication.profile || {});
  const convertStringToBoolean = (str) => str === "true";
  const permissionsConver = Object.keys(profile.customer_permissions).reduce((acc, key) => {
      const obj = {};
      Object.keys(profile.customer_permissions[key]).forEach(innerKey => {
          obj[innerKey] = convertStringToBoolean(profile.customer_permissions[key][innerKey]);
      });
      acc[key] = obj;
      return acc;
  }, {});
  const [permissions, setPermissions] = useState(permissionsConver);
  const [perform, setPerform] = useState(false);

  useEffect(()=> {
    if(profile.customer_permissions && perform) {
      const data = {
        customer_permissions: permissions,
      };
      dispatch(UpdateMyDairy({data: data}))
    }
    setPerform(true);
  }, [permissions])

  const handleToggle = (category, permission) => {
    if (permission === 'parent') {
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        [category]: {
          ...prevPermissions[category],
          parent: !prevPermissions[category].parent
        }
      }));
    } else {
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        [category]: {
          ...prevPermissions[category],
          [permission]: !prevPermissions[category][permission]
        }
      }));
    }
  };

  return (
    <CustomerPermissionsDesign>
      <div className="customer-permissions">
        <h1>{t('Customer Permissions')}</h1>
        <div className="permission-category">
          <h3>
            <span className="toggle">
              <label>{t('Show Account Details')}</label>
              <input
                type="checkbox"
                checked={permissions.accountDetails.parent}
                onChange={() => handleToggle('accountDetails', 'parent')}
              />
              <span className="slider" onClick={() => handleToggle('accountDetails', 'parent')}></span>
            </span>
          </h3>
          {permissions.accountDetails.parent && (
            <>
              <div className="toggle">
                <label>{t('Show Credited Amount')}</label>
                <input
                  type="checkbox"
                  checked={permissions.accountDetails.creditedAmount}
                  onChange={() => handleToggle('accountDetails', 'creditedAmount')}
                />
                <span className="slider" onClick={() => handleToggle('accountDetails', 'creditedAmount')}></span>
              </div>
              <div className="toggle">
                <label>{t('Show Debited Amount')}</label>
                <input
                  type="checkbox"
                  checked={permissions.accountDetails.debitedAmount}
                  onChange={() => handleToggle('accountDetails', 'debitedAmount')}
                />
                <span className="slider" onClick={() => handleToggle('accountDetails', 'debitedAmount')}></span>
              </div>
            </>
          )}
        </div>

        <div className="permission-category">
          <h3>
            <span className="toggle">
              <label>{t('Show Credit History')}</label>
              <input
                type="checkbox"
                checked={permissions.creditHistory.parent}
                onChange={() => handleToggle('creditHistory', 'parent')}
              />
              <span className="slider" onClick={() => handleToggle('creditHistory', 'parent')}></span>
            </span>
          </h3>
          {/* Render child elements only if the parent toggle is enabled */}
          {permissions.creditHistory.parent && (
            <>
              <div className="toggle">
                <label>{t('Show Amount')}</label>
                <input
                  type="checkbox"
                  checked={permissions.creditHistory.amount}
                  onChange={() => handleToggle('creditHistory', 'amount')}
                />
                <span className="slider" onClick={() => handleToggle('creditHistory', 'amount')}></span>
              </div>
              <div className="toggle">
                <label>{t('Show CLR')}</label>
                <input
                  type="checkbox"
                  checked={permissions.creditHistory.clr}
                  onChange={() => handleToggle('creditHistory', 'clr')}
                />
                <span className="slider" onClick={() => handleToggle('creditHistory', 'clr')}></span>
              </div>
              <div className="toggle">
                <label>{t('Show SNF')}</label>
                <input
                  type="checkbox"
                  checked={permissions.creditHistory.snf}
                  onChange={() => handleToggle('creditHistory', 'snf')}
                />
                <span className="slider" onClick={() => handleToggle('creditHistory', 'snf')}></span>
              </div>
            </>
          )}
        </div>

        <div className="permission-category">
          <h3>
            <span className="toggle">
              <label>{t('Show Deposit History')}</label>
              <input
                type="checkbox"
                checked={permissions.depositHistory.parent}
                onChange={() => handleToggle('depositHistory', 'parent')}
              />
              <span className="slider" onClick={() => handleToggle('depositHistory', 'parent')}></span>
            </span>
          </h3>
        </div>

        <div className="permission-category">
          <h3>
            <span className="toggle">
              <label>{t('Show Notifications')}</label>
              <input
                type="checkbox"
                checked={permissions.notifications.parent}
                onChange={() => handleToggle('notifications', 'parent')}
              />
              <span className="slider" onClick={() => handleToggle('notifications', 'parent')}></span>
            </span>
          </h3>
          {permissions.notifications.parent && (
            <>
              <div className="toggle">
                <label>{t('Show Amount')}</label>
                <input
                  type="checkbox"
                  checked={permissions.notifications.amount}
                  onChange={() => handleToggle('notifications', 'amount')}
                />
                <span className="slider" onClick={() => handleToggle('notifications', 'amount')}></span>
              </div>
              <div className="toggle">
                <label>{t('Show CLR')}</label>
                <input
                  type="checkbox"
                  checked={permissions.notifications.clr}
                  onChange={() => handleToggle('notifications', 'clr')}
                />
                <span className="slider" onClick={() => handleToggle('notifications', 'clr')}></span>
              </div>
              <div className="toggle">
                <label>{t('Show SNF')}</label>
                <input
                  type="checkbox"
                  checked={permissions.notifications.snf}
                  onChange={() => handleToggle('notifications', 'snf')}
                />
                <span className="slider" onClick={() => handleToggle('notifications', 'snf')}></span>
              </div>
            </>
          )}
        </div>
      </div>
    </CustomerPermissionsDesign>
  );
};

// Export the component for use in other files
export default CustomerPermissions;
