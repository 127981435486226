import React, { useEffect, useRef, useState } from 'react';
import { MyProfileDesign } from '../../styles/MyDairy/Design';
import accountIcon from '../../assets/images/icons-account-50.png'
import profileAvatar from '../../assets/images/login.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { UpdateMyDairy } from '../../store/actions/MyDairyActions';
import { useTranslation } from 'react-i18next';
import GradeTracker from './GradeTracker';
import { useNavigate } from 'react-router-dom';

const MyProfile = () => {
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [url, setUrl] = useState(profileAvatar);
  const [error, setError] = useState('');
  const errors = useSelector((state) => state.Authentication.errors || {});
  const profile = useSelector((state) => state.Authentication.profile || {});
  const [profileData, setProfileData] = useState({
    dairy_name: profile.dairy_name,
    owner_name: profile.owner_name,
    email: profile.email,
    phone_number: profile.phone_number,
    fate_rate: profile.fate_rate,
    image: null,
    address_attributes: {
      city: profile.address?.city,
      district: profile.address?.district,
      state: profile.address?.state,
      pin: profile.address?.pin,
    },
  });

  const onImageClick = () => {
    inputFile.current.click();
  };

  const handleProfilePictureChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const src = URL.createObjectURL(file);
    setUrl(src);
    setProfileData({...profileData, image: file});
  };

  const handleSetRate =(e)=> {
    e.preventDefault();
    setError('');
    const formData = new FormData();
    formData.append('data[dairy_name]', profileData.dairy_name);
    formData.append('data[owner_name]', profileData.owner_name);
    formData.append('data[email]', profileData.email);
    formData.append('data[phone_number]', profileData.phone_number);
    formData.append('data[fate_rate]', profileData.fate_rate);
    if(profileData.image){
      formData.append('data[image]', profileData.image);
    }
    formData.append('data[address_attributes][city]', profileData.address_attributes.city);
    formData.append('data[address_attributes][district]', profileData.address_attributes.district);
    formData.append('data[address_attributes][state]', profileData.address_attributes.state);
    formData.append('data[address_attributes][pin]', profileData.address_attributes.pin);
    dispatch(UpdateMyDairy(formData, null, true, t));
  };

  useEffect(()=> {
    if(profile.image?.url) {
      setUrl(profile.image?.url);
    }
    setProfileData({
      dairy_name: profile.dairy_name,
      owner_name: profile.owner_name,
      email: profile.email,
      phone_number: profile.phone_number,
      fate_rate: profile.fate_rate,
      image: null,
      address_attributes: {
        city: profile.address?.city,
        district: profile.address?.district,
        state: profile.address?.state,
        pin: profile.address?.pin,
      },
    });
  }, [profile])

  return (
    <MyProfileDesign>
      <form className="profile-container" onSubmit={(e) => handleSetRate(e)}>
        <div className='profile-heding'>
          <img src={accountIcon} alt='Milk Dairy'/>
          <h1>{t('My Profile')}</h1>
        </div>

        <div className='profile-section'>
          <div className='profile-sub-section profile-img-in'>
            <div className="profile_image">
              <img src={url} alt="Milk Dairy" onClick={onImageClick} title="Obtain a 10% completion percentage of all." />
              <input
                type="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(e) => handleProfilePictureChange(e)}
              />
            </div>
          </div>

          <div className='profile-sub-section'>
            <div className='full-input'>
              <label>
                <b>{t('Dairy Name')}</b>
              </label>
              <input 
                type="text"
                placeholder={t("Dairy Name")}
                name="dairy_name"
                value={profileData.dairy_name}
                onChange={(e) => setProfileData({...profileData,  dairy_name: e.target.value})}
                required
              />
              <span className="error">{errors.dairy_name}</span>
            </div>

            <div className='full-input'>
              <label>
                <b>{t('My Name')}</b>
              </label>
              <input 
                type="text"
                placeholder={t("My Name")}
                name="owner_name"
                value={profileData.owner_name}
                onChange={(e) => setProfileData({...profileData,  owner_name: e.target.value})}
                required
              />
              <span className="error">{errors.owner_name}</span>
            </div>
          </div>

          <div className='profile-sub-section'>
            <div className='full-input'>
              <label>
                <b>{t("Email")}</b>
              </label>
              <input 
                type="email"
                placeholder={t("Email")}
                name="email"
                value={profileData.email}
                onChange={(e) => setProfileData({...profileData,  email: e.target.value})}
              />
              <span className="error">{errors.email}</span>
            </div>

            <div className='full-input'>
              <label>
                <b>{t('Phone Number')}</b>
              </label>
              <input 
                type="number"
                placeholder={t("Phone Number")}
                name="phone_number"
                value={profileData.phone_number}
                onChange={(e) => setProfileData({...profileData,  phone_number: e.target.value})}
              />
              <span className="error">{errors.phone_number}</span>
            </div>
          </div>
        </div>

        <div className='profile-heding'>
          <h2>{t("Address")}</h2>
        </div>

        <div className='profile-section'>
          <div className='profile-sub-section'>
            <div className='full-input'>
              <label>
                <b>{t('Village / City Name')}</b>
              </label>
              <input 
                type="text"
                placeholder={t("Village / City Name")}
                name="city"
                required
                value={profileData.address_attributes.city}
                onChange={(e) => setProfileData({...profileData,  address_attributes: { ...profileData.address_attributes, city: e.target.value}})}
              />
            </div>

            <div className='full-input'>
              <label>
                <b>{t('District')}</b>
              </label>
              <input 
                type="text"
                placeholder={t("District")}
                name="district"
                required
                value={profileData.address_attributes.district}
                onChange={(e) => setProfileData({...profileData,  address_attributes: { ...profileData.address_attributes, district: e.target.value}})}
              />
            </div>
          </div>

          <div className='profile-sub-section'>
            <div className='full-input'>
              <label>
                <b>{t('State')}</b>
              </label>
              <input 
                type="text"
                placeholder={t("State")}
                name="state"
                required
                value={profileData.address_attributes.state}
                onChange={(e) => setProfileData({...profileData,  address_attributes: { ...profileData.address_attributes, state: e.target.value}})}
              />
            </div>

            <div className='full-input'>
              <label>
                <b>{t('Pin')}</b>
              </label>
              <input 
                type="text"
                required
                placeholder={t("Pin")}
                name="pin"
                value={profileData.address_attributes.pin}
                onChange={(e) => setProfileData({...profileData,  address_attributes: { ...profileData.address_attributes, pin: e.target.value}})}
              />
            </div>
          </div>
          <button>{t('Save Changes')}</button>
        </div>
      </form>
    </MyProfileDesign>
  );
};

export default MyProfile;
