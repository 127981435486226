import { useEffect, useState } from "react";
import { CustomerDesign } from "../../styles/MyDairy/Design";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CustomerListingAction } from "../../store/actions/MyDairyActions";
import { useTranslation } from "react-i18next";

function Customers() {
	const dispatch = useDispatch();
  const navigate = useNavigate();
	const [search, setSearch] = useState('');
	const { t } = useTranslation();
	const customers = useSelector((state) => state.MyDairy.customers || []);

	useEffect(()=> {
		dispatch(CustomerListingAction({search: search}));
	}, [search])

  return (
		<>
		<CustomerDesign>
			<h1>{t("Customers")}</h1>
			<input 
					type="text"
					placeholder={t("Search by ID or Name")}
					name="name"
					value={search}
					required
					onChange={(e) => setSearch(e.target.value)}
				/>
				<div className="customer-Listing">
				{customers.length ? 
				customers.map((customer, index) => (
					<div className="contents" key={index}>
						<div className="content-box" onClick={()=> navigate(`/customer/${customer.id}`)}>
							<div className="member">
								<div className="name">
									{customer.name}
								</div>
								<div className="code">
									#{customer.sid}
								</div>
							</div>
							<div className="member2">
								<div className="number">
									+91{customer.phone_number}
								</div>
								<div className="village">
									{customer.address}
								</div>
							</div>
						</div>
					</div>
				)) :
				
				<p>{t('There is no Customers')}</p>
				}
			</div>
			<button className="circular-button" onClick={()=> navigate('/customer/new')}>+</button>
		</CustomerDesign>
		</>
  );
}
export default Customers;

