import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import androidScreenshot1 from './images/1.png';
import androidScreenshot2 from './images/2.png';
import androidScreenshot3 from './images/3.png';
import androidScreenshot4 from './images/4.png';
import androidScreenshot5 from './images/5.png';
import androidScreenshot6 from './images/6.png';
import androidScreenshot7 from './images/7.png';
import androidScreenshot8 from './images/8.png';
import androidScreenshot9 from './images/9.png';
import qrCodeIcon from './images/qr-code.png';
import appIcon from './images/appIcon.png';

const AndroidAppSection = () => {
    const navigate = useNavigate();
    const playStoreLink = 'https://play.google.com/store/apps/details?id=com.milkdairymerchant';

    const screenshots = [androidScreenshot1, androidScreenshot2, androidScreenshot3, androidScreenshot4, androidScreenshot5, androidScreenshot6, androidScreenshot7, androidScreenshot8, androidScreenshot9];
    const [currentScreenshotIndex, setCurrentScreenshotIndex] = useState(0);

    const handlePrevious = () => {
        setCurrentScreenshotIndex(prevIndex => (prevIndex === 0 ? screenshots.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentScreenshotIndex(prevIndex => (prevIndex === screenshots.length - 1 ? 0 : prevIndex + 1));
    };

    const handleInstall = () => {
        window.open(playStoreLink, '_blank');
    };

    return (
        <AndroidAppSectionDesign>
            <section className="android-app-section">
                <div className="container">
                    <div className="content">
                        <div className="screenshot-container">
                            <div className="screenshot-slider">
                                <button className="nav-button prev" onClick={handlePrevious}>&#10094;</button>
                                <img src={screenshots[currentScreenshotIndex]} alt={`Screenshot ${currentScreenshotIndex + 1}`} className="screenshot" />
                                <button className="nav-button next" onClick={handleNext}>&#10095;</button>
                            </div>
                            <div className="thumbnails">
                                {screenshots.map((screenshot, index) => (
                                    <img key={index} src={screenshot} alt={`Screenshot ${index + 1}`} className={index === currentScreenshotIndex ? 'active' : ''} onClick={() => setCurrentScreenshotIndex(index)} />
                                ))}
                            </div>
                        </div>
                        <div className='install-container'>
                          <div className="install">
                            <img src={appIcon} alt="App Icon" className="app-icon" />
                            <div className="app-info">
                              <h3>MilkDairy Merchant</h3>
                              <button className="install-button" onClick={handleInstall}>Install</button>
                            </div>
                          </div>
                          <div className="qr-code-scanner">
                            <p>(OR) Scan QR Code to download</p>
                            <img src={qrCodeIcon} alt="QR Code Scanner" className="qr-code" />
                          </div>
                        </div>
                    </div>
                </div>
            </section>
        </AndroidAppSectionDesign>
    );
};

export default AndroidAppSection;

const AndroidAppSectionDesign = styled.div`
    .android-app-section {
        padding: 10px 0;
        text-align: center;
    }

    h2 {
        color: #28728f;
        font-size: 2.5rem;
        margin-bottom: 40px;
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .screenshot-container {
        flex: 1;
        max-width: 45%;
        margin-right: 20px;
    }

    .screenshot-slider {
        position: relative;
        margin-bottom: 20px;
    }

    .nav-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
        border: none;
        cursor: pointer;
        font-size: 1.5rem;
        padding: 10px;
        z-index: 1;
        transition: background-color 0.3s ease;
    }

    .nav-button:hover {
        background-color: rgba(0, 0, 0, 0.6);
    }

    .prev {
        left: 10px;
    }

    .next {
        right: 10px;
    }

    .screenshot {
        width: 300px;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    .thumbnails {
        display: flex;
        margin-top: 10px;
        overflow-x: auto;
    }
    
    .thumbnails::-webkit-scrollbar {
      width: 2px;
    }

    .thumbnails img {
        width: 60px;
        height: auto;
        margin: 0 5px;
        border-radius: 5px;
        cursor: pointer;
        transition: opacity 0.3s ease;
    }

    .thumbnails img.active {
        opacity: 0.6;
    }

    .install-container {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }

    .install {
        flex: 1;
        text-align: left;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 20px;
    }

    .app-icon {
        width: 80px;
        height: 80px;
        border-radius: 15px;
        margin-right: 20px;
    }

    .app-info {
        flex: 1;
        text-align: left;
    }

    .app-info h3 {
        color: #28728f;
        font-size: 1.8rem;
        margin: 2px;
    }

    .install-button {
        background-color: #4fa8de;
        color: #ffffff;
        padding: 15px 30px;
        font-size: 1rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    .install-button:hover {
        background-color: #28728f;
    }

    .qr-code-scanner {
        flex: 1;
        max-width: 100%;
        align-items: center;
        justify-content: center;
    }

    .qr-code {
        width: 360px;
        margin-right: 10px;
    }

    .qr-code-scanner p {
        color: #231f20;
        font-size: 1rem;
        margin: 0;
    }

    @media (max-width: 768px) {
      .content {
        align-items: center;
        flex-direction: column-reverse;
        align-content: center;
      }
      .qr-code-scanner {
        display: none;
      }    
      .screenshot-container {
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
      }

      .thumbnails {
          overflow-x: auto;
          flex-wrap: nowrap;
          justify-content: flex-start;
          margin-bottom: 10px;
      }
  }
`;
