import React, { useState, useEffect, useRef } from 'react';
import { ViewChartDesign } from './Design';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { useTranslation } from "react-i18next";
import { BuildChartRateAction, ChartRateListingAction, ClearChartAction, CreateChartRate, DestroyChartRate, UpdateChartRate } from '../../store/actions/MyDairyActions';
import Swal from 'sweetalert2';

const ViewChart = () => {
  const dispatch = useDispatch();
  const locationID = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const params = location.state;
  const tableRef = useRef(null);
  const chart_rates = useSelector((state) => state.MyDairy.chart_rates || {});
  const [data, setData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [fromFat, setFromFat] = useState('');
  const [rateType, setRateType] = useState('');
  const [clr, setClr] = useState('');
  const [snf, setSnf] = useState('');
  const [toFat, setToFat] = useState('');
  const [perFatRate, setPerFatRate] = useState('');

  const handleProceed = () => {
    const data = {
      from_fat: fromFat,
      to_fat: toFat,
      rate_type: rateType,
      clr: clr,
      snf: snf,
      per_fat_rate: perFatRate,
      chart_id: locationID.id,
    }
    dispatch(BuildChartRateAction({data: data})).then(() => setShowPopup(false));
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleOpenPopup = (type) => {
    setRateType(type);
    setShowPopup(true);
  };

  useEffect(() => {
    dispatch(ChartRateListingAction({chart_id: locationID.id}))
  }, [dispatch, locationID.id]);

  useEffect(() => {
    setData(chart_rates);
  }, [chart_rates]);

  const handleCellEdit = (rowIndex, columnName, value) => {
    const updatedData = [...data];
    if(updatedData[rowIndex][columnName] !== parseFloat(value)) {
      updatedData[rowIndex][columnName] = parseFloat(value);
      const editedRow = updatedData[rowIndex]
      setData(updatedData);

      if(editedRow.fat > 0 || editedRow.clr > 0 || editedRow.snf > 0 || editedRow.rate > 0){
        if(editedRow.id) {
          dispatch(UpdateChartRate({ data: editedRow }))
        } else {
          dispatch(CreateChartRate({ data: editedRow }, data, rowIndex))
        }
      }
    }
  };

  const handleAddRow = () => {
    const newRow = { fat: 0, clr: 0, snf: 0, rate: 0, chart_id: locationID.id };
    setData([...data, newRow]);

    if (tableRef.current) {
      setTimeout(() => {
        tableRef.current.lastChild.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }, 50);
    }
  };

  const ClearChart=(event)=>{
    event.stopPropagation();
    
    Swal.fire({
      title: t("Are you sure you want to clear this Chart data?"),
      text: t("This action is irreversible and the chart data will be permanently deleted!"),
      icon: "warning",
      color: "white",
      showCancelButton: true,
      confirmButtonColor: "#28728f",
      cancelButtonColor: "#28728f",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t('Cancel'),
      background: "#a4c4d1",
      customClass: {
        confirmButton: "swal-button swal-button--danger",
        cancelButton: "swal-button",
        popup: "swal-popup--grey",
        content: "swal-content--grey",
        actions: "swal-actions--center",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(ClearChartAction({data: locationID.id})).then(()=> {
          setShowPopup(false);
        })
      } else {
        return;
      }
    });
  };

  const DeleteRow=(event, row)=>{
    event.stopPropagation();
    
    Swal.fire({
      title: t("Are you sure you want to delete this Chart row?"),
      text: t("This action is irreversible and the chart row will be permanently deleted!"),
      icon: "warning",
      color: "white",
      showCancelButton: true,
      confirmButtonColor: "#28728f",
      cancelButtonColor: "#28728f",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t('Cancel'),
      background: "#a4c4d1",
      customClass: {
        confirmButton: "swal-button swal-button--danger",
        cancelButton: "swal-button",
        popup: "swal-popup--grey",
        content: "swal-content--grey",
        actions: "swal-actions--center",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DestroyChartRate(row.id)).then(()=> {
          const updatedData = data.filter(record => record.id !== row.id);
          setData(updatedData);
        })
      } else {
        return;
      }
    });
  };

  return (
    <ViewChartDesign>
      <h1 style={{color: '#28728f', textAlign: 'center', paddingTop: '20px'}}>{params.label} {t("Charts")}</h1>
      <div className='table-container'>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th>SN.</th>
              <th>{t("Fat")}</th>
              <th>{t("CLR")}</th>
              <th>{t("SNF")}</th>
              <th>{t("Rate")}</th>
              <th>{t("Action")}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex + 1}>
                <td>{rowIndex + 1}</td>
                <td
                  contentEditable
                  suppressContentEditableWarning
                  onBlur={(e) => handleCellEdit(rowIndex, 'fat', e.target.innerText)}
                >
                  {row.fat}
                </td>
                <td
                  contentEditable
                  suppressContentEditableWarning
                  onBlur={(e) => handleCellEdit(rowIndex, 'clr', e.target.innerText)}
                >
                  {row.clr}
                </td>
                <td
                  contentEditable
                  suppressContentEditableWarning
                  onBlur={(e) => handleCellEdit(rowIndex, 'snf', e.target.innerText)}
                >
                  {row.snf}
                </td>
                <td
                  contentEditable
                  suppressContentEditableWarning
                  onBlur={(e) => handleCellEdit(rowIndex, 'rate', e.target.innerText)}
                >
                  {row.rate}
                </td>

                <td className='actions'><p onClick={(e) => DeleteRow(e, row)}>Delete</p></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="tab-container">
        <button onClick={() => handleOpenPopup('clr')}>{t('Fill CLR Chart')}</button>
        <button onClick={handleAddRow}>{t("Add Row")}</button>
        <button onClick={() => handleOpenPopup('snf')}>{t('Fill SNF Chart')}</button>
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>{t('Fill Chart', {cow: params.label, type: t(rateType.toUpperCase())})}</h2>
            <div className='full-input'>
              <div className="input-container">
                <label htmlFor="fromFat">{t('From Fat')}:</label>
                <input type="number" id="fromFat" value={fromFat} onChange={(e) => setFromFat(e.target.value)} />
              </div>
              <div className="input-container">
                <label htmlFor="toFat">{t('To Fat')}:</label>
                <input type="number" id="toFat" value={toFat} onChange={(e) => setToFat(e.target.value)} />
              </div>
            </div>
            <div className='full-input'>
              { rateType === 'clr' ?
                <div className="input-container">
                  <label htmlFor="fromFat">{t('CLR')}:</label>
                  <input type="number" id="fromFat" value={clr} onChange={(e) => setClr(e.target.value)} />
                </div> :
                <div className="input-container">
                  <label htmlFor="fromFat">{t('SNF')}:</label>
                  <input type="number" id="fromFat" value={snf} onChange={(e) => setSnf(e.target.value)} />
                </div>
              }
              <div className="input-container">
                <label htmlFor="perFatRate">{t('Per Fat Rate')}:</label>
                <input type="number" id="perFatRate" value={perFatRate} onChange={(e) => setPerFatRate(e.target.value)} />
              </div>
            </div>
            <div className="full-input" style={{fontWeight: 'bold'}}>
              <label>{t('0.1 Per Fat Rate')}:</label>
              <span>{(perFatRate / 10).toFixed(2)}</span>
            </div>
            <div className="button-container">
              <button onClick={handleProceed}>{t('Update Rate List')}</button>
              <button onClick={handleClose}>{t('Close')}</button>
              <button style={{backgroundColor: 'red'}} onClick={ClearChart}>{t('Clear All Entries')}</button>
            </div>
          </div>
        </div>
      )}
    </ViewChartDesign>
  );
};

export default ViewChart;
