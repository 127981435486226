import React from 'react';
import { BuyOptionsDesign } from '../../styles/MyDairy/Design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

function Transactions() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBuyMilk = () => {
    navigate('/transactions-milk');
  };

  const handleCash = () => {
    navigate('/transactions-milk-cash');
  };

  const handleProduct = () => {
    navigate('/transactions-milk-product');
  };

  return (
    <BuyOptionsDesign>
      <div className="buy-options-container">
        <h1 className="buy-options-title">{t('Which type of transaction would you like to view?')}</h1>
        <div className="buy-options-buttons">
          <button className="buy-options-button" onClick={handleBuyMilk}>{t('Milk Purchase')}</button>
          <button className="buy-options-button" onClick={handleCash}>{t('Cash Deposit')}</button>
          <button className="buy-options-button" onClick={handleProduct}>{t('Products Sold')}</button>
        </div>
      </div>
    </BuyOptionsDesign>
  );
}

export default Transactions;
