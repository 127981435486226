import React, { useState, useEffect, useRef } from 'react';
import { TransactionsDesign } from './Design';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { DestroyDairyBillAction, SellMilkListingAction } from '../../store/actions/MyDairyActions';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import { formatDate } from './Transactions';

const DairyBills = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const { t } = useTranslation();
  const spinnerLoader = useSelector((state) => state.Authentication.spinnerLoader);
  const sellMilks = useSelector((state) => state.MyDairy.sellMilks || {});
  const [data, setData] = useState([]);
  const [preData, setPreData] = useState([]);
  const [meta, setMeta] = useState({});
  const [perform, setPerform] = useState({});
  const [pagy, setPagy] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({});
  const startDate = moment().tz('Asia/Kolkata').startOf('week').format('YYYY-MM-DD');
  const endDate = moment().tz('Asia/Kolkata').endOf('week').format('YYYY-MM-DD');
  const [filters, setFilters] = useState({
    from_date: startDate,
    to_date: endDate,
    shift: '',
    sort: sortConfig,
  });

  useEffect(() => {
    setPreData(data);
    dispatch(SellMilkListingAction({...filters, page: currentPage, meta: true}));
  }, [filters, currentPage, perform]);

  useEffect(() => {
    if(sellMilks.data){
      setData([...preData, ...sellMilks.data]);
      setMeta(sellMilks.meta);
      setPagy(sellMilks.pagination);
    } else {
      setData([])
      setMeta({})
    }
  }, [sellMilks]);
  
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setCurrentPage(1);
    setData([]);
    setFilters({...filters, sort: { key, direction }})
  };

  const getSortIconClass = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down';
    }
    return 'fa fa-sort';
  };

  const handleonclick = async (e, item) => {
    e.preventDefault();
    const state = {
      formData: {
        shift: item.shift,
        date: item.date,
      }, 
      meta: {
        id: item.id,
        fat_avg: item.avg_fat,
        clr_avg: item.avg_clr,
        snf_avg: item.avg_snf,
        total_quntity: item.total_quntity,
        total_amount: item.total_amount,
        fat: item.fat,
        clr: item.clr,
        snf: item.snf,
        quntity: item.quntity,
        amount: item.amount,
        benifit: item.benifit,
        weight_lose: item.weight_lose,
      },
    }
    navigate(`/actual-center-billing/edit/${item.id}`, { state: state })
  };

  const handleScroll = () => {
    const scrolablePage = document.querySelector(".table-container");
    const scrollTop = scrolablePage.scrollTop;
    const scrollHeight = scrolablePage.scrollHeight;
    const clientHeight = scrolablePage.clientHeight;
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

    if (!spinnerLoader && scrollPercentage >= 99 && pagy?.last > currentPage && pagy?.next) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const scrolablePage = document.querySelector(".table-container");
    scrolablePage?.addEventListener("scroll", handleScroll);

    return () => {
      scrolablePage?.removeEventListener("scroll", handleScroll);
    };
  }, [pagy?.last > currentPage && pagy?.next, currentPage, spinnerLoader]);

  const DeleteCredit=(event, id)=>{
    event.stopPropagation();
    
    Swal.fire({
      title: t("Are you sure you want to delete this Dairy Bill?"),
      text: t("This action is irreversible and the Dairy Bill data will be permanently deleted!"),
      icon: "warning",
      color: "white",
      showCancelButton: true,
      confirmButtonColor: "#28728f",
      cancelButtonColor: "#28728f",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t('Cancel'),
      background: "#a4c4d1",
      customClass: {
        confirmButton: "swal-button swal-button--danger",
        cancelButton: "swal-button",
        popup: "swal-popup--grey",
        content: "swal-content--grey",
        actions: "swal-actions--center",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DestroyDairyBillAction(id, setPerform, setData, t));
      } else {
        return;
      }
    });
  };

  return (
  <TransactionsDesign>
    <div className='filters-section'>
      <div className='input-container'>
        <div className='full-input'>
          <label>{t("From Date")}</label>
          <input
            type="date"
            placeholder="From Date"
            value={filters.from_date}
            onChange={(e) => {
              setCurrentPage(1);
              setData([]);
              setFilters({ ...filters, from_date: e.target.value });
            }}
          />
        </div>

        <div className='full-input'>
          <label>{t("To Date")}</label>
          <input
            type="date"
            placeholder="To Date"
            value={filters.to_date}
            onChange={(e) => {
              setCurrentPage(1);
              setData([]);
              setFilters({ ...filters, to_date: e.target.value });
            }}
          />
        </div>
      </div>

      <div className='input-container'>

        <div className='full-input'>
          <label>{t("Select Shift")}</label>
          <select
            value={filters.shift}
            onChange={(e) => {
              setCurrentPage(1);
              setData([]);
              setFilters({ ...filters, shift: e.target.value });
            }}
          >
            <option value="">{t("All Shifts")}</option>
            <option value="morning">{t("Morning")}</option>
            <option value="evening">{t("Evening")}</option>
          </select>
        </div>
      </div>
    </div>

    <div className='table-container'>
      <table ref={tableRef}>
        <thead>
          <tr>
            <th onClick={() => handleSort('date')}>
              {t("Date")} <i className={getSortIconClass('date')}></i>
            </th>
            <th>
              {t("Shift")}
            </th>
            <th onClick={() => handleSort('fat')}>
              {t("Fat")} <i className={getSortIconClass('fat')}></i>
            </th>
            <th onClick={() => handleSort('clr')}>
              {t("CLR")} <i className={getSortIconClass('clr')}></i>
            </th>
            <th onClick={() => handleSort('snf')}>
              {t("SNF")} <i className={getSortIconClass('snf')}></i>
            </th>
            <th onClick={() => handleSort('quntity')}>
              {t("Quantity")} <i className={getSortIconClass('quntity')}></i>
            </th>
            <th onClick={() => handleSort('amount')}>
              {t("Amount")} <i className={getSortIconClass('amount')}></i>
            </th>
            <th onClick={() => handleSort('benifit')}>
              {t("Benifit")} <i className={getSortIconClass('benifit')}></i>
            </th>
            <th>
              {t("Action")}
            </th>
          </tr>
        </thead>
        <tbody>
        {data.length > 0 && data.map((item) => (
            <tr key={item.id}>
              <td>{formatDate(item.date)}</td>
              <td>{t(item.shift)}</td>
              <td>{item.fat}</td>
              <td>{item.clr}</td>
              <td>{item.snf}</td>
              <td>{item.quntity}</td>
              <td>{item.amount}</td>
              <td>{item.benifit}</td>
              <td>
                <div className='actions-div'>
                  <p className='product-actions' onClick={(e) => handleonclick(e, item)}>Edit</p>
                  <p className='product-actions' onClick={(e) => DeleteCredit(e, item.id)}>Delete</p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        {data.length ? spinnerLoader && <div className="table-spinner-container">
        <div className="table-spinner"></div>
      </div> : ''}
      </table>
      {data.length <= 0 && <p className='no-data'>{t("No Data Found")}</p>}
    </div>

    <div className='summury-div'>
      <p>{t("Total Quantity")}: {meta.total_quntity}</p>
      <p>{t("Total Amount")}: {meta.total_amount}</p>
      <p>{t("Total Benifit")}: {meta.total_benifit}</p>
    </div>
  </TransactionsDesign>
  );
};

export default DairyBills;
