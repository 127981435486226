import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BuyMilkSortDesign } from '../../styles/MyDairy/Design';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { BuyCustomerMilk, BuyMilkListingAction, ChartListingAction, CustomerListingAction, GetBuyCustomerMilk, GetPerLitterPriceAction, GradeListingAction, PRICE_PER_LITTER_MESSAGE, UpdateBuyCustomerMilk } from '../../store/actions/MyDairyActions';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../AdminAction/Transactions';
import moment from 'moment';
import { TransactionsDesign } from '../AdminAction/Design';
import { usePrintReceipt } from './PrintReceipt';

const FatEnum = {
  "Fat": 'fat',
  "Fat/CLR": "fat_clr",
  "Fat/SNF": "fat_snf",
}

const FatEnumRever = {
  'fat':  "Fat",
  "fat_clr": "Fat/CLR",
  "fat_snf": "Fat/SNF",
}

const BuyMilkSort = ({action}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { printReceipt } = usePrintReceipt();
  const { t } = useTranslation();
  const locationUrl = useParams();
  const customers = useSelector((state) => state.MyDairy.customers || []);
  const grades = useSelector((state) => state.MyDairy.grades || {});
  const charts = useSelector((state) => state.MyDairy.charts || []);
  const price_per_litter = useSelector((state) => state.MyDairy.price_per_litter || {});
  const price_per_litter_msg = useSelector((state) => state.MyDairy.price_per_litter_msg);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [shift, setShift] = useState(new Date().getHours()>= 12 ? 'evening' : 'morning');
  const [customerId, setCustomerId] = useState('');
  const [cId, setCId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [rateType, setRateType] = useState('');
  const [fatRate, setFatRate] = useState('');
  const [chartType, setChartType] = useState('');
  const [quantity, setQuantity] = useState('');
  const [fat, setFat] = useState('');
  const [clr, setCLR] = useState('');
  const [snf, setSNF] = useState('');
  const [amount, setAmount] = useState(0.0);
  const [perltr, Setperltr] = useState('')
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);
  const buyMilkObj = useSelector((state) => state.MyDairy.buyMilkObj || {});
  const [duplicateEntries, setDuplicateEntries] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [formData, setFormData] = useState({});
  const [fillInput, setFillInput] = useState(1);
  const customerIdRef = useRef(null);
  const buttonref = useRef(null);
  const buttonrefNo = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState({});
  const buyMilks = useSelector((state) => state.MyDairy.buyMilks || {});
  const pagy = useSelector((state) => state.MyDairy.buyMilks?.pagination || {});
  const [preData, setPreData] = useState([]);
  const [cdata, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({key: 'created_at', direction: 'desc'});
  const spinnerLoader = useSelector((state) => state.Authentication.spinnerLoader);
  const [filters, setFilters] = useState({
    from_date: selectedDate,
    to_date: selectedDate,
    shift: shift,
    sort: sortConfig,
  });
  const printer = useSelector((state) => JSON.parse(localStorage.getItem("printerEnabled")) && state.MyDairy.printerConnected);

  const fetchCustomerDetails = (customerId) => {
    dispatch({
      type: PRICE_PER_LITTER_MESSAGE,
      payload: '',
    });
    const customer = customers.find((customer) => customer.sid === parseInt(customerId));
    if (customer) {
      setCId(customer.id);
      setCustomerName(customer.name);
      setRateType(FatEnumRever[customer?.rate_type]);
      if (customer?.rate_type === 'fat') {
        setFatRate(customer.grade_id);
      } else {
        setChartType(customer.chart_id);
      }
    } else {
      setCustomerName('');
      setRateType('');
      setFatRate('');
      setChartType('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const formInputs = document.querySelectorAll('.input');
      const currentInputIndex = Array.from(formInputs).findIndex(input => input === document.activeElement);
      const nextInputIndex = currentInputIndex + 1;

      if (nextInputIndex < formInputs.length) {
        setFillInput(nextInputIndex);
        formInputs[nextInputIndex].focus();
      } else {
        if(amount > 0) {
          handleSubmit(event);
        }
      }
    }
  };

  useEffect(()=> {
    dispatch(CustomerListingAction());
    dispatch(ChartListingAction());
    dispatch(GradeListingAction());
    if(action === 'edit'){
      dispatch(GetBuyCustomerMilk(locationUrl.id));
    }
  }, [dispatch])

  useEffect(()=>{
    if(buyMilkObj.customer && action === 'edit') {
      setShift(buyMilkObj.shift);
      setChartType(buyMilkObj.chart?.id);
      setRateType(FatEnumRever[buyMilkObj.rate_type]);
      setFatRate(buyMilkObj.grade?.id);
      setSelectedDate(new Date(buyMilkObj.date));
      setFat(buyMilkObj.fat);
      setCLR(buyMilkObj.clr);
      setSNF(buyMilkObj.snf);
      setQuantity(buyMilkObj.quntity);
      setAmount(buyMilkObj.amount);
      Setperltr(buyMilkObj.little_rate);
      setCustomerId(buyMilkObj.customer?.sid);
      setCId(buyMilkObj.customer?.id);
      setCustomerName(buyMilkObj.customer?.name);
      setFilters({ ...filters,
        from_date: buyMilkObj.date,
        to_date: buyMilkObj.date,
        shift: buyMilkObj.shift
        })
    }
  }, [buyMilkObj])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && amount > 0 && fillInput > 4) {
        handleSubmit(event);
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [amount, snf, clr, fat, customerId]);

  useEffect(()=> {
    dispatch({
      type: PRICE_PER_LITTER_MESSAGE,
      payload: '',
    });
    if((rateType === 'Fat/CLR' || rateType === 'Fat/SNF') && !chartType){
      setChartType(charts[0].id);
    }

    if(rateType === 'Fat' && !fatRate){
      setFatRate(grades[0].id);
    }
  }, [rateType, chartType, fatRate])
  
  useEffect(()=> {
    if(rateType === 'Fat/CLR' && Number(clr) > 0 && Number(fat) > 0) {
      dispatch(GetPerLitterPriceAction({fat: fat, clr: clr, chart_id: chartType}))
    } else if(rateType === 'Fat/SNF' && Number(snf) > 0 && Number(fat) > 0){
      dispatch(GetPerLitterPriceAction({fat: fat, snf: snf, chart_id: chartType}))
    }
	}, [fat, clr, snf, chartType, customerId, rateType])

  useEffect(()=> {
    if(rateType === 'Fat') {
      const rate = grades.find((grade) => grade.id === parseInt(fatRate));
      Setperltr((Number(fat)*Number(rate?.rate)).toFixed(2))
      setAmount((Number(quantity) * Number(fat) * Number(rate?.rate)).toFixed(2));
    } else if(rateType === 'Fat/CLR' || rateType === 'Fat/SNF') {
      Setperltr(price_per_litter?.rate);
      setAmount((Number(quantity) * Number(price_per_litter?.rate)).toFixed(2));
    }
	}, [fat, quantity, price_per_litter, fatRate, chartType, rateType])

  const handleProceed = (e) => {
    setShowDuplicatePopup(false);
    handleSubmit(e, true);
  };
  
  const handleClose = () => {
    setShowDuplicatePopup(false);
    customerIdRef.current.focus();
  };

  const handleKeyDown = (event) => {
    if(event.code === "ArrowLeft" || event.code === "ArrowUp"){
      buttonref.current.focus();
    }
    if(event.code === "ArrowRight" || event.code === "ArrowDown"){
      buttonrefNo.current.focus();
    }
  };

  const printReceipts = (item) => {
    const { fat, clr, snf, quntity, date, amount, shift } = item;
    console.log(fat, clr, snf, quntity, date, amount, shift);
    printReceipt(
      customerId,
      moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      shift.toUpperCase(),
      fat || '',
      quntity,
      clr || '-',
      snf || '-',
      amount
    );
  };

  const handleSubmit = (e, duplicate = null) => {
    e.preventDefault();
    const data = {
      chart_id: chartType,
      rate_type: FatEnum[rateType],
      grade_id: fatRate,
      little_rate: perltr,
      confirmed: duplicate,
      fat: fat,
      clr: clr,
      snf: snf,
      quntity: quantity,
      amount: amount,
      customer_id: cId,
      shift: shift,
      date: moment(selectedDate).format('YYYY-MM-DD'),
    };

    if(action === 'edit'){
      !price_per_litter_msg && dispatch(UpdateBuyCustomerMilk({data: {...data, id: locationUrl.id}}, navigate, true, t));
    } else {
      !price_per_litter_msg && dispatch(BuyCustomerMilk({data: data}, setFormData, setSelectedCustomer, true, t)).then((response)=> {
        if(response.status === 201) {
          printer && printReceipts(data);
          setCurrentPage(1);
          console.log(response.data.data)
          setData([response.data.data, ...cdata]);
          setMeta({...meta, total_quntity: ((meta.total_quntity || 0)+response.data.data.quntity)})
          setFat('');
          setCLR('');
          setSNF('');
          setQuantity('');
          setAmount('');
          Setperltr('');
          setCustomerId('');
          fetchCustomerDetails('');
          customerIdRef.current.focus();
        } else if(response.status === 200) {
          if (response.data && response.data.duplicates && response.data.duplicates.length > 0) {
            const duplicateEntries = response.data.duplicates;
            setShowDuplicatePopup(true);
            setDuplicateEntries(duplicateEntries);
            buttonref.current.focus();
          }
        }
      })
    }
  };

  // ----------- 

  useEffect(() => {
    if(buyMilks.data){
      setData([...preData, ...buyMilks.data])
      setMeta(buyMilks.meta)
    } else {
      setData([])
      setMeta({})
    }
  }, [buyMilks]);

  useEffect(() => {
    if(action !== 'edit') {
      setPreData(cdata);
      dispatch(BuyMilkListingAction({...filters, page: currentPage, meta: true}, cdata));
    }
  }, [filters, currentPage]);

  const handleScroll = () => {
    const scrolablePage = document.querySelector(".table-container");
    const scrollTop = scrolablePage.scrollTop;
    const scrollHeight = scrolablePage.scrollHeight;
    const clientHeight = scrolablePage.clientHeight;
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

    if (!spinnerLoader && scrollPercentage >= 99 && pagy?.last > currentPage && pagy?.next) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const scrolablePage = document.querySelector(".table-container");
    scrolablePage?.addEventListener("scroll", handleScroll);

    return () => {
      scrolablePage?.removeEventListener("scroll", handleScroll);
    };
  }, [pagy?.last > currentPage && pagy?.next, currentPage, spinnerLoader]);


  return (
    <BuyMilkSortDesign>
      <div className="container">
        <h1 className="heading">{t('Buy Milk')}</h1>
        <h5>{price_per_litter_msg}</h5>
        {customerName && (
          <div className="row">
            <div className="col">
              <label className="label">{t('Customer Name')}:</label>
              <p className="text">{customerName}</p>
            </div>
            <div className="col">
              <label className="label">{t('Rate Type')}:</label>
              {/* <p className="text">{t(rateType)}</p> */}
              <select
                  value={rateType}
                  onChange={(e) => setRateType(e.target.value)}
                  className="select"
                >
                  <option value="Fat">{t('Fat')}</option>
                  <option value="Fat/CLR">{t('Fat/CLR')}</option>
                  <option value="Fat/SNF">{t('Fat/SNF')}</option>
                </select>
            </div>
            <div className="col">
              <label className="label">
                {rateType === 'Fat' ? `${t('Fat Rate')}:` : `${t('Chart Type')}:`}
              </label>
              {rateType === 'Fat' ? (
                <select
                  value={fatRate}
                  onChange={(e) => setFatRate(e.target.value)}
                  className="select"
                >
                  {
                    grades.map((grade) => (
                      <option value={grade.id}>{grade.name}</option>
                    ))
                  }
                </select>
              ) : (
                <select
                  value={chartType}
                  onChange={(e) => setChartType(e.target.value)}
                  className="select"
                >
                  {
                    charts.map((chart) => (
                      <option value={chart.id}>{chart.name}</option>
                    ))
                  }
                </select>
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            <label className="label">{t('Date')}:</label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
                setData([]);
                setCurrentPage(1);
                setFilters({ ...filters,
                from_date: date,
                to_date: date,
                })
              }}
              dateFormat="dd-MM-yyyy"
              className="datepicker"
            />
          </div>
          <div className="col">
            <label className="label">{t('Shift')}:</label>
            <select
              value={shift}
              onChange={(e) => {
                setShift(e.target.value);
                setData([]);
                setCurrentPage(1);
                setFilters({ ...filters, shift: e.target.value})
              }}
              className="select"
            >
              <option value="morning">{t('Morning')}</option>
              <option value="evening">{t('Evening')}</option>
            </select>
          </div>
          <div className="col">
            <label className="label">{t('Customer ID')}:</label>
            <input
              ref={customerIdRef}
              type="number"
              autoFocus
              value={customerId}
              onChange={(e) => {
                setCustomerId(e.target.value);
                fetchCustomerDetails(e.target.value);
              }}
              className="input"
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="label">{t('Quantity')}:</label>
            <input
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              className="input"
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="col">
            <label className="label">{t('Fat')}:</label>
            <input
              type="number"
              value={fat}
              onChange={(e) => setFat(e.target.value)}
              className="input"
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="col">
            <label className="label">{t('CLR')}:</label>
            <input
              type="number"
              value={clr}
              onChange={(e) => setCLR(e.target.value)}
              className="input"
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="label">{t('SNF')}:</label>
            <input
              type="number"
              value={snf}
              onChange={(e) => setSNF(e.target.value)}
              className="input"
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="col">
            <label className="label">{t('Amount')} {perltr ? <b>({perltr} {t("pr/ltr")})</b> : ''}</label>
            <p className="text">{amount > 0 ? amount : 0}</p>
          </div>
        </div>
        <div className="row" style={{justifyContent: 'center'}}>
          <button className={`submit-button ${amount <= 0 && 'disabled'}`} onClick={(e) => amount > 0 && handleSubmit(e)}>
            {printer ? t('Print') : t('Save')}
          </button>
        </div>
      </div>
      {action !== 'edit' &&
      <>
        <hr/>
        <TransactionsDesign>
          <div className='summury-div'>
            <p>{t("Total Quantity")}: {meta.total_quntity}</p>
          </div>
          <div className='table-container'>
            <table>
              <thead>
                <tr>
                  <th>
                    {t("SN.")}
                  </th>
                  <th>
                    {t("Customer")}
                  </th>
                  <th>
                    {t("Quantity")}
                  </th>
                  <th>
                    {t("Fat")}
                  </th>
                  <th>
                    {t("Amount")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {cdata.length ? cdata.map((item) => (
                  <tr key={item.id}>
                    <td>{item.customer.sid}</td>
                    <td>{item.customer.name}</td>
                    <td>{item.quntity}</td>
                    <td>{item.fat}</td>
                    <td>{item.amount}</td>
                  </tr>
                )) : ''}
              </tbody>
            </table>
            {cdata.length ? spinnerLoader && <div className="table-spinner-container">
              <div className="table-spinner"></div>
            </div> : ''}
          </div>
        </TransactionsDesign>
      </>
      }
      {showDuplicatePopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>{t('Duplicate Entries Found')}</h2>
            <p>{t('duplicate Message', { date: formatDate(duplicateEntries[0]?.date), shift: t(duplicateEntries[0]?.shift) })}</p>
            <div className="duplicate-list">
              <table>
                <thead>
                  <tr>
                    <th>{t('Fat')}</th>
                    <th>{t('Quantity')}</th>
                    <th>{t('Amount')}</th>
                  </tr>
                </thead>
                <tbody>
                  {duplicateEntries.map((entry, index) => (
                    <tr key={index}>
                      <td>{entry.fat}</td>
                      <td>{entry.quntity}</td>
                      <td>{entry.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p>{t('Do you want to proceed with the duplicate entry?')}</p>
            <div className="button-container">
              <button onKeyDown={handleKeyDown} ref={buttonref} onClick={handleProceed}>{t('Yes')}</button>
              <button onKeyDown={handleKeyDown} ref={buttonrefNo} onClick={handleClose}>{t('Close')}</button>
            </div>
          </div>
        </div>
      )}
    </BuyMilkSortDesign>
  );
};

export default BuyMilkSort;