import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { PdfFormatSelectorDesign } from './Design';
import pdff1 from '../../assets/images/pdf-f-1.png'
import pdff2 from '../../assets/images/pdf-f-2.png'
import pdff3 from '../../assets/images/pdf-f-3.png'
import pdff4 from '../../assets/images/pdf-f-4.png'
import CustomSelect from '../Elements/CustomSelect';
import { useTranslation } from 'react-i18next';
import { CustomerListingAction, DownloadPdf } from '../../store/actions/MyDairyActions';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../utils/fetchServices';
import axios from 'axios';
import moment from 'moment';

const PdfFormatSelector = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const customers = useSelector((state) => state.MyDairy.customers || []);
  const today = new Date();
  const { t } = useTranslation();
  const currentDay = today.getDate();
  const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0).getDate();

  const setDefaultDates = () => {
    let defaultFromDate = new Date(today.getFullYear(), today.getMonth(), 1);
    let defaultToDate = new Date(today.getFullYear(), today.getMonth(), 10);

    if (currentDay >= 1 && currentDay <= 9) {
      defaultFromDate = new Date(today.getFullYear(), today.getMonth() - 1, 21);
      defaultToDate = new Date(today.getFullYear(), today.getMonth(), 0);
    } else if (currentDay >= 10 && currentDay <= 19) {
      defaultFromDate = new Date(today.getFullYear(), today.getMonth(), 1);
      defaultToDate = new Date(today.getFullYear(), today.getMonth(), 10);
    } else if (currentDay >= 20 && currentDay <= lastMonthEndDate - 1) {
      defaultFromDate = new Date(today.getFullYear(), today.getMonth(), 11);
      defaultToDate = new Date(today.getFullYear(), today.getMonth(), 20);
    }

    setFromDate(defaultFromDate);
    setToDate(defaultToDate);
  };

  useEffect(() => {
    dispatch(CustomerListingAction());
    setDefaultDates();
  }, []);

  const pdfFormats = [
    { id: 1, name: t('Normal Format'), sampleUrl: pdff1, url: '/buy_milks/pdf_download' },
    { id: 2, name: t('Morning/Evening Format'), sampleUrl: pdff2, url: '/buy_milks/bill_generation' },
    { id: 3, name: t('Customer Summery Format'), sampleUrl: pdff3, url: '/buy_milks/customer_summury' },
    { id: 4, name: t('Dairy Transaction Report'), sampleUrl: pdff4, url: '/buy_milks/audit_bill' },
  ];

  const handleFormatSelect = (format) => {
    setSelectedFormat(format);
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleDownload = async () => {
    setIsLoading(true);
    const params = {
      from_date: moment(fromDate).format('DD-MM-YYYY'),
      to_date: moment(toDate).format('DD-MM-YYYY'),
      page: 'pdf-format',
      customer_id: selectedFormat.id === 3 ? null : selectedCustomer?.value,
    }
    const lng = localStorage.getItem("lng") || 'en';

    try {
      const response = await axios.get(`${BASE_URL}${selectedFormat.url}`, {
        responseType: 'blob',
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {...params, locale: lng, meta: true},
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if(selectedCustomer?.value && selectedFormat.id !== 3) {
        a.download = `${selectedCustomer.label}.pdf`;
      } else {
        a.download = `${params.from_date} to ${params.to_date}.pdf`;
      }
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsLoading(false);
      handlePopupClose();
    } catch (error) {
      setIsLoading(false);
      console.error('Error downloading PDF:', error);
    }
  };

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const customerOptions = customers.map((customer) => ({
    value: customer.id,
    label: `${customer.name} - #${customer.sid}`,
  })); 

  return (
    <PdfFormatSelectorDesign>
      <div>
        <h1>{t('Bill Download')}</h1>
        <p>{t('Please select any format to download and print the bill.')}</p>
        <div className="pdf-format-list">
          {pdfFormats.map((format) => (
            <div key={format.id} className="pdf-format-item">
              <div>
                <img src={format.sampleUrl} alt={`${format.name} Thumbnail`} />
              </div>
              <button onClick={() => handleFormatSelect(format)}>
                {format.name}
              </button>
            </div>
          ))}
        </div>
        {showPopup && (
          <div className='PopupContainer'>
            <div className='PopupContent'>
              {(selectedFormat.id !== 3 && selectedFormat.id !== 4) &&
              <CustomSelect
                options={[{value: '', label: 'All'}, ...customerOptions]}
                value={selectedCustomer}
                onChange={handleCustomerChange}
                placeholder={t("Search for a customer...")}
                isSearchable
              />}
              <div>
                <label>{t('From Date')}:</label>
                <DatePicker
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  dateFormat="dd-MM-yyyy"
                  className="datepicker"
                />
              </div>
              <div>
                <label>{t('To Date')}:</label>
                <DatePicker
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  dateFormat="dd-MM-yyyy"
                  className="datepicker"
                />
              </div>
              <div className='button-container'>
                <button disabled={isLoading} onClick={handleDownload}>
                  {isLoading ? t('Downloading...') : t('Download PDF')}
                </button>
                <button onClick={handlePopupClose}>{t('Close')}</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </PdfFormatSelectorDesign>
  );
};

export default PdfFormatSelector;