import React from 'react';
import { FooterDesign } from './Design';
import PoweredBySection from './PoweredBySection';
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterDesign>
      <footer className="footer-section">
        <div className="footer-container">
          <div className="footer-info">
            <h3>Contact Us</h3>
            <p>
              Milk Dairy<br />
              185, Vijay Nagar,<br />
              Indore, MP 452010,<br />
              India.<br />
            </p>
            <p>Email: contact@ftpls.com</p>
            <p>Phone: +91 6267016717</p>
          </div>
          <div className="footer-social">
            <h3>Follow Us</h3>
            <div className="social-icons">
              <a href="https://www.facebook.com/people/Milk-Dairy/61556527997221" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook-f"></i>
              </a>
              {/* <a href="#" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a> */}
              <a href="https://in.linkedin.com/company/fittonia-technologies-pvt-ltd" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="https://www.instagram.com/milkdairy001" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <PoweredBySection/>
          </div>
        </div>
        <div className="footer-bottom">
        <p>&copy; {currentYear} Milk Dairy. All rights reserved.</p>        </div>
      </footer>
    </FooterDesign>
  );
};

export default Footer;
