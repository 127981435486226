// ToggleSwitch.js
import React, { useState } from 'react';
import { ToggleDesign } from '../../styles/Design';
import { LNG } from '../../store/actions/AuthenticationActions';
import { useDispatch, useSelector } from 'react-redux';

const ToggleSwitch = () => {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.Authentication.lng);
  const [toggleState, setToggleState] = useState(lng === 'hi');

  const handleToggle = () => {
    if(!toggleState) {
      dispatch({
        type: LNG,
        payload: 'hi',
      });
      localStorage.setItem("lng", 'hi');
    } else {
      dispatch({
        type: LNG,
        payload: 'en',
      });
      localStorage.setItem("lng", 'en');
    }
    setToggleState(!toggleState);
  };

  return (
    <ToggleDesign>
    <div className="toggle-container">
      <p>{toggleState ? "हिन्दी": "ENG"}</p>
      <label className={`switch ${toggleState ? 'on' : 'off'}`}>
        <input type="checkbox" checked={toggleState} onChange={handleToggle} />
        <span className="slider round"></span>
      </label>
    </div>
    </ToggleDesign>
  );
};

export default ToggleSwitch;
