import { useEffect, useState } from "react";
import { ActualCenterBillingDesign } from "../AdminAction/Design";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router';
import { CreateSellMilk, UpdateSellMilk } from "../../store/actions/MyDairyActions";
import { useTranslation } from "react-i18next";

function ActualCenterBilling({action})  {
	const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const params = location.state;
  const [formData, setFormData] = useState({
    id: params.meta.id,
    avg_fat: params.meta.fat_avg,
    avg_clr: params.meta.clr_avg,
    avg_snf: params.meta.snf_avg,
    total_quntity: params.meta.total_quntity,
    total_amount: params.meta.total_amount,
    fat: params.meta.fat,
    clr: params.meta.clr,
    snf: params.meta.snf,
    quntity: params.meta.quntity,
    amount: params.meta.amount,
    shift: params.formData.shift,
    date: params.formData.date,
  })

  useEffect(()=> {
    const benifit = Number(formData.amount) - Number(formData.total_amount)
    const weight_lose = Number(formData.quntity) - Number(formData.total_quntity)
    setFormData({...formData, 
      benifit: benifit?.toFixed(2),
      weight_lose: weight_lose?.toFixed(2),
    })
  }, [formData.quntity, formData.amount])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    if(action === 'new') {
      dispatch(CreateSellMilk({data: formData}, true, t));
    } else {
      dispatch(UpdateSellMilk({id: params.meta.id, data: formData}, true, t));
    }
    navigate('/dairy-bills')
  };

  return (
    <ActualCenterBillingDesign>
      <h2>{t("Average Dairy Billing")}</h2>
      <div className="farm">
        <form onSubmit={(e) => handleSubmit(e)}>
        <div className="form-contener">
        <div className="averages"> 
          <div>
            <div className="both"> 
              {t("Quantity")}: <b>{params.meta.total_quntity}</b>
            </div>
            <div className="both">
              {t("Average CLR")}: <b>{params.meta.clr_avg}</b>
            </div>
          </div>

          <div>
            <div className="both"> 
              {t("Average Fat")}: <b>{params.meta.fat_avg}</b>
            </div>
            <div className="both">
              {t("Average SNF")}: <b>{params.meta.snf_avg}</b>
            </div>
          </div>

        </div>  

        <div className="averages"> 
          <div>
            <div className="both"> 
              {t("Total Amount")}: <b>{params.meta.total_amount}</b>  
            </div>
          </div>
        </div>
        </div>
        
        <h2>{t("Actual Dairy Billing")}</h2>
        <div className="form-contener">
          <div className="contents">
            <div className="member1">
              <div className="content2">
                <label>{t("Actual Quantity")}</label>
                <input
                  type="number"
                  placeholder={t("Quantity")}
                  name="quntity"
                  value={formData.quntity}
                  onChange={handleInputChange}
                />
              </div>
              <div className="content1">
                <label>{t("Actual CLR")}</label>
                <input
                  type="number"
                  placeholder={t("CLR")}
                  name="clr"
                  value={formData.clr}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="member2">
              <div className="content1">
                <label>{t("Actual FAT")}</label>
                <input
                  type="number"
                  placeholder={t("Fat")}
                  name="fat"
                  value={formData.fat}
                  onChange={handleInputChange}
                  
                />
              </div>
              <div className="content2">
                <label>{t("Actual SNF")}</label>
                <input
                  type="number"
                  placeholder={t("SNF")}
                  name="snf"
                  value={formData.snf}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="member2">
              <div className="content1">
                <label>{t("Actual Amount")}</label>
                <input
                  type="number"
                  placeholder={t("Amount")}
                  name="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                  
                />
              </div>
            </div>
          </div>
        </div>

        <h2>{t("Result")}</h2>

        <div className="form-contener">
        <div className="averages"> 
          <div>
            <div className="both"> 
              {t("Quantity")}: <b>{formData.weight_lose > 0 ? `+${formData.weight_lose}` : formData.weight_lose}</b>
            </div>
          </div>

          <div>
            <div className="both"> 
              {t("Amount")}: <b>{formData.benifit > 0 ? `+${formData.benifit}` : formData.benifit}</b>
            </div>
          </div>
        </div>
        </div>
          
          <div className="save-button">
            <button type="submit">{t("Save")}</button>
          </div>
        </form>
        </div>
      
    </ActualCenterBillingDesign>
  );
}
export default ActualCenterBilling;
