import { DashboardDesign } from "../../styles/MyDairy/Design";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AdminAction() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const functinalities = [
    {
      id: 1,
      name: 'New Dairy Bill',
      navigate: '/center-billing'
    },
    {
      id: 2,
      name: 'Dairy Bills',
      navigate: '/dairy-bills'
    },
    {
      id: 3,
      name: 'Customer Transactions',
      navigate: '/transactions'
    },
    {
      id: 4,
      name: 'Earnings',
      navigate: '/earnings'
    },
    {
      id: 5,
      name: 'Charts',
      navigate: '/charts'
    },
    {
      id: 6,
      name: 'Fat Rate List',
      navigate: '/grade'
    },
  ]

  return (
    <DashboardDesign>
      <h1>{t("Admin Actions")}</h1>
      <div>
        {functinalities.map((el) => (
          <div
            key={el.id}
            onClick={() => navigate(el.navigate)}
          >
            <h3>{t(el.name)}</h3>
          </div>
        ))}
      </div>
    </DashboardDesign>
  );
}
export default AdminAction;
