import React, { useEffect, useState } from 'react';
import moment from 'moment';

export const TimeAgo = ({ createdAt }) => {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const updateTimeAgo = () => {
      const currentTime = moment();
      const postTime = moment(createdAt);
      const diffInSeconds = currentTime.diff(postTime, 'seconds');
      const diffInMinutes = currentTime.diff(postTime, 'minutes');
      const diffInHours = currentTime.diff(postTime, 'hours');
      const diffInDays = currentTime.diff(postTime, 'days');

      let newTimeAgo = '';

      if (diffInSeconds < 60) {
        newTimeAgo = 'just now';
      } else if (diffInMinutes < 60) {
        newTimeAgo = `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
      } else if (diffInHours < 24) {
        newTimeAgo = `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
      } else {
        newTimeAgo = `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
      }

      setTimeAgo(newTimeAgo);
    };

    updateTimeAgo(); 

    const intervalId = setInterval(updateTimeAgo, 60000); 

    return () => clearInterval(intervalId); 
  }, [createdAt]);

  return <span>{timeAgo}</span>;
};