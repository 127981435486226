import React from 'react'
import { CustomLoaderDesign } from '../../styles/Design'

function CustomLoader() {
  return (
    <CustomLoaderDesign>
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    </CustomLoaderDesign>
  )
}

export default CustomLoader