import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { DATA, LNG, LOGIN, ROLE } from './store/actions/AuthenticationActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Guest from './routes/Guest';
import MyDairy from './routes/MyDairy';
import Customer from './routes/Customer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomLoader from './components/Elements/CustomLoader';
import { useTranslation } from "react-i18next";

function App() {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.Authentication.loggedIn);
  const role = useSelector((state) => state.Authentication.role);
  const loader = useSelector((state) => state.Authentication.loader);
  const lng = useSelector((state) => state.Authentication.lng);
  const { i18n: {changeLanguage} } = useTranslation();

  useEffect(()=> {
    changeLanguage(lng);
  }, [lng, changeLanguage])

  useEffect(()=> {
    dispatch({
      type: ROLE,
      payload: localStorage.getItem('role'),
    });

    dispatch({
      type: DATA,
      payload: JSON.parse(localStorage.getItem('data')),
    });

    dispatch({
      type: LOGIN,
      payload: JSON.parse(localStorage.getItem('login')),
    });

    dispatch({
      type: LNG,
      payload: localStorage.getItem('lng'),
    });
  }, [loggedIn, dispatch]);

  return (
    <Router>
      {loader && <CustomLoader/>}
      {loggedIn ? (role === 'MyDairy' ? <MyDairy/> : <Customer/>) : <Guest/>}
      <ToastContainer />
    </Router>

  );
}

export default App;
