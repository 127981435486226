import { useDispatch, useSelector } from "react-redux";
import { AddCustomerDesign } from "../../styles/MyDairy/Design";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef , useState } from 'react'; 
import profileAvatar from '../../assets/images/login.jpg'
import { AddCustomers, ChartListingAction, GetCustomerAction, GradeListingAction, UpdateCustomer } from "../../store/actions/MyDairyActions";
import Icon from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { useTranslation } from "react-i18next";
import { ERRORS } from "../../utils/fetchServices";
import CustomSelect from "../Elements/CustomSelect";

function AddCustomer({action}) {
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [icon, setIcon] = useState(eyeOff);
  const [type, setType] = useState('password');
  const [url, setUrl] = useState(profileAvatar);
  const location = useParams();
  const grades = useSelector((state) => state.MyDairy.grades || {});
  const charts = useSelector((state) => state.MyDairy.charts || []);
  const [selectedChart, setSelectedChart] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const errors = useSelector((state) => state.Authentication.errors || {});
  const customer = useSelector((state) => state.MyDairy.customer || {});
  const [profileData, setProfileData] = useState({
    name: '',
    phone_number: '',
    password: '',
    confirm_password: '',
    address: '',
    selectedRateType: 'Fat',
    rate_type: 'fat',
  });

  const FatEnum = {
    "Fat": 'fat',
    "Fat/CLR": "fat_clr",
    "Fat/SNF": "fat_snf",
  }

  const FatEnumRever = {
   'fat':  "Fat",
    "fat_clr": "Fat/CLR",
    "fat_snf": "Fat/SNF",
  }

  const handleRateTypeChange = (event) => {
    if(event.target.value=== 'Fat' && !profileData.grade_id) {
      setSelectedGrade({value: grades[0].id, label: `${grades[0].name}`,})
      setProfileData({
        ...profileData,
        selectedRateType: event.target.value,
        rate_type: FatEnum[event.target.value],
        grade_id: grades[0].id,
        chart_id: null,
      });
    } else if(event.target.value !== 'fat' && !profileData.chart_id) {
      setSelectedChart({value: charts[0].id, label: `${charts[0].name}`,})
      setProfileData({
        ...profileData,
        selectedRateType: event.target.value,
        rate_type: FatEnum[event.target.value],
        chart_id: charts[0].id,
        grade_id: null,
      });
    } else {
      setProfileData({
        ...profileData,
        selectedRateType: event.target.value,
        rate_type: FatEnum[event.target.value],
      });
    }
  };

  const chartOptions = charts.map((chart) => ({
    value: chart.id,
    label: `${chart.name}`,
  }));

  const gradesOptions = grades.map((chart) => ({
    value: chart.id,
    label: `${chart.name}`,
  }));

  useEffect(()=> {
    if(action === 'edit'){
      dispatch(GetCustomerAction(location.id));
    }
    dispatch(ChartListingAction());
    dispatch(GradeListingAction());
  }, [])

  useEffect(()=> {
    if(action === 'edit'){
      setProfileData({
        name: customer.name,
        phone_number: customer.phone_number,
        password: customer.decode_text,
        confirm_password: customer.decode_text,
        address: customer.address,
        selectedRateType: FatEnumRever[customer.rate_type],
        chart_id: customer.chart?.id,
        grade_id: customer.grade?.id,
      })
      if(customer.grade) {
        setSelectedGrade({value: customer.grade.id, label: customer.grade.name})
      }
      if(customer.chart) {
        setSelectedChart({value: customer.chart.id, label: customer.chart.name})
      }
      if(customer.image?.url){
        setUrl(customer.image.url);
      }
    } else {
      if(grades.length) {
        setSelectedGrade({value: grades[0].id, label: `${grades[0].name}`,})
        setProfileData({...profileData, grade_id: grades[0].id});
      }
    }
  }, [customer, grades])

  const onImageClick = () => {
    inputFile.current.click();
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    const src = URL.createObjectURL(file);
    setUrl(src);
    setProfileData({...profileData, image: file});
  };

  const handleMilkTypeChange = (selectedOption) => {
    setSelectedChart(selectedOption);
    setProfileData({ ...profileData, chart_id: selectedOption.value});
  };

  const handleGradeTypeChange = (selectedOption) => {
    setSelectedGrade(selectedOption);
    setProfileData({ ...profileData, grade_id: selectedOption.value});
  };

  const handleSetRate =(event)=> {
    event.preventDefault();
    if(profileData.password !== profileData.confirm_password){
      dispatch({
        type: ERRORS,
        payload: {password: "password and confirm_password does not match"},
      });
      return;
    }
    const formData = new FormData();
    formData.append('data[name]', profileData.name);
    formData.append('data[phone_number]', profileData.phone_number);
    formData.append('data[password]', profileData.password);
    formData.append('data[confirm_password]', profileData.confirm_password);
    formData.append('data[address]', profileData.address);
    formData.append('data[rate_type]', profileData.rate_type);
    formData.append('data[chart_id]', profileData.chart_id);
    formData.append('data[grade_id]', profileData.grade_id);
    if(profileData.image){
      formData.append('data[image]', profileData.image);
    }
    if(action === 'new') {
      dispatch(AddCustomers(formData, navigate, true, t));
    } else {
      dispatch(UpdateCustomer({id: location.id, data: profileData}, navigate, true, t));
    }
  };

  const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
  }

  return (
    <AddCustomerDesign>
      <h1>{t("Add Customers")}</h1>
      <div className="contents">
      <form> 
        <div className="profile-name-number">
          <div className="profile">
            <img src={url} alt="Milk Dairy" onClick={onImageClick} />
            <input
              type="file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={(e) => handleProfilePictureChange(e)}
            />
          </div>
          <div className="name-number"> 
            <input 
              type="text"
              placeholder={t("Enter Customer Name")}
              name="name"
              autoComplete="off"
              value={profileData.name}
              onChange={(e) => setProfileData({...profileData,  name: e.target.value})}                    
            />
            <span className="error">{errors.name}</span>
            <input 
                type="text"
                placeholder={t("Phone Number")}
                name="phone_number"
                autoComplete="off"
                spellCheck="false"
                value={profileData.phone_number}
                onChange={(e) => setProfileData({...profileData,  phone_number: e.target.value})}
            />
            <span className="error">{errors.phone_number}</span>
          </div>
        </div>
        <div className="password-address">
          <div className="passwords"> 
            <div className="password-input">
              <input 
                type={type}
                placeholder={t("Password")}
                name="password"
                autoComplete="off"
                value={profileData.password}
                onChange={(e) => setProfileData({...profileData,  password: e.target.value})}
              />
              <span className="eye-buttton" onClick={handleToggle}>
                <Icon className="absolute mr-10" icon={icon} size={25}/>
              </span>
            </div>

            <div className="password-input">
              <input 
                type={type}
                placeholder={t("Confirm Password")}
                name="confirm_password"
                autoComplete="off"
                value={profileData.confirm_password}
                onChange={(e) => setProfileData({...profileData,  confirm_password: e.target.value})}
              />
              <span className="eye-buttton" onClick={handleToggle}>
                <Icon className="absolute mr-10" icon={icon} size={25}/>
              </span>
            </div>
          </div>
          
          <span className="error">{errors.password}</span>
          <div className="address">
            <input 
              type="text"
              placeholder={t("Address")}
              name="address"
              autoComplete="off"
              value={profileData.address}
              onChange={(e) => setProfileData({...profileData,  address: e.target.value})}
            />
          </div>
          <span className="error">{errors.address}</span>
          <div>
            <div className="form-group">
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    value="Fat"
                    checked={profileData.selectedRateType === 'Fat'}
                    onChange={handleRateTypeChange}
                  />
                  {t("Fat")}
                </label>
                <label>
                  <input
                    type="radio"
                    value="Fat/CLR"
                    checked={profileData.selectedRateType === 'Fat/CLR'}
                    onChange={handleRateTypeChange}
                  />
                  {t("Fat/CLR")}
                </label>
                <label>
                  <input
                    type="radio"
                    value="Fat/SNF"
                    checked={profileData.selectedRateType === 'Fat/SNF'}
                    onChange={handleRateTypeChange}
                  />
                  {t("Fat/SNF")}
                </label>
              </div>
            </div>

            {profileData.selectedRateType === 'Fat/CLR' || profileData.selectedRateType === 'Fat/SNF' ? (
              <div className="form-group">
                <CustomSelect
                  options={chartOptions}
                  value={selectedChart}
                  onChange={handleMilkTypeChange}
                  placeholder={t("Search for a chart...")}
                  isSearchable
                />
              </div>
            ) :
              <div>
                <CustomSelect
                    options={gradesOptions}
                    value={selectedGrade}
                    onChange={handleGradeTypeChange}
                    placeholder={t("Search for a fat rate list...")}
                    isSearchable
                  />
              </div>
              }
          </div>
          <div className="button-div">
            <button onClick={(e) => handleSetRate(e)}>
              {t("Save")}
            </button>
          </div>   
        </div>
      </form>
      </div>
    </AddCustomerDesign>
  );
}
export default AddCustomer;