import logoImage from '../../assets/images/MilkDairyLogo/Blue Fresh Milk Logo Template (1).png'
import profileAvatar from '../../assets/images/login.jpg'
import { useEffect, useState } from "react";
import { MainHeaderDesign } from "../../styles/Design";
import ToggleSwitch from "./Toggle";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import notificationIcon from '../../assets/images/notification_icon.png';
import bluetoothConnected from '../../assets/images/bluetooth-connected.png';
import bluetoothOff from '../../assets/images/bluetooth-off.png';
import Notification from '../Customer/Notification';
import { scanBluetoothDevices } from '../../store/actions/MyDairyActions';

function MainHeader() {
	const [logo] =  useState(logoImage)
  const [url, setUrl] = useState(profileAvatar);
  const dispatch = useDispatch();
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const navigate = useNavigate();
  const profile = useSelector((state) => state.Authentication.profile || {});
  const role = useSelector((state) => state.Authentication.role);
  const customer = useSelector((state) => state.MyDairy.customer || {});
  const printerEnabled = JSON.parse(localStorage.getItem("printerEnabled") || false)
  const printer = useSelector((state) => state.MyDairy.printerConnected);

  useEffect(()=> {
    if(role === 'MyDairy' && profile.image?.url) {
      setUrl(profile.image?.url);
    }

    if(role === 'Customer' && customer.image?.url) {
      setUrl(customer.image?.url);
    }
  }, [profile, customer, role])

  const handleNotificationToggle = () => {
    setNotificationOpen(!isNotificationOpen);
  };

  const bluetoothDevices = async () => {
    try {
      const device = await scanBluetoothDevices(dispatch);
      // setSelectedBluetoothDevice(device);
      // setSelectedUsbPrinter("");
    } catch (error) {
      console.error("Error connecting to Bluetooth printer:", error);
    }
  };

  return (
    <MainHeaderDesign>
      {isNotificationOpen && (
        <div className="overlay" style={{overflow: 'hidden'}}>
        </div>
      )}
      <div className="container"> 
        <div className="log-menu">
          <div className="logo" onClick={()=> navigate('/')}>
            <img src={logo} alt="Milk Dairy"/>          
          </div>
        </div>
        <div className='profile-toggel'>
        {printerEnabled &&
          (printer ? <img src={bluetoothConnected} className="logo" alt="Milk Dairy" onClick={() => bluetoothDevices()}/> :
          <img src={bluetoothOff} className="logo" alt="Milk Dairy" onClick={() => bluetoothDevices()}/>)}
          <ToggleSwitch />
            <div className="notification">
              <img src={notificationIcon} alt="Milk Dairy" onClick={() => {handleNotificationToggle(); }} />
              <Notification isOpen={isNotificationOpen} onClose={handleNotificationToggle} />
            </div>
          <div className="profile"><img src= {url} alt="Milk Dairy" onClick={()=> role === 'MyDairy' ? navigate('/account') : ''} /></div>
        </div>
      </div>
    </MainHeaderDesign>
  );
}

export default MainHeader;
