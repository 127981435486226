import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import App from './App';
import './i18n'
import { store } from "./store";
// console.log = function() { console.clear() };
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
