import { useDispatch, useSelector } from 'react-redux';
import { PRINTER } from '../../store/actions/MyDairyActions';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const usePrintReceipt = () => {
  const printer = useSelector((state) => state.MyDairy.printerConnected);
  const dispatch = useDispatch();

  const printReceipt = async (customerCode, date, shift, fat, litre, clr, snf, amount) => {
    if (!printer) {
      console.error("Printer not connected");
      return;
    }

    const boldOn = "\x1B\x45\x01";
    const boldOff = "\x1B\x45\x00";

    const per_litre = (amount / litre).toFixed(2);

    const receiptChunks = [
    `\x1B\x21\x08Milkdairy Merchant\n`,
    `your dairy, our solution\n--------------------------------\n`,
    `Customer Code: ${customerCode}\n`,
    `Date: ${date}  Shift: ${shift}\n--------------------------------\n`,
    `Fat: ${fat?.toString()?.padEnd(8, ' ')}  QTY: ${litre}\n`,
    `CLR: ${clr?.toString()?.padEnd(8, ' ')}  SNF: ${snf}\n`,
    `--------------------------------\n`,
    `Amount: ${amount.toString().padEnd(10, ' ')}  RATE: ${per_litre}\n`,
    `--------------------------------\n`,
    `${boldOn}MilkDairy www.milkdairy.in${boldOff}\n` +
    `Thank you for your transaction!\n\n\n\n\n\x1D\x56\x45`
    ];

    for (const chunk of receiptChunks) {
      const encoder = new TextEncoder();
      const data = encoder.encode(chunk);
      
      try {
        await printer.writeValue(data);
        await delay(100);
      } catch (error) {
        console.error("Error printing:", error);
        dispatch({
          type: PRINTER,
          payload: null,
        });
      }
    }
  };

  const printWelcomeReceipt = async () => {
    if (!printer) {
      console.error("Printer not connected");
      return;
    }

    const boldOn = "\x1B\x45\x01";
    const boldOff = "\x1B\x45\x00";
    const doubleHeightOn = "\x1B\x21\x10";
    const doubleHeightOff = "\x1B\x21\x00";
    const reset = "\x1B\x40";

    const welcomeMessage = [
      `${doubleHeightOn}${boldOn}Welcome to MilkDairy!${boldOff}${doubleHeightOff}\n`,
      `Thank you for connecting your printer. Please test the printer functionality. Happy Printing!\n`,
      `${boldOn}MilkDairy www.milkdairy.in${boldOff}\n\n\n\n\n\x1D\x56\x45`
    ];

    for (const chunk of welcomeMessage) {
      const encoder = new TextEncoder();
      const data = encoder.encode(chunk);
      
      try {
        await printer.writeValue(data);
        await delay(100);
      } catch (error) {
        dispatch({
          type: PRINTER,
          payload: null,
        });
        console.error("Error printing:", error);
      }
    }
  };

  const printProductPurchaseReceipt = async (customerCode, date, productName, price, quantity, totalAmount, note, currentBalance) => {
    if (!printer) {
      console.error("Printer not connected");
      return;
    }

    const boldOn = "\x1B\x45\x01";
    const boldOff = "\x1B\x45\x00";

    const purchaseChunks = [
      `\x1B\x21\x08Product Purchase      ${date}\n`,
      `--------------------------------\n`,
      `Customer Code: ${customerCode}\n`,
      `--------------------------------\n`,
      `Product: ${productName}\n`,
      `Price: ${price.toString().padEnd(13, ' ')}  QNT: ${quantity}\n`,
      `--------------------------------\n`,
      `Total Amount: ${totalAmount.toString().padEnd(10, ' ')}\n`,
      `--------------------------------\n`,
      note ? `Note: ${note}\n--------------------------------\n` : '',
      `Current Balance: ${currentBalance.toString().padEnd(10, ' ')}\n`,
      `${boldOn}MilkDairy www.milkdairy.in${boldOff}\n`,
      `Thank you for your purchase!\n\n\n\n\n\x1D\x56\x45`
    ];

    for (const chunk of purchaseChunks) {
      const encoder = new TextEncoder();
      const data = encoder.encode(chunk);
      
      try {
        await printer.writeValue(data);
        await delay(100);
      } catch (error) {
        console.error("Error printing:", error);
        dispatch({
          type: PRINTER,
          payload: null,
        });
      }
    }
  };

  const printCashWithdrawalReceipt = async (customerCode, date, withdrewAmount, note, currentBalance) => {
    if (!printer) {
      console.error("Printer not connected");
      return;
    }

    const boldOn = "\x1B\x45\x01";
    const boldOff = "\x1B\x45\x00";

    const withdrawalChunks = [
      `\x1B\x21\x08Cash Withdrawal       ${date}\n`,
      `--------------------------------\n`,
      `Customer Code: ${customerCode}\n`,
      `Withdrew Amount: ${withdrewAmount.toString().padEnd(10, ' ')}\n`,
      `--------------------------------\n`,
      note ? `Note: ${note}\n--------------------------------\n` : '',
      `Current Balance: ${currentBalance.toString().padEnd(10, ' ')}\n`,
      `${boldOn}MilkDairy www.milkdairy.in${boldOff}\n`,
      `Thank you!\n\n\n\n\n\x1D\x56\x45`
    ];

    for (const chunk of withdrawalChunks) {
      const encoder = new TextEncoder();
      const data = encoder.encode(chunk);
      
      try {
        await printer.writeValue(data);
        await delay(100);
      } catch (error) {
        console.error("Error printing:", error);
        dispatch({
          type: PRINTER,
          payload: null,
        });
      }
    }
  };

  return { printReceipt,
          printWelcomeReceipt,
          printProductPurchaseReceipt,
          printCashWithdrawalReceipt };
};
