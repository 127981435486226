import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { LOGIN, LogOutAction } from '../store/actions/AuthenticationActions';
import { useDispatch } from 'react-redux';

function LogOut() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fcmToken = localStorage.getItem('fcm_token');

  useEffect(()=>{
    dispatch(LogOutAction({token: fcmToken})).then(()=>{
      localStorage.clear();
      dispatch({
        type: LOGIN,
        payload: false,
      });
      navigate('/');
    })
  }, [])

  return (
    <div>LogOut</div>
  )
}

export default LogOut