import { combineReducers } from "redux";
import Authentication from "./reducers/Authentication";
import MyDairy from "./reducers/MyDairy";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  Authentication, MyDairy
});

export default rootReducer;
