import styled from "styled-components";

export const HeroSectionDesign = styled.div`
.hero {
  background: linear-gradient(to right, #a4c4d1, #4fa8de);
  color: #fff;
  padding: 80px 0;
  text-align: center;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #4fa8de;
  color: #ffffff;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-button:hover {
  background-color: #28728f;
}
`;

export const FeaturesSectionDesign = styled.div`
.features-section {
  background-color: #f8f8f8;
  padding: 80px 0;
  text-align: center;
}

h2 {
  color: #28728f;
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
}

.feature-card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px;
  padding: 30px;
  text-align: left;
  transition: transform 0.3s ease-in-out;
  flex: 1 1 300px;
}

.feature-card:hover {
  transform: scale(1.05);
}

.feature-icon {
  font-size: 2rem;
  color: #28728f;
  margin-bottom: 20px;
}

h3 {
  color: #28728f;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

p {
  color: #231f20;
  font-size: 1rem;
}
`;

export const HowItWorksSectionDesign = styled.div`
.how-it-works-section {
  background-color: #ffffff;
  padding: 80px 0;
  text-align: center;
}

h2 {
  color: #28728f;
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.steps-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.step-number {
  background-color: #28728f;
  color: #ffffff;
  font-size: 2rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.step-icon {
  color: #28728f;
  font-size: 2rem;
  margin-left: 20px;
}

h3 {
  color: #28728f;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

p {
  color: #231f20;
  font-size: 1rem;
}
`;

export const TestimonialsSectionDesign = styled.div`
.testimonials-section {
  background-color: #f8f8f8;
  padding: 80px 0;
  text-align: center;
}

h2 {
  color: #28728f;
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.testimonial {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin: 20px;
  padding: 30px;
  text-align: left;
  max-width: 300px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.testimonial:hover {
  transform: scale(1.05);
}

.testimonial-image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.testimonial:hover .testimonial-image img {
  transform: scale(1.2);
}

.testimonial-text p {
  color: #231f20;
  font-size: 1rem;
  margin-bottom: 20px;
}

.testimonial-text h3 {
  color: #28728f;
  font-size: 1.5rem;
  margin: 0;
}
`;

export const PricingSectionDesign = styled.div`
.pricing-section {
  background-color: #f8f8f8;
  padding: 80px 0;
  text-align: center;
}

h2 {
  color: #28728f;
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.plans-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.plan {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  max-width: 300px;
  transition: transform 0.3s ease-in-out;
  position: relative;
}

.plan:hover {
  transform: scale(1.05);
}

.recommended-badge {
  background-color: #4fa8de;
  color: #ffffff;
  padding: 8px;
  border-radius: 5px;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.plan-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h3 {
  color: #28728f;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.duration {
  color: #4fa8de;
}

.price {
  color: #28728f;
  font-size: 2.5rem;
  margin: 20px 0;
  font-weight: bold;
}

.cta-button {
  background-color: #28728f;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #4fa8de;
}
`;

export const ContactSectionDesign = styled.div`
.contact-section {
  background-color: #f8f8f8;
  padding: 80px 0;
  text-align: center;
}

h2 {
  color: #28728f;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

p {
  color: #555;
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #28728f;
  padding: 50px;
  justify-content: center;
  border-radius: 20px;
}

.form-group {
  width: 100%;
  margin-bottom: 20px;
  text-align: justify;
}

label {
  color: #28728f;
  font-size: 1.2rem;
  margin-bottom: 8px;
  display: block;
}

input,
textarea {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
  margin-bottom: 20px;
  border: 1px solid #a4c4d1;
  border-radius: 12px;
  font-size: 1rem;
}

button {
  background-color: #28728f;
  color: #ffffff;
  padding: 14px 28px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease-in-out;
}

button:hover {
  background-color: #4fa8de;
}
`;

export const FooterDesign = styled.div`
.footer-section {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 40px 0;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-info,
.footer-social {
  max-width: 400px;
}

h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #3498db;
}

p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  gap: 20px;
}

.social-icons a {
  color: #ecf0f1;
  font-size: 1.5rem;
  transition: color 0.3s ease-in-out;
}

.social-icons a:hover {
  color: #3498db;
}

.footer-bottom {
  background-color: #3498db;
  padding: 10px 0;
  text-align: center;
}

.footer-bottom p {
  font-size: 1rem;
  margin: 0;
}
`;

export const PoweredBySectionDesign = styled.div`
/* PoweredBySection.css */

.powered-by-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  cursor: pointer;
}

a, .powered-by-section p {
  margin-bottom: 5px;
  font-size: 1.2rem;
  color: #28728f;
  font-style: italic;
}

.powered-by-section img {
  height: 40px;
}

`;