import { ResetPasswordDesign } from "../styles/Design";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import Icon from "react-icons-kit";
import { useDispatch, useSelector } from "react-redux";
import { ResetPasswordAction } from "../store/actions/AuthenticationActions";

function ResetPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const errors = useSelector((state) => state.Authentication.errors || {});
    const otpVerified = useSelector((state) => state.Authentication.otpVerified);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [icon, setIcon] = useState(eyeOff);
    const [type, setType] = useState('password');

    useEffect(() => {
      if(otpVerified === false){
        navigate('/forgot_password')
      }
    }, [otpVerified]);
    
    const handleToggle = () => {
      if (type==='password'){
         setIcon(eye);
         setType('text')
      } else {
         setIcon(eyeOff)
         setType('password')
      }
    }

  const ResetPasswordSuccessfullyClick = () => {
    const data = {
      password: password,
      confirm_password: confirmPassword
    }
    dispatch(ResetPasswordAction({data: data}, navigate))
  };

  return (
    <ResetPasswordDesign>
      <div>
        <h1>Reset Password</h1>
        <div className="password-input">
          <input
            type={type}
            placeholder="New Password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="eye-buttton" onClick={handleToggle}>
            <Icon className="absolute mr-10" icon={icon} size={25}/>
          </span>
        </div>

        <div className="password-input">
          <input
            type={type}
            placeholder="Confirm New Password"
            name="confirm_password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <span className="eye-buttton" onClick={handleToggle}>
            <Icon className="absolute mr-10" icon={icon} size={25}/>
          </span>
        </div>
        <span className="error">{errors.password}</span>
        <button className="button" onClick={() => ResetPasswordSuccessfullyClick()}><b>Reset Password</b></button>
	  	</div>
    </ResetPasswordDesign>
  );
}

export default ResetPassword;
