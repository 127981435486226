import { ERRORS, deleteInfo, postInfo, putInfo } from "../../utils/fetchServices";
import { LoaderAction } from "./MyDairyActions";
export const LOGIN = 'LOGIN';
export const ROLE = 'ROLE';
export const DATA = 'DATA';
export const OTP = 'OTP';
export const RESETED = 'RESETED'
export const OTPVERIFIED = 'OTPVERIFIED'
export const LOADER = 'LOADER'
export const SPINER_LOADER = 'SPINER_LOADER';
export const LNG = 'LNG'

export const SignIn = (body, navigate) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  LoaderAction(true, dispatch);
  localStorage.setItem("errors", JSON.stringify({}));
  try {
      const response = await postInfo('/my_dairies', body, dispatch);
      if (response.status === 201) {
        const data = response.data;
        LoaderAction(false, dispatch);
        if (data.token) {
          localStorage.setItem("data", JSON.stringify(data.data));
          localStorage.setItem("token", data.token);
          localStorage.setItem("userId", data.data.id);
          localStorage.setItem("role", data.role);
          localStorage.setItem("login", true);
          dispatch({
            type: LOGIN,
            payload: true,
          });
        }
        navigate('/grade')
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const LoginIn = (body, navigate) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  LoaderAction(true, dispatch);
  try {
      const response = await postInfo('/auth/login', body, dispatch);
      if (response.status === 200) {
        const data = response.data;
        if (data.token) {
          LoaderAction(false, dispatch);
          localStorage.setItem("data", JSON.stringify(data.data));
          localStorage.setItem("token", data.token);
          localStorage.setItem("userId", data.data.id);
          localStorage.setItem("role", data.role);
          localStorage.setItem("login", true);
          dispatch({
            type: LOGIN,
            payload: true,
          });

          const fcmToken = localStorage.getItem('fcm_token');
          fcmToken && dispatch(SetFCMTokenAction({token: fcmToken}))
        return response
        }
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const SendOtp = (body, navigate) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  try {
      const response = await postInfo('/forget_password/send_otp', body, dispatch);
      if (response.status === 200) {
        const data = response.data;
        if (data.token) {
          localStorage.setItem("token", data.token);
        }
        dispatch({
          type: OTP,
          payload: true,
        });
        navigate('/verification', { state: {email: data.email} });
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const OtpVerifiaction = (body, navigate) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  try {
      const response = await postInfo('/forget_password/verify_otp', body, dispatch);
      if (response.status === 200) {
        const data = response.data;
        if (data.token) {
          localStorage.setItem("token", data.token);
        }
        dispatch({
          type: OTP,
          payload: false,
        });
        dispatch({
          type: OTPVERIFIED,
          payload: true,
        });
        navigate('/reset_password');
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const ResetPasswordAction = (body, navigate) => async (dispatch) => {
  dispatch({
    type: ERRORS,
    payload: {},
  });
  localStorage.setItem("errors", JSON.stringify({}));
  try {
      const response = await putInfo('/forget_password/forget_password', body, dispatch);
      if (response.status === 200) {
        const data = response.data;
        if (data.token) {
          localStorage.setItem("token", data.token);
        }
        dispatch({
          type: RESETED,
          payload: true,
        });
        navigate('/reset_password_successfully');
      } else {
          // dispatch({ type: FAIL });
      }
  } catch {
      // dispatch({ type: FAIL });
  }
};

export const SetFCMTokenAction = (body) => async (dispatch) => {
  try {
      const response = await postInfo('/notifications/create_device', body, dispatch);
  } catch {
  }
};

export const LogOutAction = (body) => async (dispatch) => {
  try {
      const response = await deleteInfo(`/notifications/logout?token=${body.token}`, dispatch);
  } catch {
  }
};

export const ConstactUs = (body) => async (dispatch) => {
  try {
    const response = await postInfo('/contacts', body, dispatch);
  } catch {
  }
};