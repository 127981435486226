import { useEffect, useState } from "react";
import { CenterBillingDesign } from "../AdminAction/Design";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import moment from "moment-timezone";
import CustomCenterBillingTable from "./CustomCenterBillingTabe";
import { useTranslation } from "react-i18next";

function CenterBilling(customer_id)  {
  const navigate = useNavigate();
  const initialDate = moment().tz('Asia/Kolkata').format('YYYY-MM-DD');
  const currentHour = new Date().getHours();
  const { t } = useTranslation();
  const defaultShift = currentHour >= 12 ? 'evening' : 'morning';
  const meta = useSelector((state) => state.MyDairy.customerinfo?.meta || {});
  const data = useSelector((state) => state.MyDairy.customerinfo?.data || []);
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');

  const [formData, setFormData] = useState({
    date: initialDate,
    shift: defaultShift,
  });

  const handleDateChange = (event) => {
    const date = event.target.value;
    setFormData({
      ...formData,
      date,
    });
  };

  const handleShiftChange = (shift) => {
    setFormData({
      ...formData,
      shift: shift,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!meta.is_added && data.length) {
      setFormData({
        ...formData,
      });
      navigate('/actual-center-billing', { state: {formData: formData,
        meta: {...meta,
        fat: 0,
        clr: 0,
        snf: 0,
        quntity: 0,
        amount: 0,
        benifit: 0,
        weight_lose: 0}}
      })
    }
  };

  useEffect(()=> {
    setDate(formData.date);
    setTime(t(formData.shift));
  }, [formData.shift, formData.date, t])

  return (
    <CenterBillingDesign>
      <h1>{t("New Dairy Bill")}</h1>
      <div className="farm">
      <div className="form-contener">
      <form onSubmit={handleSubmit}>
        <div className="contents">
          <div className="content1">
            <label>{t("Select Date")}</label>
            <input type="date" value={formData.date} onChange={handleDateChange} />
          </div>
          <div className="content2">
            <label>{t("Select Shift")}</label>
            <div className="tab-group">
              <div
                className={`tab ${formData.shift === 'morning' ? 'active' : ''}`}
                onClick={() => handleShiftChange('morning')}
              >
                {t("Morning")}
              </div>
              <div
                className={`tab ${formData.shift === 'evening' ? 'active' : ''}`}
                onClick={() => handleShiftChange('evening')}
              >
                {t("Evening")}
              </div>
            </div>
          </div>
        </div>

        <CustomCenterBillingTable params={formData} />  
        <div className="averages"> 
          <div>
            <div className="both"> 
              {t("Average CLR")}: <b>{meta.clr_avg}</b>
              
            </div>
            <div className="both">
              {t("Average SNF")}: <b>{meta.snf_avg}</b>
            </div>
          </div>

          <div>
            <div className="both"> 
              {t("Average Fat")}: <b>{meta.fat_avg}</b>
            </div>
            <div className="both">
              {t("Quantity")}: <b>{meta.total_quntity}</b>
            </div>
          </div>

        </div>  

        <div className="averages"> 
          <div>
            <div className="both"> 
              {t("Total Amount")}: <b>{meta.total_amount}</b>  
            </div>
          </div>
        </div>    
        
        <div className="save-button">
          <button type="submit" className={(meta.is_added || data.length <= 0) ? "disabled" : ''}>{t("Settle Down")}</button>
        </div>
        {meta.is_added && <p>{t('dairyBillSubmitted', { time, date })}</p>}
        </form>
      </div>
      </div>
    </CenterBillingDesign>
  );
}
export default CenterBilling;
