import React, { useState, useEffect } from "react";
import { PrinterConnectionDesign } from "../../styles/Design";
import { useTranslation } from "react-i18next";
import shareIcon from '../../assets/images/icons-share.png';
import { scanBluetoothDevices } from "../../store/actions/MyDairyActions";
import { useDispatch, useSelector } from "react-redux";
import { usePrintReceipt } from "./PrintReceipt";

const PrinterConnectionScreen = () => {
  const { t } = useTranslation();
  const { printWelcomeReceipt } = usePrintReceipt();
  const dispatch = useDispatch();
  const [printerEnabled, setPrinterEnabled] = useState(false);
  const [usbPrinters, setUsbPrinters] = useState([]);
  const [selectedUsbPrinter, setSelectedUsbPrinter] = useState("");
  const [selectedBluetoothDevice, setSelectedBluetoothDevice] = useState("");
  const [activeTab, setActiveTab] = useState("Bluetooth");
  const [showPopup, setShowPopup] = useState(false);
  const printer = useSelector((state) => state.MyDairy.printerConnected);

  useEffect(() => {
    const storedPrinterEnabled = JSON.parse(localStorage.getItem("printerEnabled"));
    const storedUsbPrinter = localStorage.getItem("selectedUsbPrinter");
    const storedBluetoothDevice = localStorage.getItem("selectedBluetoothDevice");
    const storedActiveTab = localStorage.getItem("activeTab");

    if (storedPrinterEnabled !== null) setPrinterEnabled(storedPrinterEnabled);
    if (storedUsbPrinter) setSelectedUsbPrinter(storedUsbPrinter);
    if (storedBluetoothDevice) setSelectedBluetoothDevice(storedBluetoothDevice);
    if (storedActiveTab) setActiveTab(storedActiveTab);
  }, []);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const getUsbPrinters = async () => {
    try {
      const devices = await navigator.usb.requestDevice({
        filters: [{ vendorId: 0x0000 }]
      });
      console.log("Selected USB devices:", devices);
      setUsbPrinters([devices]);
    } catch (err) {
      console.error("Error finding USB printers:", err);
    }
  };

  const bluetoothDevices = async () => {
    try {
      const device = await scanBluetoothDevices(dispatch);
      setSelectedBluetoothDevice(device);
      setSelectedUsbPrinter("");
    } catch (error) {
      console.error("Error connecting to Bluetooth printer:", error);
    }
  };

  const printReceiptViaBluetooth = () => {
    printWelcomeReceipt()
  };

  const handleUsbSelection = (printer) => {
    setSelectedUsbPrinter(printer);
    setSelectedBluetoothDevice("");
    localStorage.setItem("selectedUsbPrinter", printer);
  };

  const handleToggleChange = () => {
    if (printerEnabled) {
      setPrinterEnabled(false);
      localStorage.setItem("printerEnabled", false);
    } else {
      setShowPopup(true);
    }
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  const handleEnablePrinter = () => {
    setShowPopup(false);
    setPrinterEnabled(true);
    localStorage.setItem("printerEnabled", true);
  };

  const shareApp = () => {
    if (navigator.share) {
      navigator.share({
        title: 'MilkDairy Customer App',
        text: 'Check out the MilkDairy Customer app for instant notifications!',
        url: 'https://play.google.com/store/apps/details?id=com.milkdairybyftpls',
      })
      .then(() => console.log('Share successful'))
      .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Sharing not supported on this device. Please share the link manually: https://play.google.com/store/apps/details?id=com.milkdairybyftpls');
    }
  };

  return (
    <PrinterConnectionDesign>
      <div className="printer-connection">
        <header className="header">
          <h1 className="title">{t('Printer Settings')}</h1>
        </header>
        {showPopup && <div className="background-overlay"></div>}
        <div className="recommendation-card">
          <div className="toggle">
            <h3>{t('Enable Printer')}</h3>
            <input
              type="checkbox"
              id="enable-printer"
              checked={printerEnabled}
              onChange={handleToggleChange}
            />
            <label htmlFor="enable-printer" className="slider"></label>
          </div>
        </div>

        {printerEnabled && (
          <div className="printer-options">
            <div className="tabs">
              {/* <button
                className={`tab ${activeTab === "USB" ? "active" : ""}`}
                onClick={() => setActiveTab("USB")}
              >
                {t('USB Printers')}
              </button> */}
              <button
                className={`tab ${activeTab === "Bluetooth" ? "active" : ""}`}
                onClick={() => setActiveTab("Bluetooth")}
              >
                {t('Bluetooth Printers')}
              </button>
            </div>

            {activeTab === "USB" && (
              <div className="printer-category">
                <h3 className="category-title">{t('USB Printers')}</h3>
                <button onClick={getUsbPrinters} className="search-btn">{t('Find USB Printers')}</button>
                {usbPrinters.length > 0 ? (
                  <ul className="printer-list">
                    {usbPrinters.map((printer, index) => (
                      <li key={index}>
                        <button onClick={() => handleUsbSelection(printer)}>{printer}</button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>{t('No USB Printers Found')}</p>
                )}
                {selectedUsbPrinter && <p className="selected-printer">{t('Selected USB Printer')}: {selectedUsbPrinter}</p>}
              </div>
            )}

            {activeTab === "Bluetooth" && (
              <div className="printer-category">
                <h3 className="category-title">{t('Bluetooth Printers')}</h3>
                <button onClick={bluetoothDevices} className="search-btn">{t('Find Bluetooth Printers')}</button>
                {printer && <p className="selected-printer">{t('Selected Bluetooth Device')}: {selectedBluetoothDevice?.name}</p>}
                {printer && <button className="search-btn" onClick={printReceiptViaBluetooth} disabled={!printer}>Check Printer</button>}
              </div>
            )}
          </div>
        )}

        {showPopup && (
          <div className="popup">
          <div className="popup-content">
            <h3>{t('Help us save the environment! 🌱')}</h3>
            <p>{t('We recommend not using roll paper for printing receipts. Instead, use our MilkDairy Customer app to get instant notifications after each transaction.')}</p>
            <div className="share-app">
              <p>
                <a 
                  href="https://play.google.com/store/apps/details?id=com.milkdairybyftpls" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="app-link"
                >
                  {t('Click here to download the MilkDairy Customer app')}
                </a>
              </p>
              
              <p>
                <img 
                  src={shareIcon} 
                  alt="Share" 
                  onClick={shareApp} 
                  className="share-icon"
                  style={{ cursor: 'pointer' }}
                />
              </p>
            </div>
            
            <p className="slogan">{t('Save paper, save the planet!')}</p>
            <div className="popup-buttons">
              <button onClick={handleCancel} className="cancel-btn">{t('Cancel')}</button>
              <button onClick={handleEnablePrinter} className="enable-btn">{t('Enable Printer')}</button>
            </div>
          </div>
        </div>
        )}
      </div>
    </PrinterConnectionDesign>
  );
};

export default PrinterConnectionScreen;
