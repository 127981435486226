import { Route, Routes } from 'react-router-dom';
import Dashboard from '../components/Customer/Dashboard';
import MainHeader from '../components/Headers/MainHeader';
import LogOut from './LogOut';
import { useEffect, useState } from 'react';
import { getToken } from '@firebase/messaging';
import { useDispatch } from 'react-redux';
import { SetFCMTokenAction } from '../store/actions/AuthenticationActions';
import messaging, { onMessageListener } from '../firebase';
import { toast } from 'react-toastify';

function Customer() {
  const dispatch = useDispatch();
  const fcmToken = localStorage.getItem('fcm_token');

  useEffect(() => {
    if (!fcmToken) {
      try {
        Notification.requestPermission()
          .then(() => getToken(messaging))
          .then((token) => {
            localStorage.setItem('fcm_token', token);
            dispatch(SetFCMTokenAction({ token }));
            console.log(token);
          })
          .catch((err) => console.log('fcm-error', err));
      } catch (error) {
        console.log('Error in Notification.requestPermission:', error);
      }
    }
  }, [fcmToken, dispatch]);

  useEffect(() => {
    try {
      onMessageListener()
        .then((payload) => {
          console.info("notify")
        })
        .catch((err) => console.log('Failed:', err));
    } catch (error) {
      console.log('Error in onMessageListener:', error);
    }
  }, []);

  return (
    <>
      <MainHeader />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/logout" element={<LogOut />} />
      </Routes>
    </>
  );
}

export default Customer;
