import React, { useEffect, useState } from 'react'
import { BuyMilkSortDesign, NewProductsDesign } from '../../styles/MyDairy/Design'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { CreateProduct, GetProductAction, UpdateProduct } from '../../store/actions/MyDairyActions';

function NewProduct({action}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useParams();
  const { t } = useTranslation();
  const product = useSelector((state) => state.MyDairy.product || {});
  const errors = useSelector((state) => state.Authentication.errors || {});
  const [productData, setProductData] = useState({
    name: '',
    amount: '',
    stock_quantity: ''
  })

  useEffect(()=> {
    if(action === 'edit'){
      dispatch(GetProductAction(location.id));
    }
  }, [dispatch, action, location.id])

  useEffect(()=> {
    if(action === 'edit'){
      setProductData({
        name: product.name,
        amount: product.amount,
        stock_quantity: product.stock_quantity
      })
    }
  }, [product, action])

  const AddNewProductAction=(event)=> {
    event.preventDefault();
    if(action === 'new'){
      dispatch(CreateProduct({data: productData}, navigate, true, t));
    } else {
      dispatch(UpdateProduct({id: location.id, data: productData}, navigate, true, t));
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const formInputs = document.querySelectorAll('.input, select');
      const currentInputIndex = Array.from(formInputs).findIndex(input => input === document.activeElement);
      const nextInputIndex = currentInputIndex + 1;

      if (nextInputIndex < formInputs.length) {
        formInputs[nextInputIndex].focus();
      } else {
        if(productData.amount > 0) {
          AddNewProductAction(event);
        }
      }
    }
  };

  return (
    <BuyMilkSortDesign>
      <div className="container">
        <h1 className="heading">{t('Add New Product')}</h1>
        <div className="row">
          <div className="col">
            <label className="label">{t('Product Name')} :</label>
            <input 
              type="text"
              name="name"
              className="input"
              autoFocus
              onKeyPress={handleKeyPress}
              value={productData.name}
              required
              onChange={(e) => setProductData({...productData,  name: e.target.value})}
            />
            <span className="error">{errors.name}</span>
          </div>
          <div className="col">
            <label className="label">{t('Amount')} :</label>
            <input 
              type="number"
              className="input"
              name="amount"
              required
              value={productData.amount}
              onKeyPress={handleKeyPress}
              maxlength="8"
              onChange={(e) => setProductData({...productData,  amount: e.target.value})}          
            />
          </div>
          <div className="col">
          <label className="label">{t('Stock')} :</label>
            <input 
              type="number"
              name="amount"
              className="input"
              required
              value={productData.stock_quantity}
              onKeyPress={handleKeyPress}
              maxlength="8"
              onChange={(e) => setProductData({...productData,  stock_quantity: e.target.value})}          
            />
          </div>
        </div>
        
        <div className="row" style={{justifyContent: 'center'}}>
          <button className={`submit-button ${productData.amount <= 0 ? 'disabled' : ''}`} onClick={(e) => productData.amount > 0 && AddNewProductAction(e)}>
            {t('Save')}
          </button>
        </div>
      </div>
    </BuyMilkSortDesign>
  )
}

export default NewProduct