import { useEffect, useRef, useState } from "react";
import { AddCashDesign, BuyMilkSortDesign } from "../../styles/MyDairy/Design";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { CustomerListingAction, DepositeHistoryAdminAction, DepositeToCustomer, UpdateCustomerProduct } from "../../store/actions/MyDairyActions";
import CustomSelect from "../Elements/CustomSelect";
import { useTranslation } from "react-i18next";
import DatePicker from 'react-datepicker';
import moment from "moment";
import { usePrintReceipt } from "./PrintReceipt";
import { TransactionsDesign } from "../AdminAction/Design";

function AddCash({action}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const customerIdRef = useRef(null);
  const params = location.state;
  const { t } = useTranslation();
  const { printCashWithdrawalReceipt } = usePrintReceipt();
  const printer = useSelector((state) => JSON.parse(localStorage.getItem("printerEnabled")) && state.MyDairy.printerConnected);
  const buyMilks = useSelector((state) => state.MyDairy.depositehistoryAdmin || {});
  const pagy = useSelector((state) => state.MyDairy.depositehistoryAdmin?.pagination || {});
  const spinnerLoader = useSelector((state) => state.Authentication.spinnerLoader);
  const customers = useSelector((state) => state.MyDairy.customers || []);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [cId, setCId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [submited, setSubmited] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [customerBalance, setCustomerBalance] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [preData, setPreData] = useState([]);
  const [cdata, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [formData, setFormData] = useState({
    customer_id: '',
    deposit_type: 'cash',
    amount: '',
    note: '',
  });

  const fetchCustomerDetails = (customerId) => {
    const customer = customers.find((customer) => customer.sid === parseInt(customerId));
    if (customer) {
      console.log(customer);
      setCId(customer.id);
      setCustomerId(customer.sid);
      setCustomerBalance(customer.customer_account)
      setCustomerName(customer.name);
      setFormData({...formData, customer_id: customer.id})
    } else {
      setCustomerName('');
    }
  };

  useEffect(()=> {
		dispatch(CustomerListingAction());
  }, [dispatch, submited])

	useEffect(()=> {
    if(action === 'edit'){
      setCustomerId(params.customer.sid);
      fetchCustomerDetails(params.customer.sid);
      setFormData({
        customer_id: params.customer.id,
        deposit_type: 'cash',
        amount: params.amount,
        note: params.note,
      });
    } else {
      if(params?.customer) {
        setCustomerId(params.customer.sid);
        fetchCustomerDetails(params.customer.sid);
        setFormData({
          customer_id: params.customer.id,
          deposit_type: 'cash',
        });
      }
    }
	}, [dispatch, action, customers])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const formInputs = document.querySelectorAll('.input');
      const currentInputIndex = Array.from(formInputs).findIndex(input => input === document.activeElement);
      const nextInputIndex = currentInputIndex + 1;

      if (nextInputIndex < formInputs.length) {
        formInputs[nextInputIndex].focus();
      } else {
        if(formData.amount > 0) {
          handleSubmit(event);
        }
      }
    }
  };

  const printReceipts = (item) => {
    const { amount, note } = item;
    printCashWithdrawalReceipt(
      customerId,
      moment(selectedDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      amount || '-',
      note,
      (customerBalance?.balance - amount).toFixed(2)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(action === 'edit'){ 
      dispatch(UpdateCustomerProduct({id: params.id, data: {...formData, date: moment(selectedDate).format('YYYY-MM-DD')}}, navigate, true, t, params.redirect)).then(()=> {
        setSubmited(!submited)
      });
    } else {
        dispatch(DepositeToCustomer({data: {...formData, date: moment(selectedDate).format('YYYY-MM-DD')}}, setFormData, setCustomerId, true, 'cash', null, t)).then((response)=> {
          printer && printReceipts(formData);
          setCurrentPage(1);
          setData([response.data.data, ...cdata]);
          setMeta({...meta, total_amount: (meta.total_amount+response.data.data.amount)})
          setSubmited(!submited);
          setCId('');
          setCustomerName('');
          setCustomerId('');
          customerIdRef.current.focus();
      });
    }
  };

  useEffect(() => {
    if(buyMilks.data){
      setData([...preData, ...buyMilks.data])
      setMeta(buyMilks.meta)
    } else {
      setData([])
      setMeta({})
    }
  }, [buyMilks]);

  useEffect(() => {
    if(action !== 'edit') {
      setPreData(cdata);
      dispatch(DepositeHistoryAdminAction({sort: {key: 'created_at', direction: 'desc'}, from_date: selectedDate, to_date: selectedDate, page: currentPage, meta: true, deposit_type: "cash"}, cdata));
    }
  }, [selectedDate, currentPage]);

  const handleScroll = () => {
    const scrolablePage = document.querySelector(".table-container");
    const scrollTop = scrolablePage.scrollTop;
    const scrollHeight = scrolablePage.scrollHeight;
    const clientHeight = scrolablePage.clientHeight;
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

    if (!spinnerLoader && scrollPercentage >= 99 && pagy?.last > currentPage && pagy?.next) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const scrolablePage = document.querySelector(".table-container");
    scrolablePage?.addEventListener("scroll", handleScroll);

    return () => {
      scrolablePage?.removeEventListener("scroll", handleScroll);
    };
  }, [pagy?.last > currentPage && pagy?.next, currentPage, spinnerLoader]);

  return (
    <BuyMilkSortDesign>
      <div className="container">
        <h1 className="heading">{t('Make a Deposit')}</h1>
        {customerName && (
          <div className="row">
            <div className="col">
              <label className="label">{t('Customer Name')}:</label>
              <p className="text">{customerName}</p>
            </div>
            <div className="col">
              <label className="label">{t('Status')}:</label>
              <p className="text" style={{ color: customerBalance?.balance > 0 ? '#28728f' : '#B00020' }}>{customerBalance?.balance > 0 ? t("To give to the customer") : t("To take from the customer")}</p>
            </div>
            <div className="col">
              <label className="label">{t('Account Balance')}:</label>
              <p className="text" style={{ color: customerBalance?.balance > 0 ? '#28728f' : '#B00020' }}>{customerBalance?.balance}</p>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            <label className="label">{t('Date')}:</label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                setData([]);
                setSelectedDate(date)
              }}
              dateFormat="dd-MM-yyyy"
              className="datepicker"
            />
          </div>
          <div className="col">
            <label className="label">{t('Customer ID')}:</label>
            <input
              ref={customerIdRef}
              type="number"
              onKeyPress={handleKeyPress}
              autoFocus
              value={customerId}
              onChange={(e) => {
                setCustomerId(e.target.value);
                fetchCustomerDetails(e.target.value);
              }}
              className="input"
            />
          </div>
          <div className="col">
            <label className="label">{t('Amount')}:</label>
            <input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              className="input"
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="label">{t("Note")} :</label>
            <input
              name="note"
              type="text"
              onKeyPress={handleKeyPress}
              value={formData.note}
              className="input"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row" style={{justifyContent: 'center'}}>
          <button className={`submit-button ${formData.amount <= 0 ? 'disabled' : ''}`} onClick={(e) => formData.amount > 0 && handleSubmit(e)}>
            {printer ? t('Print') : t('Save')}
          </button>
        </div>
      </div>
      {action !== 'edit' &&
      <>
        <hr/>
        <TransactionsDesign>
          <div className='summury-div'>
            <p>{t("Total Amount")}: {meta.total_amount}</p>
          </div>
          <div className='table-container'>
            <table>
              <thead>
                <tr>
                  <th>
                    {t("SN.")}
                  </th>
                  <th>
                    {t("Customer")}
                  </th>
                  <th>
                    {t("Amount")}
                  </th>
                  <th>
                    {t("Note")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {cdata.length ? cdata.map((item) => (
                  <tr key={item.id}>
                    <td>{item.customer.sid}</td>
                    <td>{item.customer.name}</td>
                    <td>{item.amount}</td>
                    <td>{item.note}</td>
                  </tr>
                )) : ''}
              </tbody>
            </table>
            {cdata.length ? spinnerLoader && <div className="table-spinner-container">
              <div className="table-spinner"></div>
            </div> : ''}
          </div>
        </TransactionsDesign>
      </>
      }
    </BuyMilkSortDesign>
  );
}
export default AddCash;

