import AndroidAppSection from "./AndroidAppSection";
import ContactSection from "./ContactSection";
import FeaturesSection from "./FeaturesSection";
import Footer from "./Footer";
import HeroSection from "./HeroSection";
import HowItWorksSection from "./HowItWorksSection";
import PricingSection from "./PricingSection";
import TestimonialsSection from "./TestimonialsSection";

function LandingPage() {
  return (
    <div>
      <HeroSection />
      <AndroidAppSection/>
      <FeaturesSection />
      <HowItWorksSection />
      {/* <TestimonialsSection /> */}
      <PricingSection />
      <ContactSection />
      <Footer /> 
    </div>
  );
}

export default LandingPage;
