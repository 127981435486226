import React, { useEffect, useRef, useState } from 'react'
import { ProductsDesign } from '../../styles/MyDairy/Design'
import CustomTable from './CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { DestroyProductAction, ProductListingAction } from '../../store/actions/MyDairyActions';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { TransactionsDesign } from '../AdminAction/Design';

function Products() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const { t } = useTranslation();
  const [perform, setPerform] = useState("");
  const spinnerLoader = useSelector((state) => state.Authentication.spinnerLoader);
  const product = useSelector((state) => state.MyDairy.products || []);
  
  useEffect(()=> {
    dispatch(ProductListingAction());
  }, [perform])

  const DeleteProduct=(event, id)=>{
    event.stopPropagation(); // Stop event propagation
    Swal.fire({
      title: t("Are you sure you want to delete this Product?"),
      text: t("This action is irreversible and the Product data will be permanently deleted!"),
      icon: "warning",
      color: "white",
      showCancelButton: true,
      confirmButtonColor: "#28728f",
      cancelButtonColor: "#28728f",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t('Cancel'),
      background: "#a4c4d1",
      customClass: {
        confirmButton: "swal-button swal-button--danger",
        cancelButton: "swal-button",
        popup: "swal-popup--grey",
        content: "swal-content--grey",
        actions: "swal-actions--center",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DestroyProductAction(id, true, setPerform, t));
      } else {
        return;
      }
    });
  };

  return (
    <TransactionsDesign>
      <div className='filters-section'>
        <h1>{t("My Products")}</h1>
      </div> 
      <div className='table-container'>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th>
                {t("SN.")}
              </th>
              <th>
                {t("Name")}
              </th>
              <th>
                {t("Amount")}
              </th>
              <th>
                {t("Stock")}
              </th>
              <th>
                {t("Action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {product.length ? product.map((item, index) => (
              <tr key={item.id}>
                <td>{index+1}</td>
                <td>{item.name}</td>
                <td>{item.amount}</td>
                <td>{item.stock_quantity}</td>
                <td><div className='product-actions'><p onClick={() => navigate(`/product/edit/${item.id}`)}>Edit</p><p onClick={(e) => DeleteProduct(e, item.id)}>Delete</p></div></td>
              </tr>
            )) : ''}
          </tbody>
        </table>
        {product.length ? spinnerLoader && <div className="table-spinner-container">
          <div className="table-spinner"></div>
        </div> : ''}
      </div>
      <button className="circular-button" onClick={()=> navigate('/product/new')}>+</button>
    </TransactionsDesign>
  )
}

export default Products