import React from "react";
import Select from "react-select";

const CustomSelect = ({ options, value, onChange, placeholder, isSearchable }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "2px solid #28728f",
      borderRadius: "30px",
      width: "100%",
      padding: '10px',
      margin: '15px 0',
      boxSizing: 'border-box',
      "&:hover": {
        border: "2px solid #28728f",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#28728f" : "#FFFFFF",
      color: state.isSelected ? "#FFFFFF" : "#000000",
      "&:hover": {
        background: "#a4c4d1",
        color: "#FFFFFF",
      },
    }),
  };

  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isSearchable={isSearchable}
      styles={customStyles}
      required
    />
  );
};

export default CustomSelect;
