import React, { useState } from "react";
import { LoginDesign } from "../styles/Design";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginIn } from "../store/actions/AuthenticationActions";
import Icon from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

function Login() {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.Authentication.loader);
  const [icon, setIcon] = useState(eyeOff);
  const [type, setType] = useState('password');
  const errors = useSelector((state) => state.Authentication.errors || {});
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignupClick = () => {
    navigate("/signup");
  };

  const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
  }

  const handleFormSubmit =(e)=> {
    e.preventDefault();
    !loader && dispatch(LoginIn({data: formData})).then((response) => {
      if(response?.status === 200){
        navigate('/');
      }
    })
  };

  return (
    <LoginDesign>
      <div className="back-img">
        <form onSubmit={handleFormSubmit}>
        <div className="lable-input">
          <div className="password-input">
            <input
              type="text"
              placeholder="Email or Phone"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <span className="error">{errors.email}</span>
        </div>
          
        <div className="lable-input">
          <div className="password-input">
            <input
              type={type}
              placeholder="Password"
              name="password"
              value={formData.password}
              required
              onChange={handleInputChange}
            />
            <span className="eye-buttton" onClick={handleToggle}>
              <Icon className="absolute mr-10" icon={icon} size={25}/>
            </span>
          </div>
          <span className="error">{errors.password}</span>
        </div>

          <div className="box6">
            <a href="/forgot_password">
              <p>Forgot password?</p>
            </a>
          </div>
          <button type="submit">Login</button>
          <hr />
          <button className="button" onClick={handleSignupClick}>
            Create new account
          </button>
        </form>
      </div>
    </LoginDesign>
  );
}

export default Login;
